export interface DataPrivilege {
    shareType: 'Everyone' | 'OnlyMe' | 'Custom'
    teams: Team[]
    accounts: Account[]
    owners: Owner[]
    displayShareType: string
}

export interface Team {
  id: string
  name: string
}

export interface Account {
  id: string
  account?: string
  name: string
}

export interface Owner {
  id: string
  name: string
}

export const emptyDataPrivilege: DataPrivilege = {
  shareType: 'Custom',
  teams: [],
  accounts: [],
  owners: [],
  displayShareType: '',
};