import styled from "styled-components";
import ButtonStyle, { ButtonStyleProps } from "../../styles/ButtonStyle";

const Button = styled.button.attrs((props: ButtonStyleProps) => ({
  type: props.isSubmit === true ? 'submit' : 'button',
})) <ButtonStyleProps>`
  ${ButtonStyle}
  display: flex;
  align-items: center;
  gap: 4px;
`;

export default Button;
