import { useContext, useState } from "react";
import { useDebounce, useMedia } from "react-use";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridPagerSettings,
  GridRowClickEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { DataResult, State } from "@progress/kendo-data-query";
import styled, { ThemeContext } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import InputWithIcon from "../../components/shared/InputWithIcon";
import GridLoader from "../../components/shared/GridLoader";
import { useTranslation } from 'react-i18next';

const OverrideGrid = styled(Grid)`
  .k-grid-toolbar {
    .searchKey {
      @media ${(props) => props.theme.devices.mobile.mediaQuery} {
        flex-grow: 1;
      }
      border-radius: 4px;
    }
  }
`;


function OpportunityList() {
  const { t } = useTranslation();
  const [opportunities, setOpportunities] = useState<DataResult>({
    data: [],
    total: 0,
  });
  const [pageState, setPageState] = useState<State>({ take: 10, skip: 0 });
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [displayKeyword, setDisplayKeyword] = useState("");
  const [pagerSettings,] = useState({
    buttonCount: 5,
  } as GridPagerSettings);
  // const navigate = useNavigate();
  const themeContext = useContext(ThemeContext);
  const isMobile = useMedia(`(${themeContext?.devices.mobile.mediaQuery})`);


  const handleDataStateChange = (e: GridDataStateChangeEvent) => {
    setPageState(e.dataState);
    let gridContainer = document.querySelector(".k-grid-content");
    if (gridContainer) {
      gridContainer.scrollTop = 0;
    }
  };

  const handleDataReceived = (opportunities: DataResult) => {
    setOpportunities(opportunities);
  };

  const handleRowClick = (e: GridRowClickEvent) => {
    // const opportunityShowLink = `/opportunity/show/${e.dataItem.id}`;
    // navigate(opportunityShowLink, { replace: true });
  };

  useDebounce(
    () => {
      setSearchKeyWord(displayKeyword);
      setPageState({ take: 10, skip: 0 });
    },
    700,
    [displayKeyword]
  );

  const LinkCell = (props: GridCellProps) => {
    const opportunityData = props.dataItem;
    const opportunityShowLink = `/opportunity/show/${opportunityData.id}`;
    return (
      <td>
        <Link to={opportunityShowLink} key={opportunityData.id}>
          {opportunityData.name}
        </Link>
      </td>
    );
  };


  const columns = [
    {
      field: 'name',
      title: t("OpportunityDto.Name", { "User.Custom.KeyWord.Opportunity": t("User.Custom.KeyWord.Opportunity") }),
      cell: LinkCell,
      width: 400,
      mobileShow: true
    },
    {
      field: 'placingCustomerName',
      title: `${t('User.Custom.KeyWord.Customer')}/${t("User.Custom.KeyWord.Company")}`,
      width: 220,
      mobileShow: true
    },
    {
      field: 'amount',
      title: t("OpportunityModel.Amount"),
      width: 120,
      mobileShow: true
    },
    {
      field: 'expectedCloseDate',
      title: t("OpportunityDto.ExpectedCloseDate"),
      width: 140
    },
    {
      field: 'status',
      title: t("OpportunityDto.Status", { "User.Custom.KeyWord.Opportunity": t("User.Custom.KeyWord.Opportunity") }),
      width: 140
    },
    {
      field: 'probability',
      title: t("OpportunityDto.Probability"),
      width: 140
    },
    {
      field: 'salesRepresentativeName',
      title: t("OpportunityDto.SalesRepresentive"),
      width: 140
    }];


  return (
    <>
      <OverrideGrid
        filterable={false}
        sortable={false}
        pageable={pagerSettings}
        fixedScroll={true}
        {...pageState}
        data={opportunities}
        onDataStateChange={handleDataStateChange}
        onRowClick={handleRowClick}
      >
        <GridToolbar>
          <InputWithIcon icon={faSearch}
            placeholder={`${t("Opportunity.List.InputPlaceHolder", { "User.Custom.KeyWord.Customer": t("User.Custom.KeyWord.Customer") })}`}
            setDisplayKeyword={setDisplayKeyword} displayKeyword={displayKeyword} />
        </GridToolbar>
        {
          columns.map((column) => {
            if (isMobile === true && column.mobileShow !== true) return null;
            return <Column key={column.field} field={column.field} title={column.title} cell={column.cell} width={column.width} />
          })
        }
      </OverrideGrid>

      <GridLoader
        apiUrl="opportunities/list"
        keyword={searchKeyWord}
        pageState={pageState}
        onDataReceived={handleDataReceived}
      />
    </>
  );
}

export default OpportunityList;
