import styled, { ThemeContext } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUsers, faAngleRight, faAngleLeft } from "@fortawesome/pro-regular-svg-icons";
import { Customer as ICustomer } from "../../models/Customer";
import { ContactMech as IContactMech } from "../../models/ContactMech";
import { Team as ITeam, Account as IAccount, Owner as IOwner } from "../../models/DataPrivilege";
import { useState, useContext } from "react";
import { useMedia } from "react-use";
import { useTranslation } from 'react-i18next';

interface CustomerDetailInfoProps {
  pannelSize?: "mini" | "normal";
  customerData?: ICustomer;
}

interface ContactMechsInfoProps {
  contactMechs: IContactMech[];
}

interface SingleInfoProps {
  field: string;
  content: string;
}

interface PermissionInfoProps {
  shareType: "Everyone" | "OnlyMe" | "Custom";
  teams: ITeam[];
  accounts: IAccount[];
  owners: IOwner[];
}

type ISideBarSize = "normal" | "mini";

const ReSizeBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${(props) => props.theme.colorGray300};
  border-radius: 50%;
  color: ${(props) => props.theme.colorGray700};
  background: ${(props) => props.theme.colorWhite};
  width: 24px;
  height: 24px;
  position: absolute;
  left: -12px;
  top: 16px;
  cursor: pointer;
`;

const SideBar = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colorGray100};
  height: auto;
  max-width: 400px;
  transition: max-width 0.5s linear;
  &.miniSide {
    max-width: 20px;
  }
  @media screen and (${(props) => props.theme.devices.mobile.mediaQuery}) {
    background-color: ${(props) => props.theme.colorWhite};
    width: 100%;
  }
`;
const StyledCustomerShowDetailInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 350px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  @media screen and (${(props) => props.theme.devices.mobile.mediaQuery}) {
    padding: 0;
  }
  a {
    color: ${(props) => props.theme.colorPrimary};
  }
  .contactMechsInfoContent,
  .customerInfoContent,
  .customerPersonalContent,
  .customerPhoneContent,
  .customerCompanyContent,
  .customerModifyInfoContent,
  .customerPermissionContent {
    padding: 12px 0;
    &:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.colorGray300};
    }
    &__customerName {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: ${(props) => props.theme.colorGray800};
    }
    &__customerNo {
      font-size: 12px;
      line-height: 18px;
      color: ${(props) => props.theme.colorGray600};
    }
    &__Title,
    &__SubTitle {
      font-size: 14px;
      font-weight: bold;
      line-height: 28px;
      color: ${(props) => props.theme.colorGray800};
      svg {
        color: ${(props) => props.theme.colorPrimary};
        margin-right: 4px;
      }
    }
    &__Data {
      font-size: 14px;
      color: ${(props) => props.theme.colorGray800};
      line-height: 24px;
    }

    &__Item {
      line-height: 24px;
      &:not(:last-child) {
        margin-bottom: 4px;
      }
      .customerInfoField {
        font-size: 14px;
        font-weight: bold;
        color: ${(props) => props.theme.colorGray800};
        margin-right: 4px;
      }
      .customerInfoData {
        font-size: 14px;
        font-weight: 400;
        color: ${(props) => props.theme.colorGray800};
      }
    }
  }
`;

const ContactMechsInfo = (props: ContactMechsInfoProps) => {
  const { contactMechs } = props;
  const { t } = useTranslation();
  const hasContactMechs = contactMechs.length > 0;
  if (!hasContactMechs) {
    return null;
  } else {
    return (
      <div className="contactMechsInfoContent">
        {contactMechs.map((contactMech, index) => {
          return (
            <div className="contactMechsInfoContent__Item" key={index}>
              <span className="customerInfoField">{contactMech.type?.name}</span>
              <span className="customerInfoData">
                {contactMech.value}
                {contactMech.isPrimary ? ` (${t("PartyContactMech.IsPrimary")})` : ""}
              </span>
            </div>
          );
        })}
      </div>
    );
  }
};

const SingleInfo = (props: SingleInfoProps) => {
  const { field, content } = props;
  const hasContent = field && content;
  if (!hasContent) {
    return null;
  } else {
    return (
      <div className="customerInfoContent">
        <div className="customerInfoContent__Item">
          <span className="customerInfoField">{field}</span>
          <span className="customerInfoData"> {content}</span>
        </div>
      </div>
    );
  }
};

const PermissionInfo = (props: PermissionInfoProps) => {
  const { t } = useTranslation();
  const { shareType, teams, accounts, owners } = props;
  const hasTeams = teams.length > 0;
  const hasAccounts = accounts.length > 0;
  const hasOwners = owners.length > 0;

  return (
    <div className="customerPermissionContent">
      <div className="customerPermissionContent__Title">{t("DataShareType")}</div>
      <div>{shareType === "Everyone" ? t("DataShareType.Everyone") : shareType === "OnlyMe" ? t("DataShareType.OnlyMe") : t("DataShareType.Custom")}</div>
      {shareType === "Custom" && hasTeams && (
        <>
          <div className="customerPermissionContent__SubTitle">
            <FontAwesomeIcon icon={faUsers} />
            <span className="SubTitle__Text">{t("DataShareType.SpecifiedTeams")}</span>
          </div>
          <div className="customerPermissionContent__Data">
            {teams.map((team, index, teams) => {
              const comma = teams.length - 1 === index ? "" : "、";
              return (
                <span key={index}>
                  {team.name}
                  {comma}
                </span>
              );
            })}
          </div>
        </>
      )}

      {shareType === "Custom" && hasAccounts && (
        <>
          <div className="customerPermissionContent__SubTitle">
            <FontAwesomeIcon icon={faUser} />
            <span className="SubTitle__Text">{t("DataShareType.SpecifiedUsers")}</span>
          </div>
          <div className="customerPermissionContent__Data">
            {accounts.map((account, index, accounts) => {
              const comma = accounts.length - 1 === index ? "" : "、";
              return (
                <span key={index}>
                  {account.name}
                  {comma}
                </span>
              );
            })}
          </div>
        </>
      )}

      {hasOwners && (
        <>
          <div className="customerPermissionContent__SubTitle">
            <FontAwesomeIcon icon={faUser} />
            <span className="SubTitle__Text">{t("DataShareType.Owner")}</span>
          </div>
          <div className="customerPermissionContent__Data">
            {owners.map((owner, index) => {
              return <span key={index}>{owner.name}</span>;
            })}
          </div>
        </>
      )}
    </div>
  );
};

const CustomerShowDetailInfo = (props: CustomerDetailInfoProps) => {
  const { t } = useTranslation();
  const { customerData } = props;
  const themeContext = useContext(ThemeContext);
  const isMobile = useMedia(`(${themeContext?.devices.mobile.mediaQuery})`);
  const [sideBarSize, setSideBarSize] = useState<ISideBarSize>("normal");
  const sideBarClass = sideBarSize === "mini" ? "miniSide" : "";

  if (customerData === undefined) return null;
  const { name, customerNo, globalName, createDate, createUserName, modifyDate, modifyUserName } = customerData;
  const { mobiles, faxes, phones, postalAddresses, emails, websites, dataPrivilegeData } = customerData;
  const { shareType, teams, accounts, owners } = dataPrivilegeData;

  const handleReSizeBtnClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    if (sideBarSize === "normal") {
      setSideBarSize("mini");
    } else {
      setSideBarSize("normal");
    }
  };

  return (
    <SideBar className={sideBarClass}>
      {isMobile === false && (
        <ReSizeBtn onClick={handleReSizeBtnClick}>
          <FontAwesomeIcon icon={sideBarSize === "normal" ? faAngleRight : faAngleLeft} />
        </ReSizeBtn>
      )}
      <StyledCustomerShowDetailInfo>
        <div className="customerPersonalContent">
          <div className="customerPersonalContent__customerName">{name}</div>
          <div className="customerPersonalContent__customerNo">{customerNo}</div>
        </div>
        <ContactMechsInfo contactMechs={mobiles} />
        <ContactMechsInfo contactMechs={faxes} />
        <ContactMechsInfo contactMechs={phones} />
        <ContactMechsInfo contactMechs={postalAddresses} />
        <ContactMechsInfo contactMechs={emails} />
        <ContactMechsInfo contactMechs={websites} />

        <SingleInfo field={t("CustomerEditModel.GlobalName")} content={globalName} />

        <div className="customerModifyInfoContent">
          {modifyDate !== createDate && (<>
            <div className="customerModifyInfoContent__Item">
              <span className="customerInfoField">{t("Com.ModifyUser")}</span>
              <span className="customerInfoData">{modifyUserName}</span>
            </div>
            <div className="customerModifyInfoContent__Item">
              <span className="customerInfoField">{t("Com.ModifyDate")}</span>
              <span className="customerInfoData">{modifyDate}</span>
            </div>
          </>)}
          <div className="customerModifyInfoContent__Item">
            <span className="customerInfoField">{t("Com.CreateUser")}</span>
            <span className="customerInfoData">{createUserName}</span>
          </div>
          <div className="customerModifyInfoContent__Item">
            <span className="customerInfoField">{t("Com.CreateDate")}</span>
            <span className="customerInfoData">{createDate}</span>
          </div>
        </div>

        <PermissionInfo shareType={shareType} teams={teams} accounts={accounts} owners={owners} />
      </StyledCustomerShowDetailInfo>
      <div className="customerMiniPanel"></div>
    </SideBar>
  );
};

export default CustomerShowDetailInfo;
