import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import { useContext } from "react";
import CustomerContext from '../../contexts/CustomerContext'
import ButtonLink from "../../components/shared/ButtonLink";
import { ButtonStyleProps } from "../../styles/ButtonStyle";
import { RouteName } from "../../constants/Routes";
import { useMatchedRoute } from "../../hooks/useMatchedRoute";
import { useTranslation } from 'react-i18next';

type doCaseType = "toDoUpdateCustomer" | "toDoCreateCustomer";

export interface ButtonProps {
  text?: string;
  linkTo: string;
  styles?: ButtonStyleProps;
  doCase?: doCaseType;
}

const StyledButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: auto;
  margin-right: 24px;
`;

const useButtonList = (routeName: RouteName | undefined, targetId: string = "") => {
  const { t } = useTranslation();
  if (routeName === undefined) return [];
  const buttonMap: Record<RouteName, ButtonProps[]> = {
    Login: [],
    CustomerList: [
      {
        text: t("Com.New") || "",
        linkTo: "/customer/create",
        styles: {
          isPrimary: true,
          hasChildren: true,
        },
      },
    ],
    CustomerShow: [
      {
        text: t("Com.Update") || "",
        linkTo: `/customer/edit/${targetId}`,
        styles: {
          isPrimary: true,
          hasChildren: true,
        },
      },
    ],
    CustomerCreate: [
      {
        text: t("Com.Cancel") || "",
        linkTo: `/customer`,
        styles: {
          isPrimary: false,
          hasChildren: true,
        },
      },
      {
        text: t("Com.Save") || "",
        doCase: "toDoCreateCustomer",
        linkTo: `/customerEdit/${targetId}`,
        styles: {
          isPrimary: true,
          hasChildren: true,
        },
      },
    ],
    CustomerEdit: [
      {
        text: t("Com.Cancel") || "",
        linkTo: `/customer`,
        styles: {
          isPrimary: false,
          hasChildren: true,
        },
      },
      {
        text: t("Com.Save") || "",
        doCase: "toDoUpdateCustomer",
        linkTo: `/customer/edit/${targetId}`,
        styles: {
          isPrimary: true,
          hasChildren: true,
        },
      },
    ],
    OpportunityList: [],
    OpportunityItemList: [],
    ErrorPage: [],
  };
  return buttonMap[routeName];
};

/**
 * ButtonProps 需要決定的資訊
 * - 樣式
 * - 顯示什麼字
 * - 要做什麼事
 *    Button 有可能要做的事:
 *    1. link 到某連結 -> 傳入連結
 *    2. 執行某個 function -> 是否要帶參數?
 * 未來可能要做：
 * - 點擊後，出現下拉選單的按鈕
 */

const ButtonBar = () => {
  const { name: routeName } = useMatchedRoute() || {};
  const { customerId } = useParams();
  const buttonList = useButtonList(routeName, customerId);

  const navigate = useNavigate();
  const { submitBtnRef } = useContext(CustomerContext) || {};
  // 無需顯示按鈕
  if (buttonList === undefined) return <></>;

  const modifyCustomer = () => {
    submitBtnRef?.current?.click();
  }

  const doCaseMap: Record<doCaseType, () => void> = {
    toDoCreateCustomer: modifyCustomer,
    toDoUpdateCustomer: modifyCustomer,
  };

  const findOutWhatToDo = (
    doCase: doCaseType | undefined,
    linkTo: string,
    doCaseMap: Record<doCaseType, () => void>
  ) => {
    if (doCase) {
      doCaseMap[doCase]();
      return;
    }
    if (linkTo) navigate(linkTo, { replace: true });
  };

  return (
    <StyledButtonBar>
      {buttonList.map((button: ButtonProps, index) => {
        const { text, linkTo, doCase, styles } = button;
        const { isPrimary, isDisabled, content, hasChildren } = styles || {};
        return (
          <ButtonLink
            key={index}
            isPrimary={styles && isPrimary}
            hasChildren={styles && hasChildren}
            isDisabled={styles && isDisabled}
            content={content}
            onClick={() => findOutWhatToDo(doCase, linkTo, doCaseMap)}
          >
            {text}
          </ButtonLink>
        );
      })}
    </StyledButtonBar>
  );
};

export default ButtonBar;
