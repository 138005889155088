import styled from "styled-components";

export interface LinkProps {
  isNewLine?: boolean;
  marginTop?: string;
  marginLeft?: string;
  fontSize?: string;
  isError?: boolean;
}

const Link = styled.a<LinkProps>`
  display: ${props => props.isNewLine ? 'block' : ''};
  margin-top: ${props => props.marginTop || ''};
  margin-left: ${props => props.marginLeft || ''};
  font-size: ${props => props.fontSize || ''};
  color: ${props => props.isError
    ? props.theme.colorError
    : props.theme.colorPrimary};
  line-height: 20px;
  text-decoration: none;
`;

export default Link;
