
import "@progress/kendo-theme-default/dist/all.css";
import { rgba } from "polished";
import { css } from "styled-components";
import { defaultTheme } from "../themes/defaultTheme";


const kendo = css`
  /**
  * https://github.com/telerik/kendo-react/issues/45 說已有更新，但目前仍有問題
  *
  * dropdownlist 在 dialog / window 裡，z-index 是 100，調整成 10010
  */
  .k-animation-container {
      z-index: 10010 !important;
  }
  .k-datetimepicker {
    .k-icon-button.k-input-button{
      background-color: ${defaultTheme.colorWhite};
      border: 0;
      color: ${defaultTheme.colorGray500};
    }
  }

  .k-input {
    &:focus-within {
      border: 1px solid ${(props) => props.theme.colorPrimary};
      box-shadow: none;
    }
  }

  .k-dropdownlist {
    border: 1px solid ${(props) => props.theme.colorGray300};
    border-radius: 4px;
    background: ${(props) => props.theme.colorWhite};

    &.k-invalid {
      border-color: ${(props) => props.theme.colorError};
    }

    // .chevronArrow::before {
    //   /* 2種用法都無效 */
    //   /* font-family: "Font Awesome 5 Pro";
    //   font-weight: 400; */
    //   /* font: var(--fa-font-regular);
    //   content: "\f078";
    //   color: ${(props) => props.theme.colorGray500}; */
    // }
  }

  .dropdownListPopupContainer {
    /* 搜尋框 */
    .k-list-filter .k-searchbox {
      border: 1px solid ${(props) => props.theme.colorGray300};
      border-radius: 4px;
      box-shadow: none;
    }
    .k-selected.k-list-optionlabel {
      color: ${(props) => props.theme.colorPrimary};
      background-color: ${(props) => props.theme.colorWhite};
    }
    .k-list-content {
      .k-list-item {
        color: ${(props) => props.theme.colorGray700};
        font-size: 14px;
        line-height: 20px;
        box-shadow: none;

        &.k-focus {
          box-shadow: none;
        }
        &.k-selected {
          color: ${(props) => props.theme.colorPrimary};
          background-color: ${(props) => props.theme.colorWhite};
        }

        &:hover {
          background-color: ${(props) => props.theme.colorGray100};
        }
      }
    }
  }
  .k-checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid ${defaultTheme.colorGray400};
    border-radius: 2px;

    &:checked{
      border-color: ${defaultTheme.colorPrimary};
      background-color: ${defaultTheme.colorPrimary};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23${defaultTheme.colorWhite.substring(1)}' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M3,8 l3,3 l7-7'/%3e%3c/svg%3e");
    }

    &:disabled{
      border: 1px solid ${defaultTheme.colorGray300};
      background-color: ${defaultTheme.colorGray200};
      &:hover{
        border: 1px solid ${defaultTheme.colorGray300};
        box-shadow: none;
      }
    }

    &:disabled:checked{
      border: 1px solid ${defaultTheme.colorGray300};
      background-color: ${defaultTheme.colorGray200};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23${defaultTheme.colorGray400.substring(1)}' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M3,8 l3,3 l7-7'/%3e%3c/svg%3e");
      &:hover{
        box-shadow: none;
      }
    }

    &:focus, &:checked:focus {
      box-shadow: none;
    }

    &:hover {
      border: 1px solid ${defaultTheme.colorPrimary};
      box-shadow: 0px 0px 0px 2px ${rgba(defaultTheme.colorPrimary, 0.2)};
    }
  }
  .k-checkbox + .k-checkbox-label{
    color: ${defaultTheme.colorGray800};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-inline-start: 0px;
    padding-inline-start: 4px;
  }

  .k-textarea{
    textarea{
      resize: none;
    }
  }

  .k-window{
    &:focus{
      box-shadow: ${defaultTheme.windowBoxShadow};
    }
  }

  .k-grid {
    height: 100%;
    flex-grow: 1;
    flex-basis: 1px;
    border-radius: 4px;
    overflow: hidden;
    color: ${(props) => props.theme.colorGray800};
    .k-header {
      background-color: ${(props) => props.theme.colorWhite};
    }
    .k-grid-toolbar {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      background-color: ${(props) => props.theme.colorWhite};
    }

    tbody td {
      border: 0px;
    }
    & .k-alt {
      background-color: ${(props) => props.theme.colorGray100} !important;
    }
    & .k-master-row:hover {
      cursor: pointer;
      background-color: ${(props) => props.theme.colorGray200} !important;
    }
    .k-grid-pager {
      .k-pager-numbers-wrap {
         .k-link {
           color: ${(props) => props.theme.colorGray600};
           &:hover:not(.k-selected) {
             background-color: ${(props) => props.theme.colorGray200};
             border-radius: 25px;
           }
           &:focus:not(.k-selected) {
             outline: 0;
             border: 2px solid rgba(#0e86fe, 0.2);
           }
         }
         .k-selected {
           color: ${(props) => props.theme.colorWhite};
           background-color: ${(props) => props.theme.colorGray600};
           border-radius: 25px;
         }

        .k-button {
          color: ${props => props.theme.colorGray600};
          border-radius: 25px;

          &.k-selected {
            color: ${props => props.theme.colorWhite};
            background-color: ${props => props.theme.colorGray600} !important;
            &::before {
              opacity: 0;
            }
          }

          &.k-disabled {
            color: ${(props) => props.theme.colorGray300};
          }

          &:focus::after {
            opacity: 0;
          }
          &:hover {
            background-color: ${(props) => props.theme.colorGray200};
          }
        }
      }
      .k-pager-nav.k-disabled {
        color: ${(props) => props.theme.colorGray300};
      }
      .k-pager-nav:not(.k-disabled), .k-pager-info.k-label {
        color: ${(props) => props.theme.colorGray600};
      }
    }
    // & .k-grid-pager {
    //   .k-disabled {
    //     color: ${(props) => props.theme.colorGray300};
    //   }
    //   .k-pager-nav {
    //     color: ${(props) => props.theme.colorGray600};
    //   }
    //   .k-pager-numbers {
    //     .k-link {
    //       color: ${(props) => props.theme.colorGray600};
    //       &:hover:not(.k-selected) {
    //         background-color: ${(props) => props.theme.colorGray200};
    //         border-radius: 25px;
    //       }
    //       &:focus:not(.k-selected) {
    //         outline: 0;
    //         border: 2px solid rgba(#0e86fe, 0.2);
    //       }
    //     }
    //     .k-selected {
    //       color: ${(props) => props.theme.colorWhite};
    //       background-color: ${(props) => props.theme.colorGray600};
    //       border-radius: 25px;
    //     }
    //   }
    // }
  }
`;


export { kendo };