import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";
import Login from "../pages/login/Login";
import CustomerList from "../pages/customer/CustomerList";
import CustomerShow from "../pages/customer/CustomerShow";
import CustomerPage from "../pages/customer/CustomerPage";
import CustomerEditor from "../pages/customer/CustomerEditor";
import OpportunityList from "../pages/opportunity/OpportunityList";
import OpportunityPage from "../pages/opportunity/OpportunityPage";
import OpportunityItemList from "../pages/opportunity/OpportunityItemList";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/login' element={<Login />} />,
      <Route path='/customer' element={<CustomerPage />}>
        <Route path="show/:customerId" element={<CustomerShow />} />
        <Route path="edit/:customerId" element={<CustomerEditor />} />
        <Route path="create" element={<CustomerEditor />} />
        <Route path="" element={<CustomerList />} />
      </Route>
      <Route path='/opportunity' element={<OpportunityPage />}>
        <Route path='' element={<OpportunityList />} />
        <Route path='list' element={<OpportunityList />} />
        <Route path='item' element={<OpportunityItemList />} />
        <Route path='item/list' element={<OpportunityItemList />} />
      </Route>
      <Route path='*' element={<CustomerPage />}>
        <Route path="*" element={<CustomerList />} />
      </Route>
    </>
  )
)


export const PageRoutes = () => {
  return <RouterProvider router={router} />;
};
