import { useContext, useEffect, useState } from "react";
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridPagerSettings,
} from "@progress/kendo-react-grid";
import styled, { ThemeContext } from "styled-components";
import { rgba } from "polished";
import CustomerShowBaseInfo from "./CustomerShowBaseInfo";
import CustomerShowDetailInfo from "./CustomerShowDetailInfo";
import { useParams } from "react-router-dom";
import { useCrmClient } from "../../contexts/CrmClientContext";
import { Customer, Customer as ICustomer } from "../../models/Customer";
import { useMedia } from "react-use";
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from "@progress/kendo-react-layout";
import { useCustomerOutletContext } from "./CustomerPage";
import TaskList from "../task/TaskList";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import GridLoader from "../../components/shared/GridLoader";
import { DataResult, State } from "@progress/kendo-data-query";
import { useTranslation } from 'react-i18next';

export type IscrollStatus = "Idle" | "NeedToLoad" | "Loading" | "Complete" | "Final";

interface ContentProps {
  isMobile: boolean;
}

const Content = styled.div<ContentProps>`
  background-color: ${(props) => props.theme.colorGray100};
  box-shadow: 0px 6px 10px ${(props) => rgba(props.theme.colorGray800, 0.2)};
  display: flex;
  flex-direction: row;
  @media screen and (${(props) => props.theme.devices.mobile.mediaQuery}) {
    flex-direction: column;
  }
  height: calc(100vh - 90px);
  border-radius: 4px;
  overflow-x: hidden;
  overflow-y: scroll;

  .k-tabstrip {
    position: ${(props) => (props.isMobile ? "relative" : "")};
    .k-tabstrip-items-wrapper {
      padding-left: 20px;
      background-color: ${(props) => props.theme.colorWhite};
      position: ${(props) => (props.isMobile ? "sticky" : "")};
      top: ${(props) => (props.isMobile ? "0" : "")};
      .k-tabstrip-items {
        .k-item {
          font-size: 18px;
          color: ${(props) => props.theme.colorGray700};
        }
        .k-active {
          color: ${(props) => props.theme.colorPrimary};
          border: 0;
          border-bottom: 3px solid ${(props) => props.theme.colorPrimary} !important;
        }
      }
    }
    .k-tabstrip-content {
      min-height: 500px;
      border: none;
      .k-animation-container {
        width: 100%;
        @media screen and (${(props) => props.theme.devices.mobile.mediaQuery}) {
        }
      }
    }
  }
`;

const CustomerLeftContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colorWhite};
  border-right: 1px solid ${(props) => props.theme.colorGray200};
  width: 100%;
`;

const StyledTabStrip = styled(TabStrip)`
  flex: 1;
  .k-content {
    .k-animation-container {
      width: 100%;
    }
  }
`;


const OverrideGrid = styled(Grid)`
  .k-grid-aria-root {
    min-height: 400px;
  }
`;

const CustomerShow = () => {
  dayjs.extend(utc);
  const crmClient = useCrmClient();
  const { t } = useTranslation();
  const { customerId } = useParams();
  const [customerData, setCustomerData] = useState<ICustomer>();
  const [scrollStatus, setScrollStatus] = useState<IscrollStatus>("Idle");
  const themeContext = useContext(ThemeContext);
  const isMobile = useMedia(`(${themeContext?.devices.mobile.mediaQuery})`);
  const [tabSelected, setTabSelected] = useState<number>(0);
  const { setBreadcrumbInfo } = useCustomerOutletContext();
  const [opportunities, setOpportunities] = useState<DataResult>({
    data: [],
    total: 0,
  });
  const [oppPagerSettings,] = useState({
    buttonCount: 5,
  } as GridPagerSettings);
  const [oppPageState, setOppPageState] = useState<State>({ take: 10, skip: 0 });

  // get customer info
  useEffect(() => {
    crmClient!
      .get("customers/customerInfo/" + customerId)
      .then((response) => {
        if (response.status === 200) {
          const receiveCustomer = response.data as Customer;
          const customer = {
            ...receiveCustomer,
            createDate: dayjs.utc(receiveCustomer.createDate).local().format("YYYY/MM/DD HH:mm:ss"),
            modifyDate: dayjs.utc(receiveCustomer.modifyDate).local().format("YYYY/MM/DD HH:mm:ss"),
          }


          setCustomerData(customer);
          setBreadcrumbInfo({
            breadcrumbShowName: customer.name,
            hasBreadcrumbArrow: true,
          });
        } else {
          console.error("get customerInfo ERROR");
        }
      })
      .catch((error) => console.error("get customerInfo ERROR", error));
  }, []);

  if (customerData === undefined) return null;

  const handleTabSelect = (e: TabStripSelectEventArguments) => {
    setTabSelected(e.selected);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = event.currentTarget.scrollTop;
    const scrollHeight = event.currentTarget.scrollHeight;
    const clientHeight = event.currentTarget.clientHeight;
    // console.log(`scrollTop: ${scrollTop}, scrollHeight: ${scrollHeight}, clientHeight: ${clientHeight}`)

    if (tabSelected === 0 && scrollTop + 200 >= scrollHeight - clientHeight && scrollStatus !== "Loading") {
      if (scrollStatus !== "Final") {
        setScrollStatus("NeedToLoad");
      }
    }
  };


  const handleOppDataStateChange = (e: GridDataStateChangeEvent) => {
    setOppPageState(e.dataState);
    let gridContainer = document.querySelector(".k-grid-content");
    if (gridContainer) {
      gridContainer.scrollTop = 0;
    }
  };

  const handleOppDataReceived = (opportunities: DataResult) => {
    setOpportunities(opportunities);
  };

  const columns = [
    {
      field: 'name',
      title: t("OpportunityDto.Name", { "User.Custom.KeyWord.Opportunity": t("User.Custom.KeyWord.Opportunity") }),
      width: 240,
      mobileShow: true
    },
    {
      field: 'expectedCloseDate',
      title: t("OpportunityDto.ExpectedCloseDate"),
      width: 120,
    },
    {
      field: 'status',
      title: t("OpportunityDto.Status"),
      width: 100,
    },
    {
      field: 'probability',
      title: t("OpportunityDto.Probability"),
      width: 100,
    },
    {
      field: 'salesRepresentativeName',
      title: t("OpportunityDto.SalesRepresentive"),
      width: 100,
    },
    {
      field: 'productName',
      title: t("OpportunityDto.ProductName"),
      width: 160,
    },
    {
      field: 'quantity',
      title: t("OpportunityDto.Quantity"),
      width: 90,
    },
    {
      field: 'unitPrice',
      title: t("OpportunityDto.UnitPrice"),
      width: 100,
    },
    {
      field: 'subtotal',
      title: t("OpportunityDto.Subtotal"),
      width: 100,
      mobileShow: true
    },
  ];

  return (
    <Content onScroll={handleScroll} isMobile={isMobile}>
      <CustomerLeftContent>
        <CustomerShowBaseInfo customerData={customerData} isMobile={isMobile} />
        {isMobile === false && (
          <StyledTabStrip selected={tabSelected} onSelect={handleTabSelect}>
            <TabStripTab title={t("User.Custom.KeyWord.Task")}>
              <TaskList customer={customerData} scrollStatus={scrollStatus} setScrollStatus={setScrollStatus} />
            </TabStripTab>
            <TabStripTab title={t("User.Custom.KeyWord.Opportunity")}>
              <OverrideGrid
                filterable={false}
                sortable={false}
                pageable={oppPagerSettings}
                fixedScroll={true}
                {...oppPageState}
                data={opportunities}
                onDataStateChange={handleOppDataStateChange}
              >
                {
                  columns.map((column) => {
                    return <Column key={column.field} field={column.field} title={column.title} width={column.width} />
                  })
                }
              </OverrideGrid>
              <GridLoader
                apiUrl="opportunities/itemList"
                keyword={""}
                pageState={oppPageState}
                onDataReceived={handleOppDataReceived}
                condition={{ placingCustomerIds: `${customerData.id}` }}
              />
            </TabStripTab>
          </StyledTabStrip>
        )}
      </CustomerLeftContent>
      {isMobile === true ? (
        <TabStrip selected={tabSelected} onSelect={handleTabSelect}>
          <TabStripTab title={t("Customer.Info.String")}>
            <CustomerShowDetailInfo customerData={customerData} />
          </TabStripTab>
          <TabStripTab title={t("User.Custom.KeyWord.Task")}>
            <TaskList customer={customerData} scrollStatus={scrollStatus} setScrollStatus={setScrollStatus} />
          </TabStripTab>
        </TabStrip>
      ) : (
        <CustomerShowDetailInfo customerData={customerData} />
      )}
    </Content>
  );
};

export default CustomerShow;
