import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import styled from "styled-components";

interface EditorItemProps {
  distance?: 'Near' | 'Middle' | 'Far',
}
const EditorItem = styled.div<EditorItemProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  margin-left: ${props => {
    switch (props.distance) {
      case 'Near':
        return '4px';
      case 'Middle':
        return '8px';
      case 'Far':
        return '16px';
      default:
        return '';
    }
  }};
`;
interface InputWrapperProps {
  distance?: 'Near' | 'Middle' | 'Far',
  disableMobileWidthStyle: boolean,
}
const InputWrapper = styled.div<InputWrapperProps>`
  margin-left: ${props => {
    switch (props.distance) {
      case 'Near':
        return '4px';
      case 'Middle':
        return '8px';
      case 'Far':
        return '16px';
      default:
        return '';
    }
  }};

  @media screen and (${props => props.theme.devices.mobile.mediaQuery}) {
    ${props => props.disableMobileWidthStyle ? '' : 'width: 100%;'}
  }
`;
interface StyledInputProps {
  $inputSize?: 'Small' | 'Middle' | 'Large',
}
const StyledInput = styled(Input) <StyledInputProps>`
  width: ${props => {
    switch (props.$inputSize) {
      case 'Small':
        return '120px';
      case 'Large':
        return '300px';
      case 'Middle':
      default:
        return '200px';
    }
  }};
  padding: '6px 12px';

  @media screen and (${props => props.theme.devices.mobile.mediaQuery}) {
    width: 100%;
  }
`;

interface EditorItemInputProps extends FieldRenderProps {
  editorItemDistance?: 'Near' | 'Middle' | 'Far',
  inputDistance?: 'Near' | 'Middle' | 'Far',
  inputSize?: 'Small' | 'Middle' | 'Large',
}
export const EditorItemInput = (props: EditorItemInputProps) => {
  const {
    label,
    validationMessage,
    visited,
    editorItemDistance,
    inputDistance,
    inputDisableMobileWidthStyle,
    inputSize,
    value,
    ...others
  } = props;

  return (
    <FieldWrapper>
      <EditorItem distance={editorItemDistance}>
        {label && <Label>{label}</Label>}
        <InputWrapper
          distance={inputDistance}
          disableMobileWidthStyle={inputDisableMobileWidthStyle}>
          <StyledInput value={value || ''} $inputSize={inputSize} {...others} />
          {visited && validationMessage && <Error>{validationMessage}</Error>}
        </InputWrapper>
      </EditorItem>
    </FieldWrapper>
  );
}