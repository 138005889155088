import styled from "styled-components";
import ButtonStyle, { ButtonStyleProps } from "../../styles/ButtonStyle";

const ButtonLink = styled.a<ButtonStyleProps>`
  ${ButtonStyle}
  display: inline-block;
  text-decoration: none;
`;

export default ButtonLink;
