import styled from "styled-components";

interface IconButtonProps {
  id?: string;
  children: React.ReactNode;
  refObj?: React.RefObject<HTMLDivElement>;
  handleClick?: React.MouseEventHandler<HTMLDivElement>;
  isActive?: boolean;
  fontSize?: string;
  className?: string;
}

const StyledIconButton = styled.div<IconButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : props.theme.fontSize)};
  color: ${(props) => props.theme.colorGray800};

  border-radius: ${(props) => props.isActive === true && props.theme.buttonBorderRadiusDefault};
  background-color: ${(props) => props.isActive === true && props.theme.colorGray200};
  padding: 6px 10px;
  line-height: 18px;

  &:hover {
    border-radius: ${(props) => props.theme.buttonBorderRadiusDefault};
    background-color: ${(props) => props.theme.colorGray200};
    cursor: pointer;
  }

  &:active {
    user-select: none;
  }
`;

const IconButton = (props: IconButtonProps) => {
  const { children, id, refObj, handleClick, isActive, className } = props;

  return (
    <StyledIconButton id={id} onClick={handleClick} ref={refObj} isActive={isActive} className={className}>
      {children}
    </StyledIconButton>
  );
};

export default IconButton;
