import { useEffect } from "react";

const useClickOutside = (element: HTMLElement | null, handler: () => void) => {
  useEffect(() => {
    if (!element) return;
    const listener = (event: MouseEvent) => {
      if (!event) return;
      if (!(event.target instanceof HTMLElement)) return;
      if (!event.target.contains(element)) return;
      handler();
    };
    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  }, [element, handler]);
};

export { useClickOutside };