import { DropDownList, DropDownListProps, DropDownsPopupSettings } from "@progress/kendo-react-dropdowns";

const CustomDropDownList = (props: DropDownListProps) => {
  const { data, ...others } = props;

  const DefaultDropDownsPopupSettings: DropDownsPopupSettings = {
    className: "dropdownListPopupContainer",
  };

  return (
    <DropDownList popupSettings={DefaultDropDownsPopupSettings} data={data} {...others} />
  );
};

export default CustomDropDownList;
