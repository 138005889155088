import { matchPath, useLocation } from "react-router-dom";
import { useRoutes } from "../constants/Routes";


export function useMatchedRoute() {
  const { pathname } = useLocation();
  const routes = useRoutes();
  const matchRoute = routes.filter(route => {
    const paths = route.paths;
    const matchPaths = paths.filter(path => matchPath(path, pathname));
    return matchPaths.length
  }).shift();
  return matchRoute;
}
