import {
  DropDownList,
  DropDownsPopupSettings,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { useState } from "react";
import styled from "styled-components";
import { citiesData } from "../constants/cities";
import {
  FieldArrayRenderProps,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';

interface EditorItemProps {
  distance?: "Near" | "Middle" | "Far";
}

interface ICity {
  id: string;
  name: string;
  townships: ITown[];
}

interface ITown {
  id: string;
  name: string;
}

const EditorItem = styled.div<EditorItemProps>`
  margin-left: ${(props) => {
    switch (props.distance) {
      case "Near":
        return "4px";
      case "Middle":
        return "8px";
      case "Far":
        return "16px";
      default:
        return "";
    }
  }};
  .postalAddressContent {
    display: flex;
    gap: 8px;
  }
`;

interface StyledDropDownListProps {
  dropDownListSize?: "Small" | "Middle" | "Large";
}
const StyledDropDownList = styled(DropDownList) <StyledDropDownListProps>`
  width: ${(props) => {
    switch (props.dropDownListSize) {
      case "Small":
        return "120px";
      case "Large":
        return "300px";
      case "Middle":
      default:
        return "200px";
    }
  }};
`;


export const EditorItemPostalAddressDropdownlist = (
  props: FieldArrayRenderProps
) => {
  const { t } = useTranslation();
  const {
    visited,
    validationMessage,
    filterable,
    dataItemKey,
    textField,
    isPrimitive,
    isRequired,
    editorItemDistance,
    inputSize,
    onChange,
    value,
    ...others
  } = props;
  const [cityId, townshipId] = value.split(',');
  const targetCity = citiesData.find((city) => city.id === cityId) || null;
  const [city, setCity] = useState<ICity | null>(targetCity)
  const defaultItemCity = { id: "", name: t("Com.PleaseSelect") };
  const defaultItemTown = { id: "", name: t("Com.PleaseSelect") };
  const targetTownsData = city?.townships || [defaultItemTown];
  const [townsData, setTownsData] = useState<ITown[]>(targetTownsData);
  const targetTown = city?.townships.find((town) => town.id === townshipId) || defaultItemTown;
  const [town, setTown] = useState<ITown | null>(targetTown);


  useEffect(() => {
    props.onPop();
    props.onPush({
      value: {
        city: !!city && city.id,
        town: !!town && town.id,
      }
    })
  }, [city, town])


  const handleCityChange = (event: DropDownListChangeEvent) => {
    const currentCity = event.target.value;
    const targetCity = citiesData.find((city) => city.id === currentCity.id);
    setCity(currentCity);
    !!targetCity && setTownsData(targetCity.townships);
    setTown(defaultItemTown);
  };

  const handleTownChange = (event: DropDownListChangeEvent) => {
    const currentTown = event.target.value;
    setTown(currentTown);
  };

  const hasCity = city && city.id !== defaultItemCity.id;

  const DefaultDropDownsPopupSettings: DropDownsPopupSettings = {
    className: "dropdownListPopupContainer",
    popupClass: "popupItem",
  };

  return (
    <FieldWrapper>
      <EditorItem distance={editorItemDistance}>
        <div className="postalAddressContent">
          <StyledDropDownList
            style={{ width: "100px" }}
            data={citiesData}
            dataItemKey="id"
            textField="name"
            defaultItem={defaultItemCity}
            popupSettings={DefaultDropDownsPopupSettings}
            onChange={handleCityChange}
            value={city}
            {...others}
          />
          <StyledDropDownList
            style={{ width: "100px" }}
            disabled={!hasCity}
            data={townsData}
            dataItemKey="id"
            textField="name"
            defaultItem={defaultItemTown}
            popupSettings={DefaultDropDownsPopupSettings}
            onChange={handleTownChange}
            value={town}
            {...others}
          />
        </div>
        <div className="validationMessageContent">
          {visited && validationMessage && <Error>{validationMessage}</Error>}
        </div>
      </EditorItem>
    </FieldWrapper>
  );
};
