import styled from "styled-components";
import { Skeleton } from "@progress/kendo-react-indicators";

interface CustomerPictureProps {
  imgUrl: string;
  isImgLoading: boolean;
  width: number;
}

const StyledCustomerPictureContent = styled.div`
  position: relative;
  .customerPicture {
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colorGray100};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px;
  }
`;

const CustomerPictureContent = (props: CustomerPictureProps) => {
  const { imgUrl, isImgLoading, width } = props;
  return (
    <StyledCustomerPictureContent>
      {isImgLoading ? (
        <Skeleton shape={"circle"} style={{ width: width, height: width }} />
      ) : (
        <>
          <img className="customerPicture" src={imgUrl} alt="customerPicture" style={{ width: width, height: width }} />
          <span className="premission" />
        </>
      )}
    </StyledCustomerPictureContent>
  );
};

export default CustomerPictureContent;
