import { Outlet, redirect, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { BreadcrumbProps, defaultBreadcrumbProps } from "../../components/Breadcrumb";
import { useEffect, useState } from "react";
import { OpportunityContextProvider } from "../../contexts/OpportunityContext";
import BaseLayout, { LinkListProps } from "../../components/layout/BaseLayout";
import { initKeywordI18nFromCrm } from "../../i18n";
import LoadingPanel from "../../components/LoadingPanel";
import { isLogin } from "../../utils/isLogin";

const OpportunityPage = () => {
  const { t } = useTranslation();
  const [breadcrumbInfo, setBreadcrumbInfo] = useState<BreadcrumbProps>(defaultBreadcrumbProps);
  const { pathname } = useLocation();
  const isShowUserProfilePanel = ["/opportunity/list", "/opportunity", '/opportunity/list', '/opportunity/item/list'].includes(pathname);
  const linkListPropsArray: LinkListProps[] = [{ label: t("User.Custom.KeyWord.Opportunity"), paths: ['/opportunity', '/opportunity/list'] }, { label: t("Opportunity.Show.OpportunityItem", { "User.Custom.KeyWord.Opportunity": t("User.Custom.KeyWord.Opportunity")}), paths: ['/opportunity/item/list'] }]
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if ( isLogin() === true ) {
      initKeywordI18nFromCrm().then(() => setIsInitialized(true));
    }else{
      redirect('/login');
    }
  }, [])

  if (!isInitialized) return <LoadingPanel />;

  return <OpportunityContextProvider>
    <BaseLayout
      isShowUserProfilePanel={isShowUserProfilePanel}
      breadcrumbInfo={breadcrumbInfo}
      linkListPropsArray={linkListPropsArray}
    >
      <Outlet context={{ setBreadcrumbInfo }} />
    </BaseLayout>
  </OpportunityContextProvider>
}

export default OpportunityPage;