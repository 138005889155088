import styled from "styled-components";
import {
  DateTimePicker,
  DateTimePickerProps,
  Calendar,
  CalendarProps,
  DateInput,
  DateInputProps,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { Popup, PopupProps } from "@progress/kendo-react-popup";

interface CustomDateTimePickerProps extends DateTimePickerProps {
  isShowNowTime?: boolean;
  onDateTimeChange?: (date: Date | null) => void;
}

const StyledDateTimePicker = styled.div`
  .setNowTime {
    color: ${(props) => props.theme.colorPrimary};
    font-weight: bold;
    line-height: 18px;
    margin-left: 12px;
    cursor: pointer;
    &:active {
      user-select: none;
    }
  }
`;

const StyledCalendar = styled(Calendar)``;

const CustomDateTimePicker = (props: CustomDateTimePickerProps) => {
  const { isShowNowTime, value, onDateTimeChange, ...others } = props;

  return (
    <StyledDateTimePicker>
      <DateTimePicker
        value={value}
        calendar={CustomCalendar}
        dateInput={CustomDateInput}
        popup={CustomPopup}
        onChange={(e: DateTimePickerChangeEvent) => {
          onDateTimeChange?.(e.value);
        }}
        {...others}
      />
      {isShowNowTime === true && (
        <span className="setNowTime" onClick={() => onDateTimeChange?.(new Date())}>
          現在時間
        </span>
      )}
    </StyledDateTimePicker>
  );
};

const CustomCalendar = (props: CalendarProps) => {
  return <StyledCalendar {...props} />;
};

const CustomDateInput = (props: DateInputProps) => {
  return <DateInput {...props} />;
};

const CustomPopup = (props: PopupProps) => {
  return (
    <Popup
      {...props}
      style={{ zIndex: "10004" }}
      anchorAlign={{
        horizontal: "center",
        vertical: "bottom",
      }}
      popupAlign={{
        horizontal: "center",
        vertical: "top",
      }}
    />
  );
};

export default CustomDateTimePicker;
