import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import axios from 'axios';
import otaClient from '@crowdin/ota-client';
import { XMLParser } from 'fast-xml-parser';
import * as microsoftTeams from '@microsoft/teams-js';
import { createClient } from './contexts/CrmClientContext';


// 因為 teams 的語系是用 zh-tw 這種格式，所以要轉換成 zh-TW
const langEnum = {
  'en': 'en',
  'ja': 'ja',
  'th': 'th',
  'vi': 'vi',
  'zh-cn': 'zh-CN',
  'zh-tw': 'zh-TW',
}
type LangEnum = typeof langEnum[keyof typeof langEnum];

const getTeamsLocale = (): Promise<string> => {
  const timeoutPromise = new Promise<string>((resolve) => {
    setTimeout(() => {
      resolve('zh-TW')
    }, 100000)
  })

  const teamsPromise = new Promise<string>((resolve) => {
    microsoftTeams.initialize(() => {
      microsoftTeams.getContext((context) => {
        let localeKey = context.locale as keyof typeof langEnum;

        if (['zh-cn', 'zh-tw'].includes(localeKey)) {
          resolve(langEnum[localeKey]);
        }else{
          localeKey = localeKey.split('-')[0] as keyof typeof langEnum;
          if (['en', 'ja', 'th', 'vi'].includes(localeKey)) {
            resolve(localeKey);
          }else{
            resolve(langEnum['en']);
          }
        }
      });
    })
  })
  return Promise.race([timeoutPromise, teamsPromise]);
}

const fetchI18nResult = async () => {
  const getI18nResult = async (locale: LangEnum) => {
    const hash = '79c384c55ec29d00f3c1e890yec'
    // issue: https://github.com/crowdin/ota-client-js/issues/64#issuecomment-1817884477

    const client = new otaClient(hash, {
      httpClient: {
        get: async (url) => {
          const res = await axios.get(url);
          return res.data;
        }
      }
    });

    try {
      const res = await client.getLanguageTranslations(locale);
      // 取得 XML 語系資料
      const responseData = res[0].content;

      // 將語系資料從 xml 轉為 JSON
      const options = {
        ignoreAttributes: false,
        attributeNamePrefix: ''
      };
      const parser = new XMLParser(options);
      const jsonObj = parser.parse(responseData).root.data;

      // 整理成 JSON 格式
      const jsonResult: Record<string, string> = {};
      jsonObj.forEach((translateData: { name: string; value: string; }) => {
        const key = translateData.name;
        const value = translateData.value;
        jsonResult[key] = value;
      });
      return jsonResult;
    } catch (error) {
      return console.error(error);
    }
  }



  const fetchI18n = async (locale: LangEnum) => {
    const i18nResult = await getI18nResult(locale);
    const result = {
      ...i18nResult,
    }
    return result
  }

  const locale = await getTeamsLocale();
  const i18nResult = await fetchI18n(locale);
  return i18nResult;
}


const fetchKeyWords = async (locale: LangEnum) => {
  const crmClient = createClient();
  const crmLocale = locale.slice(-2).toUpperCase();
  const allKeywords = await crmClient.get<any>('/stores/mine/settings/keywords', {});

  const keywords = allKeywords.data[crmLocale];
  return keywords;
}

export const defaultNS = "ns1";
export async function initI18n() {
  const resources = {
    tw: {
      ns1: await fetchI18nResult(),
    },
  } as const;

  await i18n.use(initReactI18next).init({
    lng: "tw",
    fallbackLng: "tw",
    ns: ["ns1"],
    interpolation: {
      useRawValueToEscape: true,
      prefix: "{",
      suffix: "}",
      escapeValue: false,
    },
    defaultNS,
    resources,
  });
}

export async function initKeywordI18nFromCrm() {
  const locale = await getTeamsLocale();
  const keywords = await fetchKeyWords(locale);
  i18n.addResourceBundle('tw', 'ns1', { ...keywords });
}


export default i18n;