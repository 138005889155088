import { useEffect, useRef, useState } from "react";
import { Outlet, useOutletContext, useLocation, useNavigate } from "react-router-dom";
import CustomerContext, { ICustomerContextValue } from '../../contexts/CustomerContext';
import BaseLayout from "../../components/layout/BaseLayout";
import { Customer } from "../../models/Customer";
import { BreadcrumbProps, defaultBreadcrumbProps } from "../../components/Breadcrumb";
import { initKeywordI18nFromCrm } from "../../i18n";
import LoadingPanel from "../../components/LoadingPanel";
import { isLogin } from "../../utils/isLogin";

const CustomerPage = () => {
  /**
   * - customer 系列如果有什麼共同要做的事，可以在這邊做
   * - 未來如果在 customer 底下，需要用不同 Layout 的話，可以在這邊做判斷
   */

  const [breadcrumbInfo, setBreadcrumbInfo] = useState<BreadcrumbProps>(defaultBreadcrumbProps);
  const { pathname } = useLocation();
  const isShowUserProfilePanel = ["/customer/list", "/", "/customer"].includes(pathname);
  const [customer, setCustomer] = useState<Customer>({
    id: '',
    customerNo: '',
    name: '',
    firstName: '',
    lastName: '',
    nickName: '',
    globalName: '',
    nationality: undefined,
    gender: 'Unknown',
    workingCareers: [],
    mobiles: [],
    phones: [],
    faxes: [],
    emails: [],
    postalAddresses: [],
    websites: [],
    createDate: '',
    createUserName: '',
    modifyDate: '',
    modifyUserName: '',
    dataPrivilegeData: {
      shareType: 'Everyone',
      teams: [],
      accounts: [],
      owners: [],
      displayShareType: '',
    },
    labels: [],
  });
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);

  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const CustomerContextValue: ICustomerContextValue = {
    customer: customer,
    setCustomer: setCustomer,
    submitBtnRef: submitBtnRef,
  }
  useEffect(() => {
    if ( isLogin() === true ) {
      initKeywordI18nFromCrm().then(() => setIsInitialized(true));
    }else {
      navigate("/login", { replace: true });
    }
  }, [])

  if (!isInitialized) return <LoadingPanel />;

  return (
    <CustomerContext.Provider value={CustomerContextValue}>
      <BaseLayout
        isShowUserProfilePanel={isShowUserProfilePanel}
        breadcrumbInfo={breadcrumbInfo}
      >
        <Outlet context={{ setBreadcrumbInfo }} />
      </BaseLayout>
    </CustomerContext.Provider>
  );
};

interface ICustomerOutletContext {
  setBreadcrumbInfo: React.Dispatch<React.SetStateAction<BreadcrumbProps>>;
};
export const useCustomerOutletContext = () => {
  return useOutletContext<ICustomerOutletContext>();
}

export default CustomerPage;
