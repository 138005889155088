import axios, { AxiosError, AxiosInstance } from "axios";
import { createContext, ReactNode, useContext } from "react";

interface CrmClientContextProviderProps {
  children: ReactNode;
}

export const CrmClientContext = createContext<AxiosInstance | undefined>(undefined);

export function useCrmClient() {
  return useContext(CrmClientContext);
}

function CrmClientContextProvider(props: CrmClientContextProviderProps) {
  const crmClient = createClient();

  return (
    <CrmClientContext.Provider value={crmClient}>
      {props.children}
    </CrmClientContext.Provider>
  );
}

// 因為 i18n.ts 沒有包在 crmClientContextProvider 裡面，所以要寫一個 createClient() 來使用
export function createClient() {
  const crmClient = axios.create({
    baseURL: process.env.REACT_APP_CRM_TEAMS_API_BASE_URL,
    timeout: parseInt(process.env.REACT_APP_CRM_TEAMS_API_DEFAULT_TIMEOUT_SECOND || '30', 10) * 1000
  });
  crmClient.interceptors.request.use(function (config) {
    // Pass through site name composition because authentication api endpoints are special.
    if ((config.url?.indexOf('authentication') || -1) >= 0) {
      return config;
    }

    // Handle base api endpoint and jwt.
    const siteName = localStorage.getItem('crm.currentSiteName');
    if (!siteName) {
      const controller = new AbortController();
      config.signal = controller.signal;
      controller.abort('Site name is required.');

      window.location.replace('/login');

      return config;
    }
    const credential = localStorage.getItem(`crm.${siteName}.credential`);
    const { jwt } = JSON.parse(credential || '{}');
    if (!jwt) {
      const controller = new AbortController();
      config.signal = controller.signal;
      controller.abort('Token is required.');

      window.location.replace('/login');

      return config;
    }

    config.baseURL += `/${siteName}/api`;
    config.headers!.authorization = `Bearer ${jwt}`;

    return config;
  }, function (error) {
    return Promise.reject(error);
  });
  crmClient.interceptors.response.use(function (response) {
    return response;
  }, function (error: AxiosError) {
    console.log('error', error)
    switch (error.response?.status) {
      case 401:
        window.location.replace('/login');
        break;
    }
    return Promise.reject(error);
  });

  return crmClient;
}

export default CrmClientContextProvider;
