import { useTranslation } from 'react-i18next';

export type RouteName =
  | "Login"
  | "CustomerShow"
  | "CustomerCreate"
  | "CustomerEdit"
  | "CustomerList"
  | "OpportunityList"
  | "OpportunityItemList"
  | "ErrorPage";

export interface IRoute {
  name: RouteName;
  paths: string[];
  pageTitle: string;
  isShowPageTitle?: boolean;
  isShowBreadcrumbName?: boolean;
}
/**
 * 新頁面需要 按鈕列 or 麵包屑(Breadcrumb) 的話，就來這邊新增一筆資料
 * name: 該頁的名字
 * paths: 該頁的 path, 可使用 matchPath 去判斷
 * pageTitle: 該頁的標題
 * isShowPageTitle: 該頁麵包屑要不要 show 標題
 * isShowBreadcrumbName: 該頁麵包屑要不要 show BreadcrumbName (動態的資料)
 */

export const useRoutes = (): IRoute[]  => {
  const { t } = useTranslation();
  const routes: IRoute[] = [
    {
      name: 'Login',
      paths: ['/login'],
      pageTitle: t('Com.SingIn.Account'),
    },
    {
      name: 'CustomerList',
      paths: ['/', '/customer', '/customer/list'],
      pageTitle: t('User.Custom.KeyWord.Customer'),
    },
    {
      name: 'CustomerShow',
      paths: ['/customer/show/:customerId'],
      pageTitle: t('User.Custom.KeyWord.Customer'),
      isShowBreadcrumbName: true,
    },
    {
      name: 'CustomerCreate',
      paths: ['/customer/create'],
      pageTitle: `${t('Com.New')}${t('User.Custom.KeyWord.Customer')}`
    },
    {
      name: 'CustomerEdit',
      paths: ['/customer/edit/:id'],
      pageTitle: t("Com.EditSomething", { "0": t("User.Custom.KeyWord.Customer") })
    },
    {
      name: 'OpportunityList',
      paths: ['/opportunity', '/opportunity/list'],
      pageTitle: t("User.Custom.KeyWord.Opportunity"),
    },
    {
      name: 'OpportunityItemList',
      paths: ['/opportunity/item/list', '/opportunity/item'],
      pageTitle: t("User.Custom.KeyWord.Opportunity"),
    },
    {
      name: 'ErrorPage',
      paths: ['*'],
      pageTitle: t("Com.PageNotFound")
    }
  ]
  return routes;
}