import { rgba } from "polished";
import { useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import styled from "styled-components";
import { useCrmClient } from "../../contexts/CrmClientContext";
import { ContactMechType } from "../../models/ContactMechType";
import { Customer, emptyCustomer } from "../../models/Customer";
import Editor from "./Editor";
import LoadingPanel from "../../components/LoadingPanel";
import CustomerContext from '../../contexts/CustomerContext';


const Content = styled.div`
  height: calc(100vh - 90px);
  background-color: ${props => props.theme.colorWhite};
  box-shadow: 0px 6px 10px ${props => rgba(props.theme.colorGray800, 0.2)};
  border-radius: 4px;
  overflow-y: auto;

  @media screen and (${props => props.theme.devices.tablet.mediaQuery}) {
    margin: 14px 12px 0 12px;
  }
`;

function CustomerEditor() {
  const crmClient = useCrmClient();
  const { customerId: id } = useParams();
  const navigate = useNavigate();
  const [isCreateMode] = useState<boolean>(!id);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [requiredColumnNames, setRequiredColumnNames] = useState<string[]>(['name']);

  const { setCustomer } = useContext(CustomerContext) || {};
  const [mobileTypes, setMobileTypes] = useState<ContactMechType[]>([]);
  const [phoneTypes, setPhoneTypes] = useState<ContactMechType[]>([]);
  const [faxTypes, setFaxTypes] = useState<ContactMechType[]>([]);
  const [addressTypes, setAddressTypes] = useState<ContactMechType[]>([]);
  const [emailTypes, setEmailTypes] = useState<ContactMechType[]>([]);
  const [nationalities, setNationalities] = useState<string[]>([]);

  useEffectOnce(() => {
    crmClient!.get<ContactMechType[]>('stores/mine/settings/contactMechTypes', {
      params: {
        subtypes: 'MobilePhoneType,FixedPhoneType,FaxType,PostalAddressType,EmailType',
      },
    }).then((response) => {
      const mobileTypes = response.data.filter((contactMechType: ContactMechType) => contactMechType.subtype === 'MobilePhoneType');
      setMobileTypes(mobileTypes);

      const phoneTypes = response.data.filter((contactMechType: ContactMechType) => contactMechType.subtype === 'FixedPhoneType');
      setPhoneTypes(phoneTypes);

      const faxTypes = response.data.filter((contactMechType: ContactMechType) => contactMechType.subtype === 'FaxType');
      setFaxTypes(faxTypes);

      const addressTypes = response.data.filter((contactMechType: ContactMechType) => contactMechType.subtype === 'PostalAddressType');
      setAddressTypes(addressTypes);

      const emailTypes = response.data.filter((contactMechType: ContactMechType) => contactMechType.subtype === 'EmailType');
      setEmailTypes(emailTypes);
    });

    // GET 國籍資料
    crmClient!.get<string[]>('stores/mine/settings/nationalities')
      .then((response) => setNationalities(response.data));

    // GET 必備欄位
    crmClient!.get<string[]>('stores/mine/settings/requiredColumns', {
      params: {
        domain: 'PersonAccount',
      },
    }).then((response) => setRequiredColumnNames(['name', ...response.data]));

    // 是修改客戶頁面的話，就去取得客戶資料
    if (!isCreateMode) {
      crmClient!.get<Customer>(`customers/${id}`)
        .then((response) => {
          if (setCustomer !== undefined) {
            setCustomer(response.data);
          } else {
            console.error('setCustomer is undefined')
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.log('get customer ERROR', error);
        });
    } else {
      setIsLoading(false);
      setCustomer && setCustomer(emptyCustomer);
    }
  });

  function customerEditorOnSubmit(customer: Customer) {
    const { id } = customer;
    setIsLoading(true);
    // 新增客戶
    if (!id) {
      crmClient?.post('customers', customer)
        .then(response => {
          if (response.status === 200) {
            const id = response.data;
            navigate(`/customer/show/${id}`, { replace: true });
          } else {
            console.log('post create customers -> got error', response);
          }
        })
        .catch(error => console.log('create Customer ERROR', error))
        .finally(() => {
          setIsLoading(false);
        })
    } else {
      // 修改客戶
      crmClient?.put(`customers/${id}`, customer)
        .then(response => {
          if (response.status === 200) {
            navigate(`/customer/show/${id}`, { replace: true });
          } else {
            console.log('put edit customers -> got error', response);
          }
        })
        .catch(error => console.log('update Customer ERROR', error))
        .finally(() => {
          setIsLoading(false);
        })
    }
  }

  return (
    <Content>
      {
        isLoading ?
          <LoadingPanel /> :
          (<Editor
            requiredColumnNames={requiredColumnNames}
            mobileTypes={mobileTypes}
            phoneTypes={phoneTypes}
            faxTypes={faxTypes}
            addressTypes={addressTypes}
            emailTypes={emailTypes}
            nationalities={nationalities}
            onSubmit={customerEditorOnSubmit} />)
      }
    </Content>
  );
}

export default CustomerEditor;
