import { createContext, RefObject, useContext } from "react";
import { Customer } from "../models/Customer";

export interface ICustomerContextValue {
  customer: Customer;
  setCustomer: React.Dispatch<React.SetStateAction<Customer>>;
  submitBtnRef: RefObject<HTMLButtonElement>;
}


const CustomerContext = createContext<ICustomerContextValue | undefined>(undefined);



export const useCustomerContext = () => {
  const context = useContext<ICustomerContextValue | undefined>(CustomerContext);
  if (context === undefined) {
    console.error('CustomerContext is undefined');
    return null;
  }
  return context;
}


export default CustomerContext;