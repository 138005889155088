import { useState } from "react";
import { useCrmClient } from "../../contexts/CrmClientContext";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faMobile } from "@fortawesome/pro-regular-svg-icons";
import defaultCustomerPicture from '../../images/user-profile-picture-default.gif';
import femaleDefaultCustomerPicture from '../../images/user-profile-picture-female.gif'
import CustomerPictureContent from "./CustomerPictureContent";
import Label from '../../components/shared/Label';
import { Customer as ICustomer } from '../../models/Customer';
import { WorkingCareer } from "../../models/WorkingCareer";
import { ContactMech } from "../../models/ContactMech";
import { Label as ILabel } from "../../models/Label";
import { useEffectOnce } from "react-use";


interface CustomerBaseInfoProps {
  customerData?: ICustomer;
  isMobile: boolean;
}

interface CompanyInfoProps {
  workingCareer: WorkingCareer;
}

interface ContactInfoProps {
  email?: ContactMech;
  phone?: ContactMech;
  mobile?: ContactMech;
}

interface LabelsInfoProps {
  labels: ILabel[];
}

interface CustomerBaseInfoContentProps {
  isMobile: boolean;
}
const CustomerBaseInfoContent = styled.div<CustomerBaseInfoContentProps>`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  padding: 16px 24px;
`


const CustomerBaseInfo = styled.div<CustomerBaseInfoProps>`
  margin-left: ${props => props.isMobile ? '' : '16px'};
  flex-grow: 1;
  .customerTitle{
    display: flex;
    flex-direction: row;
    gap: 24px;
    .customerName{
      color: ${(props) => props.theme.colorGray900};
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 8px;
    }
    .customerToolbar{
      margin-left: auto;
      a{
        color: ${(props) => props.theme.colorPrimary};
      }
      svg{
        margin-left: 24px;
        cursor: pointer;
      }
    }
  }
  .companyInfo{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 16px;
    .jobTitle, .companyName, .deptName{
      color: ${(props) => props.theme.colorGray800};
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
    }
  }
  .contactInfo{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
    color: ${(props) => props.theme.colorGray800};
    margin-bottom: 16px;
    .mail, .phone, .mobile{
      svg{
        margin-right: 4px;
      }
    }
  }
  .labelInfo{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }
`


const CompanyInfo = (props: CompanyInfoProps) => {
  const { workingCareer } = props;
  return (
    workingCareer ?
      <div className="companyInfo">
        {workingCareer.jobTitle ? (
          <span>
            <span className="jobTitle">{workingCareer.jobTitle}</span>
            <span>於</span>
          </span>
        ) : ""}
        <span className="companyName">{workingCareer.company?.name}</span>
        {workingCareer.departmentName && (<span className="deptName">({workingCareer.departmentName})</span>)}
      </div>
      : null
  )
}

const ContactInfo = (props: ContactInfoProps) => {
  const { email, phone, mobile } = props;

  return (
    <div className="contactInfo">
      {
        email &&
        <span className="mail">
          <FontAwesomeIcon icon={faEnvelope} />
          <span>{email.value}</span>
        </span>
      }

      {
        phone &&
        <span className="phone">
          <FontAwesomeIcon icon={faPhone} />
          <span>{phone.value}</span>
        </span>
      }

      {
        mobile &&
        <span className="mobile">
          <FontAwesomeIcon icon={faMobile} />
          <span>{mobile.value}</span>
        </span>
      }
    </div>
  )
}

const LabelsInfo = (props: LabelsInfoProps) => {
  const { labels } = props;
  if (labels.length === 0) {
    return null;
  }

  return (
    <div className="labelInfo">
      {
        labels.map((label) => {
          return (
            <Label key={label.id} styleId={label.styleId}>
              {label.displayName}
            </Label>
          )
        })
      }
    </div>
  )
}

const CustomerShowBaseInfo = (props: CustomerBaseInfoProps) => {
  const { customerData, isMobile } = props;
  const crmClient = useCrmClient();
  const [imgUrl, setImgUrl] = useState<string>('');
  const [isImgLoading, setIsImgLoading] = useState<boolean>(true);

  useEffectOnce(() => {
    crmClient!.get('customers/' + id + '/thumbnailPicture', {
      responseType: 'blob'
    })
      .then(response => {
        if (response.status === 200) {
          const file = new Blob([response.data]);
          const url = URL.createObjectURL(file);
          setImgUrl(url);
          setIsImgLoading(false);
        } else {
          gender === "Male" ? setImgUrl(defaultCustomerPicture) : setImgUrl(femaleDefaultCustomerPicture);
          setIsImgLoading(false);
        }
      })
      .catch((error) => {
        gender === "Male" ? setImgUrl(defaultCustomerPicture) : setImgUrl(femaleDefaultCustomerPicture);
        setIsImgLoading(false);
        console.log('get customer thumbnail ERROR', error)
      })
  });

  if (customerData === undefined) {
    return null;
  }

  const { id, name, workingCareers, emails, phones, mobiles, labels, gender } = customerData;
  const primaryCompanies = workingCareers.filter((workingCareer) => workingCareer.isPrimary);
  const primaryCompany = primaryCompanies[0];
  const primaryEmails = emails.filter((email) => email.isPrimary);
  const primaryEmail = primaryEmails[0];
  const primaryPhones = phones.filter((phone) => phone.isPrimary);
  const primaryPhone = primaryPhones[0];
  const primaryMobiles = mobiles.filter((mobile) => mobile.isPrimary);
  const primaryMobile = primaryMobiles[0];



  return (
    <CustomerBaseInfoContent isMobile={isMobile}>
      <CustomerPictureContent imgUrl={imgUrl} isImgLoading={isImgLoading} width={60} />
      <CustomerBaseInfo isMobile={isMobile}>
        <div className="customerTitle">
          <span className="customerName">{name}</span>
          {/* 這三顆先不用做
            <span className="customerToolbar">
              <a href="https://teams.microsoft.com/l/call/0/0?users=ryan_chu@gss.com.tw">
                <FontAwesomeIcon icon={faPhone}/>
              </a>
              <a href="https://teams.microsoft.com/l/call/0/0?users=ryan_chu@gss.com.tw&withVideo=true">
                <FontAwesomeIcon icon={faVideo}/>
                </a>
              <a href="https://teams.microsoft.com/l/chat/0/0?users=ryan_chu@gss.com.tw">
                <FontAwesomeIcon icon={faCommentLines}/>
              </a>
            </span>
            */}
        </div>

        <CompanyInfo workingCareer={primaryCompany} />
        <ContactInfo email={primaryEmail} phone={primaryPhone} mobile={primaryMobile} />
        <LabelsInfo labels={labels} />
      </CustomerBaseInfo>
    </CustomerBaseInfoContent>
  );
};

export default CustomerShowBaseInfo;
