import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { rgba } from "polished";
import ButtonBar, { ButtonProps } from "../../pages/shared/ButtonBar";
import UserProfilePanel from "../UserProfilePanel";
import Breadcrumb, { BreadcrumbProps } from "../Breadcrumb";
import TabLink from "../shared/TabLink";

interface BaseLayoutProps {
  title?: string;
  children?: ReactNode | never[];
  buttonList?: ButtonProps[];
  linkListPropsArray?: LinkListProps[];
  isShowUserProfilePanel: boolean;
  breadcrumbInfo: BreadcrumbProps;
}

export interface LinkListProps {
  label: string;
  paths: string[];
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin: 5px 20px 0px 20px;
  height: 40px;
`;

const Container = styled.div`
  margin: 16px;
  background-color: ${(props) => props.theme.colorWhite};
  box-shadow: 0px 6px 10px ${(props) => rgba(props.theme.colorGray800, 0.2)};
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px);
  border-radius: 4px;
`;

const TabLinkContainer = styled.div`
  display: flex;
  gap: 24px;
`

/**
 * BaseLayout 為 UI Component, 目的是為了顯示資料
 * 盡量讓此 component 不要有 effect
 */
const BaseLayout = (props: BaseLayoutProps) => {
  const { isShowUserProfilePanel, breadcrumbInfo, linkListPropsArray, children } = props;
  const navigate = useNavigate();
  const siteName = localStorage.getItem("crm.currentSiteName");
  const credential = localStorage.getItem(`crm.${siteName}.credential`);
  const { jwt } = JSON.parse(credential || "{}");

  useEffect(() => {
    if (!siteName) navigate("/login", { replace: true });
    if (!jwt) navigate("/login", { replace: true });
  }, [siteName, jwt, navigate])


  return (
    <>
      <Header>
        <Breadcrumb {...breadcrumbInfo} />
        {linkListPropsArray && <TabLinkContainer>{linkListPropsArray.map((linkListProps, index) => <TabLink key={index} {...linkListProps} />)} </TabLinkContainer>}
        <ButtonBar />
        {isShowUserProfilePanel && <UserProfilePanel />}
      </Header>
      <Container>
        {children}
      </Container>
    </>
  );
};

export default BaseLayout;
