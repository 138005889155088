import { createGlobalStyle } from 'styled-components';
import * as overwriteStyle from './vendor-overwrite';

export const GlobalStyle = createGlobalStyle`
  ${overwriteStyle.kendo};

  html {
    font-family: "PingFang TC","Microsoft JhengHei",sans-serif;
    overflow: hidden;
  }
  body {
    overflow-x: hidden;
  }
  html, body, #root {
    height: 100%;
  }
  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
  input,textarea {
    outline: none;
    font-family: 'PingFang TC','Microsoft JhengHei',sans-serif;
    font-weight: 500;
  }
`