import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler } from "react";
import styled, { css } from "styled-components";
import Link, { LinkProps } from "./Link";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface IconLinkProps extends LinkProps {
  className?: string;
  icon: IconProp;
  linkText: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  marginTop?: string;
  marginLeft?: string;
  isError?: boolean;
  wrapperStyleDisableFlex?: boolean;
}

interface LinkWrapperProps {
  marginTop?: string;
  marginLeft?: string;
  isError?: boolean;
  disableFlex?: boolean;
}
const LinkWrapper = styled.div<LinkWrapperProps>`
  ${props => props.disableFlex ? '' : css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  `}
  margin-top: ${props => props.marginTop || ''};
  margin-left: ${props => props.marginLeft || ''};
  color: ${props => props.isError
    ? props.theme.colorError
    : props.theme.colorPrimary};
  cursor: pointer;
  a{
    font-weight: bold;
  }
`;

const IconLink = (props: IconLinkProps) => {
  const {
    className,
    icon,
    linkText,
    onClick,
    marginTop,
    marginLeft,
    isError,
    wrapperStyleDisableFlex,
    ...others
  } = props;

  return (
    <LinkWrapper className={className}
      marginTop={marginTop}
      marginLeft={marginLeft}
      isError={isError}
      disableFlex={wrapperStyleDisableFlex}
      onClick={onClick}>
      <FontAwesomeIcon icon={icon} fontSize="14px" width="14px" />
      <Link isError={isError}
        marginLeft="4px"
        {...others}>
        {linkText}
      </Link>
    </LinkWrapper>
  );
}

export default IconLink;
