import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as microsoftTeams from '@microsoft/teams-js';
import { initI18n } from './i18n';

// Must execute in Microsoft Teams in production mode.
let isSupported = process.env.NODE_ENV !== 'production';
const appCheckThreshold = 3;
let appCheckAttempts = 0;


// Check execution environment.
appCheckAttempts += 1;
microsoftTeams.initialize(() => isSupported = true);

// Check Microsoft Teams initialization whether finish.
const appCheck = setInterval(() => {
  if (appCheckAttempts > appCheckThreshold) {
    const messageElement = document.getElementById('message');
    if (messageElement) {
      messageElement.setAttribute('style', 'color: #EB5000;');
      messageElement.innerHTML = 'This website does not support executing outside Microsoft Teams now.<br />Please open your Microsoft Teams and go to Teams Store, then find and use Vital CRM.';
    }
    appCheck && clearInterval(appCheck);
    return;
  }

  if (isSupported) {
    initializeApp();
    appCheck && clearInterval(appCheck);
  } else {
    appCheckAttempts += 1;
    microsoftTeams.initialize(() => isSupported = true);
  }
}, 300);

async function initializeApp() {
  await initI18n();
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  );
  root.render(
    <App />
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
