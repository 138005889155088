import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useCrmClient } from "../../contexts/CrmClientContext";
import { Store } from "../../models/Store";
import crmLogoImage from "../../images/crm-logo-horizontal.png";
import { AxiosError } from "axios";
import ButtonLink from "../../components/shared/ButtonLink";
import Button from "../../components/shared/Button";
import Link from "../../components/Link";
import { Navigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from 'react-i18next';

interface StoreSelectorProps {
  gssCloudAccessToken: string;
  onLogout?: Function;
}

interface StoreSelectorState {
  stores: Store[];
  selectedSiteName: string;

  title: string;
  errorMessage: string;
  isAuthenticating: boolean;
  isLoggingIn: boolean;
  navigateTo: string;
}

const StoreSelectorComponent = styled.div`
  padding-top: 40px;
`;

const Logo = styled.div`
  width: 216px;
  height: 72px;
  margin: 0 auto;
  background-image: url(${crmLogoImage});
  background-position: center;
  background-size: 216px 72px;
  background-repeat: no-repeat;
`;

interface TitleProps {
  isError?: boolean;
}

const Title = styled.div<TitleProps>`
  margin-top: ${props => props.isError ? '16px' : '40px'};
  text-align: center;
  font-weight: 500;
  line-height: 20px;
  color: ${props => props.theme[props.isError ? 'colorError' : 'colorGray700']};
`;

const ListContainer = styled.div`
  position: relative;
  margin: 16px auto 0 auto;
  width: 368px;
`;

const List = styled.div`
  max-height: 220px;
  padding-bottom: 30px;
  overflow: auto;
`;

interface ListItemProps {
  isActive?: boolean;
}

const ListItem = styled.div<ListItemProps>`
  padding: 10px 16px;
  border-radius: 4px;
  border: ${props => props.isActive ? '1px solid #0095FF' : '1px solid #FFF'};
  background-color: ${props => props.isActive ? '#EBF5FF' : ''};

  &:hover {
    cursor: pointer;
    background-color: ${props => props.isActive ? '' : props.theme.colorGray100};
  }
`;

const ListFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
`;

const ButtonsWrapper = styled.div`
  margin-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

const StoreSelector = (props: StoreSelectorProps) => {
  const { t } = useTranslation();
  const crmClient = useCrmClient();
  const { gssCloudAccessToken } = props;
  const [state, setState] = useState<StoreSelectorState>({
    stores: [],
    selectedSiteName: '',
    title: `${t("Sysem.Authenticating")}......`,
    errorMessage: '',
    isAuthenticating: true,
    isLoggingIn: false,
    navigateTo: '',
  });


  const onLoginClick = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      isLoggingIn: true
    }));
    crmClient!.post<{ jwt: string }>(`${state.selectedSiteName}/api/authentication/jwt`, {
      token: gssCloudAccessToken,
    })
      .then(response => {
        localStorage.setItem('crm.currentSiteName', state.selectedSiteName);
        const credential = { siteName: state.selectedSiteName, jwt: response.data.jwt };
        localStorage.setItem(`crm.${state.selectedSiteName}.credential`, JSON.stringify(credential));
        setState(prevState => ({
          ...prevState,
          navigateTo: '/customer/list'
        }));
      }, (error: AxiosError<{ messages: string[] }>) =>
        setState(prevState => ({
          ...prevState,
          errorMessage: `${t("System.LoginFail")}，${t("System.GotError")}${t("Com.MoreInfo")}：${error.response ? error.response.data.messages[0] : error.message}`
        }))
      )
      .catch(_ => setState(prevState => ({
        ...prevState
        , errorMessage: `${t("System.LoginFail")}，${t("System.GotError")}`
      })))
      .finally(() => setState(prevState => ({
        ...prevState,
        isLoggingIn: false
      })));
  }, [crmClient, gssCloudAccessToken, state.selectedSiteName, t]);

  useEffect(() => {
    crmClient!.get<Store[]>('api/authentication/stores', {
      params: {
        token: gssCloudAccessToken
      }
    }).then(response => {
      if (response.data.length === 0) {
        setState(prevState => ({ ...prevState, title: t("System.NoAvailablePlatforms") }));
      } else if (response.data.length === 1) {
        setState(prevState => ({
          ...prevState,
          title: t("Com.SelectStore"),
          stores: response.data,
          selectedSiteName: response.data[0].siteName,
          errorMessage: '',
        }));
        onLoginClick()
      } else {
        setState(prevState => ({
          ...prevState,
          title: t("Com.SelectStore"),
          stores: response.data
        }));
      }
    }, (error: AxiosError<{ messages: string[] }>) =>
      setState(prevState =>
      ({
        ...prevState,
        errorMessage: `${t("Chuangming.101")}，${t("System.GotError")}。${t("Com.MoreInfo")}：${error.response ? error.response.data.messages[0] : error.message}`
      }))
    )
      .catch(_ => setState(prevState => ({ ...prevState, errorMessage: `${t("Chuangming.101")}，${t("System.GotError")}。` })))
      .finally(() => setState(prevState => ({ ...prevState, isAuthenticating: false })));
  }, [crmClient, gssCloudAccessToken, t, onLoginClick])

  function onStoreClick(siteName: string): React.MouseEventHandler<HTMLDivElement> | undefined {
    setState(prevState => ({
      ...prevState,
      selectedSiteName: prevState.selectedSiteName === siteName ? '' : siteName,
      errorMessage: ''
    }));
    return;
  }

  function onLogout() {
    localStorage.removeItem('crm.currentSiteName');
    localStorage.removeItem(`crm.${state.selectedSiteName}.credential`);
    props.onLogout?.();
  }

  return (
    <StoreSelectorComponent>
      <Logo />
      <Title>{state.title}</Title>
      {!!state.errorMessage && <Title isError={true}>{state.errorMessage}</Title>}
      {state.stores.length > 0 && <ListContainer>
        <List>
          {state.stores.map(s => {
            return <ListItem key={s.siteName.toString()}
              isActive={state.selectedSiteName === s.siteName}
              onClick={_ => onStoreClick(s.siteName)}>{s.storeName}</ListItem>
          })}
        </List>
        <ListFooter />
      </ListContainer>}
      {!state.isAuthenticating && <ButtonsWrapper>
        {!state.errorMessage && (
          state.stores.length === 0
            ? <ButtonLink target="_blank"
              href={`${process.env.REACT_APP_CRM_GSS_COULD_BASE_URL}`}
              isPrimary={true}
              hasChildren={true}>
              <span>{t("System.Vital.CloudService")}</span>
              <FontAwesomeIcon icon={faExternalLink} width="14px" style={{ marginLeft: 8 }} />
            </ButtonLink>
            : <Button isPrimary={true}
              isDisabled={!state.selectedSiteName || state.isLoggingIn}
              disabled={!state.selectedSiteName || state.isLoggingIn}
              content={t("Com.System.StartUsing") as string}
              onClick={onLoginClick}>{t("Com.System.StartUsing")}</Button>)}
        <Link target="_blank"
          href={`${process.env.REACT_APP_CRM_GSS_COULD_MEMBER_BASE_URL}/cas/logout`}
          isNewLine={true}
          fontSize="16px"
          onClick={onLogout}>{t("Com.SingOut.Account")}</Link>
      </ButtonsWrapper>}
      {state.navigateTo && <Navigate to={state.navigateTo} replace={true} />}
    </StoreSelectorComponent>
  );
}

export default StoreSelector;
