import * as microsoftTeams from '@microsoft/teams-js';
import { useState } from "react";
import styled, { css } from "styled-components";
import heroImage from "../../images/login-hero.png";
import crmLogoWithTitleImage from "../../images/crm-logo-with-title-horizontal.png";
import Button from "../../components/shared/Button";
import ButtonLink from "../../components/shared/ButtonLink";
import StoreSelector from "./StoreSelector";
import mobileHeroImage from "../../images/login-hero.mobile.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

interface LoginComponentProps {
  showBackgroundImage: boolean;
}

const LoginComponent = styled.div<LoginComponentProps>`
  width: 100%;
  height: 100%;
  background-color: #FFF;
  ${props => props.showBackgroundImage
    ? css`
      background-image: url(${heroImage});
      background-position: right top;
      background-size: 50%;
      background-repeat: no-repeat;
    `
    : ''}

  @media screen and (${props => props.theme.devices.tablet.mediaQuery}) {
    ${props => props.showBackgroundImage
      ? 'background-image: none;'
      : ''}
    overflow-y: auto;
  }
`;

const Content = styled.div`
  width: calc(45% - 118px);
  padding-top: 140px;
  padding-left: 118px;

  @media screen and  (${props => props.theme.devices.tablet.mediaQuery}) {
    width: 100%;
    text-align: center;
    padding-top: 80px;
    padding-left: initial;
  }
`;

const Description = styled.div`
  margin-top: 40px;
  margin-left: 110px;
  color: ${props => props.theme.colorGray800};
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.05em;

  @media screen and (${props => props.theme.devices.tablet.mediaQuery}) {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
  margin-top: 40px;
  margin-left: 110px;

  @media screen and (${props => props.theme.devices.tablet.mediaQuery}) {
    margin-left: initial;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

const ErrorMessage = styled.div`
  margin-top: 20px;
  margin-left: 110px;
  color: ${props => props.theme.colorError};

  @media screen and (${props => props.theme.devices.tablet.mediaQuery}) {
    margin-left: initial;
  }
`;

const GssCloudLoginButton = styled(Button)`
  background-color: #FFE76B;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.08em;
  cursor: pointer;
  outline: none;
  transition: box-shadow 0.3s, background-color 0.3s;

  // Override button style.
  padding: 12px 24px;
  border-radius: 4px;
  box-shadow: none;
  color: ${props => props.theme.colorGray900};

  &:hover {
    box-shadow: 0 8px 20px rgb(255 231 107 / 40%);
    background-color: #FFE042;
  }
`;

const TrialButtonLink = styled(ButtonLink)`
  padding: 12px 24px;
  background-color: #0095FF;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.08em;
  outline: none;
  transition: box-shadow 0.3s, background-color 0.3s;

  // Override button style.
  border-radius: 4px;
  box-shadow: none;
  color: #FFF;

  &:hover {
    box-shadow: 0 8px 20px rgb(0 149 255 / 40%);
    background-color: #0083E0;
  }
`;

const MobileLoginImage = styled.img.attrs({
  src: mobileHeroImage,
})`
  display: none;

  @media screen and (${props => props.theme.devices.tablet.mediaQuery}) {
    display: block;
    width: 100%;
    max-width: 500px;
    margin: 32px auto 0 auto;
  }
`;

const parseAuthenticationResult = (result: string = '') => {
  let isSuccessful: boolean = false;
  let accessToken: string = '';
  let error: string = '';
  
  result.split('_vitalcrm-result_')
    .forEach((parameter: string) => {
      const pair = parameter.split('_vitalcrm-parameter_');
      switch (pair[0]) {
        case 'isSuccessful':
          isSuccessful = pair[1] === 'true';
          break;
        case 'accessToken':
          accessToken = pair[1];
          break;
        default:
          error = pair[1];
          break;
      }
    });
  
  return { isSuccessful, accessToken, error };
}

function Login() {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [gssCloudAccessToken, setGssCloudAccessToken] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  function onLoginClick() {
    microsoftTeams.authentication.authenticate({
      url: `${process.env.REACT_APP_CRM_TEAMS_API_BASE_URL}/authentication/login?scheme=vitalcrm&oauthRedirectMethod={oauthRedirectMethod}&authId={authId}`,
      isExternal: true,
      width: 1200,
      height: 800,
      successCallback: (result: string) => {
        const { isSuccessful, accessToken, error } = parseAuthenticationResult(result);
        if (isSuccessful) {
          setGssCloudAccessToken(accessToken);
          setIsAuthenticated(true);
        } else {
          setErrorMessage(`${t("System.LoginError.GSSCloud")}，${t("Com.MoreInfo")}：${error}。`);
        }
      },
      failureCallback: (result: string) => {
        const { error } = parseAuthenticationResult(result);
        setErrorMessage(`${t("System.LoginError.GSSCloud")}，${t("Com.MoreInfo")}：${error || result}。`);
      }
    } as microsoftTeams.authentication.AuthenticateParameters);
  }

  function onLogout() {
    setIsAuthenticated(false);
  }

  return (
    <LoginComponent showBackgroundImage={!isAuthenticated}>
      {!isAuthenticated
        ? <Content>
          <img src={crmLogoWithTitleImage} width="350" alt="crmLogoWithTitleImage" />
          <Description>{t("Teams.Login.Description")}</Description>
          <ButtonsWrapper>
            <GssCloudLoginButton onClick={onLoginClick}>{t("Com.EnterCRM")}</GssCloudLoginButton>
            <TrialButtonLink target="_blank" href={`${process.env.REACT_APP_CRM_GSS_COULD_BASE_URL}`}>
              <span>{t("System.Vital.CloudService")}</span>
              <FontAwesomeIcon icon={faExternalLink} width="16px" style={{ marginLeft: 8, fontSize: 16 }} />
            </TrialButtonLink>
          </ButtonsWrapper>
          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          <MobileLoginImage />
        </Content>
        : <StoreSelector gssCloudAccessToken={gssCloudAccessToken} onLogout={onLogout}></StoreSelector>
      }
    </LoginComponent>
  );
}

export default Login;
