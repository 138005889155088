import { faPlus, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import { Field, FieldArray, FieldArrayRenderProps, Form, FormElement, FormRenderProps } from "@progress/kendo-react-form";
import { RadioButtonChangeEvent, RadioButtonProps } from "@progress/kendo-react-inputs";
import { useState, useContext } from "react";
import CustomerContext from '../../contexts/CustomerContext'
import styled, { css, ThemeContext } from "styled-components";
import { EditorItemCheckbox } from "../../components/EditorItemCheckbox";
import { EditorItemInput } from "../../components/EditorItemInput";
import { EditorItemRadioButton } from "../../components/EditorItemRadioButton";
import { EditorItemRadioButtonGroup } from "../../components/EditorItemRadioButtonGroup";
import { EditorItemDropdownList } from "../../components/EditorItemDropdownList";
import { EditorItemPostalAddressDropdownlist } from '../../components/EditorItemPostalAddressDropdownlist';
import IconLink from "../../components/IconLink";
import { Customer } from "../../models/Customer";
import { WorkingCareer } from "../../models/WorkingCareer";
import { ContactMech } from "../../models/ContactMech";
import { PostalAddress } from "../../models/PostalAddress";
import { v4 as uuidv4 } from "uuid";
import { useEffectOnce, useMedia } from "react-use";
import { ContactMechType } from "../../models/ContactMechType";
import { EditorItemCompanyDropdownList } from "../../components/EditorItemCompanyDropdownList";
import { Company } from "../../models/Company";
import HorizontalLine from "../../components/shared/HorizontalLine";
import { useTranslation } from 'react-i18next';

interface EditorProps {
  requiredColumnNames: string[];
  mobileTypes: ContactMechType[];
  phoneTypes: ContactMechType[];
  faxTypes: ContactMechType[];
  addressTypes: ContactMechType[];
  emailTypes: ContactMechType[];
  nationalities: string[];
  onSubmit: (customer: Customer) => void;
}

// This rule is the same as the one on CRM web.
const emailRegex = new RegExp(/^([^<>()\[\]\\.,;:\s@""]+(\.[^<>()\[\]\\.,;:\s@""]+)*)@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/);

// Custom Kendo Form and related components style.
const StyledForm = styled(FormElement)`
  color: ${props => props.theme.colorGray700};
  font-size: 14px;
  line-height: 20px;
  padding: 40px;
  @media screen and (${props => props.theme.devices.mobile.mediaQuery}){
    padding: 24px;
  }

  .k-form-error{
    color: ${props => props.theme.colorError};
    font-style: normal;
    font-size: 12px;
  }
  .k-input{
    border: 1px solid ${props => props.theme.colorGray300};
    &:hover{
      border: 1px solid ${props => props.theme.colorGray500};
    }
    &:focus{
      border: 1px solid ${props => props.theme.colorGray600};
    }
  }
  .k-form-field {
    margin-top: 0;
    height: 56px;
  }

  .nameDetailContent{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    .nameField{
      display: flex;
    }
    @media screen and (${props => props.theme.devices.mobile.mediaQuery}){
      div{
        margin-left: 0;
      }
      .k-form-field{
        .k-label{
          margin-right: 8px;
        }
      }
      input{
        width: 80px;
      }
    }
  }


  @media screen and (${props => props.theme.devices.mobile.mediaQuery}){
    .k-form-field {
      width: 100%;
    }
    .boldEditorItemTitle{
      font-size: 18px;
      font-weight: bold;
      color: ${props => props.theme.colorGray800};
    }

    .editorItemsContainer{
      padding: 22px 12px;
      margin-bottom: 20px;
      border: 1px solid ${props => props.theme.colorGray200};
      border-radius: 4px;
      background-color: ${props => props.theme.colorGray100};

      .k-form-field{
        & > div {
          margin-left: 0;
          flex-direction: column;
        }
        .k-label{
          line-height: 20px;
        }
      }
      /* 公司資訊 - 公司(下拉選單) */
      .workingCareersDorpdownList{
        width: 100%;
      }

      .removeItem{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const EditorItemsContainer = styled.div`
  @media screen and (${props => props.theme.devices.mobile.mediaQuery}) {
    width: 100%;
  }
`;

// Editor item style.
interface EditorItemsWrapperProps {
  isChildren?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}
const EditorItemsWrapper = styled.div<EditorItemsWrapperProps>`
  display: flex;
  flex-direction: row;
  @media screen and (${props => props.theme.devices.mobile.mediaQuery}){
    flex-direction: column;
    gap: 8px
  }
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 3.5rem;
  @media screen and (${props => props.theme.devices.mobile.mediaQuery}){
    min-height: 0;
  }
  /**
   * isChildren === true -> margin-top: 0px;
   * isFirst === true -> margin-top: 24px;
   * isFirst === false -> margin-top: 16px;
   * isLast === true -> margin-bottom: 24px;
   */
  margin-top: ${props => props.isChildren
    ? '0px'
    : props.isFirst
      ? '24px' : '0px'};
  ${props => !props.isLast ? null : css`
    margin-bottom: 24px;
  `}
`;

const EditorItemsAddActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 1.5rem;
`
interface EditorItemTitleProps {
  isRequired?: boolean;
}
const EditorItemTitle = styled.div<EditorItemTitleProps>`
  position: relative;
  width: 100px;

  ${props => props.isRequired ? css`
    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: -8px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: ${props => props.theme.colorError};
    }
  ` : null}
`;
interface EditorItemDelimiterProps {
  distance?: 'Near' | 'Middle' | 'Far',
}
const EditorItemDelimiter = styled.div<EditorItemDelimiterProps>`
  margin-left: ${props => {
    switch (props.distance) {
      case 'Near':
        return '4px';
      case 'Middle':
        return '8px';
      case 'Far':
        return '16px';
      default:
        return '';
    }
  }};
`;

interface WorkingCareerViewModel extends WorkingCareer {
  rowId: string;
}
interface WorkingCareerProps extends FieldArrayRenderProps {
  value: WorkingCareerViewModel[];
  onPrimaryChange: (rowId: string) => void;
  companyValidator?: (value: Company) => string;
  jobTitleValidator?: (value: string) => string;
  isMobile: boolean;
}
const WorkingCareerEditor = (props: WorkingCareerProps) => {
  const { t } = useTranslation();
  // Initailize primary row ID state.
  const primaryWorkingCareer = props.value.find(
    (workingCareerViewModel: WorkingCareerViewModel) => workingCareerViewModel.isPrimary
  ) || props.value[0];
  const [primaryRowId, setPrimaryRowId] = useState<string>(primaryWorkingCareer.rowId);
  const { isMobile, companyValidator, jobTitleValidator } = props;

  function onAdd() {
    props.onPush({
      value: {
        rowId: uuidv4(),
        isOnTheJob: true,
        isPrimary: false,
      },
    });
  }
  function onPrimaryChange(event: RadioButtonChangeEvent) {
    setPrimaryRowId(event.value);
    props.onPrimaryChange(event.value);
  }
  function onRemove(rowId: string) {
    // Remove the target when re-rendering.
    const index = props.value.findIndex((workingCareerViewModel: WorkingCareerViewModel) => workingCareerViewModel.rowId === rowId);
    props.onRemove({ index });

    // Removed one is primary.
    if (primaryRowId === rowId) {
      // 找出有勾在職的第一筆資料
      const onTheJobFirstIndex = props.value.findIndex(workingCareerViewModel => workingCareerViewModel.isOnTheJob === true);
      const onTheJobData = props.value.filter(workingCareerViewModel => workingCareerViewModel.isOnTheJob === true);
      /**
       * 如果要刪除的是主要，檢查要刪的是不是第一筆在職:
       *   1.1 是的話，檢查看有無其它在職資料，有的話，就設其它在職的第一筆為主要，沒有的話，就設第一筆資料為主要
       *   1.2 不是的話，檢查要刪的，不是第一筆在職的話，就看有無其它在職，有的話就設在職的第一筆為主要，沒有的話，就設第一筆為主要
       */
      let targetIndex;
      if (onTheJobFirstIndex === index) {
        targetIndex = onTheJobData.length > 1 ? props.value.findIndex(workingCareerViewModel => workingCareerViewModel.rowId === onTheJobData[1].rowId) : 0;
      } else {
        targetIndex = onTheJobData.length === 1 ? 0 : onTheJobFirstIndex;
      }

      const newPrimaryWorkingCareer = props.value[targetIndex];
      newPrimaryWorkingCareer.isPrimary = true;
      setPrimaryRowId(newPrimaryWorkingCareer.rowId);
      props.onPrimaryChange(newPrimaryWorkingCareer.rowId);
    }
  }

  return (
    <EditorItemsContainer>
      {props.value.map((workingCareerViewModel: WorkingCareerViewModel, index: number) => {
        return (
          <EditorItemsWrapper className="editorItemsContainer" key={workingCareerViewModel.rowId} isChildren={true}>
            <EditorItemRadioButton name="workingCareers.isPrimary"
              value={workingCareerViewModel.rowId}
              checked={primaryRowId === workingCareerViewModel.rowId}
              label={t("PartyContactMech.IsPrimary") as string}
              onChange={onPrimaryChange}
            />
            <Field name={`workingCareers[${index}].company`}
              label={isMobile ? t("User.Custom.KeyWord.Company") : ""}
              className="workingCareersDorpdownList"
              component={EditorItemCompanyDropdownList}
              editorItemDistance="Far"
              dropdownListSize="Middle"
              validator={companyValidator}
            />
            <Field name={`workingCareers[${index}].jobTitle`}
              label={t("SysParamCreator.Custom.KeyWord.ProfessionalTitle")}
              component={EditorItemInput}
              editorItemDistance="Far"
              inputDistance={isMobile ? "" : "Middle"}
              inputSize={isMobile ? "Large" : "Small"}
              validator={jobTitleValidator}
            />
            <Field name={`workingCareers[${index}].departmentName`}
              label={t("SysParamCreator.Custom.KeyWord.Department")}
              component={EditorItemInput}
              editorItemDistance="Far"
              inputDistance={isMobile ? "" : "Middle"}
              inputSize={isMobile ? "Large" : "Small"}
            />
            <Field name={`workingCareers[${index}].isOnTheJob`}
              label={t("CustomerEditModel.Employed")}
              component={EditorItemCheckbox}
              wrapperStyle={isMobile ? {} : { marginLeft: '16px', marginTop: '6px' }}
              labelStyle={{ paddingLeft: '8px' }}
            />
            {props.value.length > 1 && <IconLink icon={faTrashAlt}
              linkText={t("Com.Remove")}
              className="removeItem"
              marginTop={isMobile ? "" : "6px"}
              marginLeft={isMobile ? "" : "16px"}
              isError={true}
              wrapperStyleDisableFlex={true}
              onClick={_ => onRemove(workingCareerViewModel.rowId)}
            />}
          </EditorItemsWrapper>
        )
      })}
      <EditorItemsAddActionWrapper>
        <IconLink icon={faPlus}
          linkText={t("Com.New")}
          onClick={onAdd}
        />
      </EditorItemsAddActionWrapper>
    </EditorItemsContainer>
  );
}

interface ContactMechViewModel extends ContactMech {
  rowId: string;
}
interface postalAddressViewModel extends PostalAddress {
  rowId: string;
  cityTownInfo?: {
    city: string;
    town: string;
  }[]
}
interface MobileEditorProps extends FieldArrayRenderProps {
  value: ContactMechViewModel[];
  primaryId: string;
  onPrimaryChange: (rowId: string) => void;
  types: ContactMechType[];
  customValidator?: (value: string) => string;
  isMobile: boolean;
}
const MobileEditor = (props: MobileEditorProps) => {
  const { t } = useTranslation();
  // Initailize primary row ID state.
  const primaryMobile = props.value.find(
    (mobileViewModel: ContactMechViewModel) => mobileViewModel.isPrimary
  ) || props.value[0];
  const [primaryRowId, setPrimaryRowId] = useState<string>(primaryMobile.rowId);
  const { isMobile, customValidator } = props;

  function onAdd() {
    props.onPush({
      value: {
        rowId: uuidv4(),
        type: props.types?.length > 0 ? props.types[0] : undefined,
        isPrimary: false,
      },
    });
  }
  function onPrimaryChange(event: RadioButtonChangeEvent) {
    setPrimaryRowId(event.value);
    props.onPrimaryChange(event.value);
  }
  function onRemove(rowId: string) {
    // Remove the target when re-rendering.
    const index = props.value.findIndex((mobileViewModel: ContactMechViewModel) => mobileViewModel.rowId === rowId);
    props.onRemove({ index });

    // Removed one is primary.
    if (primaryRowId === rowId) {
      const targetIndex = 0;
      const newPrimaryMobile = props.value[targetIndex];
      newPrimaryMobile.isPrimary = true;
      setPrimaryRowId(newPrimaryMobile.rowId);
      props.onPrimaryChange(newPrimaryMobile.rowId);
    }
  }

  return (
    <EditorItemsContainer>
      {props.value.map((mobileViewModel: ContactMechViewModel, index: number) => {
        return (
          <EditorItemsWrapper className="editorItemsContainer" key={mobileViewModel.rowId} isChildren={true}>
            <EditorItemRadioButton name="mobiles.isPrimary"
              value={mobileViewModel.rowId}
              checked={primaryRowId === mobileViewModel.rowId}
              label={t("PartyContactMech.IsPrimary") as string}
              onChange={onPrimaryChange}
            />
            <Field name={`mobiles[${index}].value`}
              component={EditorItemInput}
              editorItemDistance="Far"
              validator={customValidator}
              inputSize={isMobile ? "Large" : "Small"}
            />
            <Field name={`mobiles[${index}].type`}
              component={EditorItemDropdownList}
              data={props.types}
              dataItemKey="name"
              textField="name"
              editorItemDistance="Middle"
              dropdownListSize={isMobile ? "Large" : "Small"}
            />
            {props.value.length > 1 && <IconLink icon={faTrashAlt}
              linkText={t("Com.Remove")}
              className="removeItem"
              marginTop={isMobile ? "" : "6px"}
              marginLeft={isMobile ? "" : "16px"}
              isError={true}
              wrapperStyleDisableFlex={true}
              onClick={_ => onRemove(mobileViewModel.rowId)}
            />}
          </EditorItemsWrapper>
        );
      })}
      <EditorItemsAddActionWrapper>
        <IconLink icon={faPlus}
          linkText={t("Com.New")}
          onClick={onAdd}
        />
      </EditorItemsAddActionWrapper>
    </EditorItemsContainer>
  );
};

interface PhoneEditorProps extends FieldArrayRenderProps {
  value: ContactMechViewModel[];
  primaryId: string;
  onPrimaryChange: (rowId: string) => void;
  types: ContactMechType[];
  customValidator?: (value: string) => string;
  isMobile: boolean;
}
const PhoneEditor = (props: PhoneEditorProps) => {
  const { t } = useTranslation();
  // Initailize primary row ID state.
  const primaryPhone = props.value.find(
    (phoneViewModel: ContactMechViewModel) => phoneViewModel.isPrimary
  ) || props.value[0];
  const [primaryRowId, setPrimaryRowId] = useState<string>(primaryPhone.rowId);
  const { isMobile, customValidator, types } = props;

  function onAdd() {
    props.onPush({
      value: {
        rowId: uuidv4(),
        type: props.types?.length > 0 ? props.types[0] : undefined,
        isPrimary: false,
      },
    });
  }
  function onPrimaryChange(event: RadioButtonChangeEvent) {
    setPrimaryRowId(event.value);
    props.onPrimaryChange(event.value);
  }
  function onRemove(rowId: string) {
    // Remove the target when re-rendering.
    const index = props.value.findIndex((phoneViewModel: ContactMechViewModel) => phoneViewModel.rowId === rowId);
    props.onRemove({ index });

    // Removed one is primary.
    if (primaryRowId === rowId) {
      const targetIndex = 0;
      const newPrimaryPhone = props.value[targetIndex];
      newPrimaryPhone.isPrimary = true;
      setPrimaryRowId(newPrimaryPhone.rowId);
      props.onPrimaryChange(newPrimaryPhone.rowId);
    }
  }

  return (
    <EditorItemsContainer>
      {props.value.map((phoneViewModel: ContactMechViewModel, index: number) => {
        return (
          <EditorItemsWrapper className="editorItemsContainer" key={phoneViewModel.rowId} isChildren={true}>
            <EditorItemRadioButton name="phones.isPrimary"
              value={phoneViewModel.rowId}
              checked={primaryRowId === phoneViewModel.rowId}
              label={t("PartyContactMech.IsPrimary") as string}
              onChange={onPrimaryChange}
            />
            <Field name={`phones[${index}].value`}
              component={EditorItemInput}
              editorItemDistance="Far"
              validator={customValidator}
              inputSize={isMobile ? "Large" : "Small"}
            />
            <Field name={`phones[${index}].type`}
              component={EditorItemDropdownList}
              data={types}
              dataItemKey="name"
              textField="name"
              editorItemDistance="Middle"
              dropdownListSize={isMobile ? "Large" : "Small"}
            />
            {props.value.length > 1 && <IconLink icon={faTrashAlt}
              linkText={t("Com.Remove")}
              className="removeItem"
              marginTop={isMobile ? "" : "6px"}
              marginLeft={isMobile ? "" : "16px"}
              isError={true}
              wrapperStyleDisableFlex={true}
              onClick={_ => onRemove(phoneViewModel.rowId)}
            />}
          </EditorItemsWrapper>
        );
      })}
      <EditorItemsAddActionWrapper>
        <IconLink icon={faPlus}
          linkText={t("Com.New")}
          onClick={onAdd}
        />
      </EditorItemsAddActionWrapper>
    </EditorItemsContainer>
  );
}

interface FaxEditorProps extends FieldArrayRenderProps {
  value: ContactMechViewModel[];
  primaryId: string;
  onPrimaryChange: (rowId: string) => void;
  types: ContactMechType[];
  customValidator?: (value: string) => string;
  isMobile: boolean;
}
const FaxEditor = (props: FaxEditorProps) => {
  const { t } = useTranslation();
  // Initailize primary row ID state.
  const primaryFax = props.value.find(
    (faxViewModel: ContactMechViewModel) => faxViewModel.isPrimary
  ) || props.value[0];
  const [primaryRowId, setPrimaryRowId] = useState<string>(primaryFax.rowId);
  const { isMobile, customValidator, types } = props;

  function onAdd() {
    props.onPush({
      value: {
        rowId: uuidv4(),
        type: props.types?.length > 0 ? props.types[0] : undefined,
        isPrimary: false,
      },
    });
  }
  function onPrimaryChange(event: RadioButtonChangeEvent) {
    setPrimaryRowId(event.value);
    props.onPrimaryChange(event.value);
  }
  function onRemove(rowId: string) {
    // Remove the target when re-rendering.
    const index = props.value.findIndex((faxViewModel: ContactMechViewModel) => faxViewModel.rowId === rowId);
    props.onRemove({ index });

    // Removed one is primary.
    if (primaryRowId === rowId) {
      const targetIndex = 0;
      const newPrimaryFax = props.value[targetIndex];
      newPrimaryFax.isPrimary = true;
      setPrimaryRowId(newPrimaryFax.rowId);
      props.onPrimaryChange(newPrimaryFax.rowId);
    }
  }

  return (
    <EditorItemsContainer>
      {props.value.map((faxViewModel: ContactMechViewModel, index: number) => {
        return (
          <EditorItemsWrapper className="editorItemsContainer" key={faxViewModel.rowId} isChildren={true}>
            <EditorItemRadioButton name="faxes.isPrimary"
              value={faxViewModel.rowId}
              checked={primaryRowId === faxViewModel.rowId}
              label={t("PartyContactMech.IsPrimary") as string}
              onChange={onPrimaryChange}
            />
            <Field name={`faxes[${index}].value`}
              component={EditorItemInput}
              editorItemDistance="Far"
              validator={customValidator}
              inputSize={isMobile ? "Large" : "Small"}
            />
            <Field name={`faxes[${index}].type`}
              component={EditorItemDropdownList}
              data={types}
              dataItemKey="name"
              textField="name"
              editorItemDistance="Middle"
              dropdownListSize={isMobile ? "Large" : "Small"}
            />
            {props.value.length > 1 && <IconLink icon={faTrashAlt}
              linkText={t("Com.Remove")}
              className="removeItem"
              marginTop={isMobile ? "" : "6px"}
              marginLeft={isMobile ? "" : "16px"}
              isError={true}
              wrapperStyleDisableFlex={true}
              onClick={_ => onRemove(faxViewModel.rowId)}
            />}
          </EditorItemsWrapper>
        );
      })}
      <EditorItemsAddActionWrapper>
        <IconLink icon={faPlus}
          linkText={t("Com.New")}
          onClick={onAdd}
        />
      </EditorItemsAddActionWrapper>
    </EditorItemsContainer>
  );
}

interface EmailEditorProps extends FieldArrayRenderProps {
  value: ContactMechViewModel[];
  primaryId: string;
  onPrimaryChange: (rowId: string) => void;
  types: ContactMechType[];
  customValidator?: (value: string) => string;
  isMobile: boolean;
}
const EmailEditor = (props: EmailEditorProps) => {
  const { t } = useTranslation();
  // Initailize primary row ID state.
  const primaryEmail = props.value.find(
    (emailViewModel: ContactMechViewModel) => emailViewModel.isPrimary
  ) || props.value[0];
  const [primaryRowId, setPrimaryRowId] = useState<string>(primaryEmail.rowId);
  const { isMobile, customValidator, types } = props;

  function onAdd() {
    props.onPush({
      value: {
        rowId: uuidv4(),
        type: props.types?.length > 0 ? props.types[0] : undefined,
        isPrimary: false,
      },
    });
  }
  function onPrimaryChange(event: RadioButtonChangeEvent) {
    setPrimaryRowId(event.value);
    props.onPrimaryChange(event.value);
  }
  function onRemove(rowId: string) {
    // Remove the target when re-rendering.
    const index = props.value.findIndex((emailViewModel: ContactMechViewModel) => emailViewModel.rowId === rowId);
    props.onRemove({ index });

    // Removed one is primary.
    if (primaryRowId === rowId) {
      const targetIndex = 0;
      const newPrimaryEmail = props.value[targetIndex];
      newPrimaryEmail.isPrimary = true;
      setPrimaryRowId(newPrimaryEmail.rowId);
      props.onPrimaryChange(newPrimaryEmail.rowId);
    }
  }

  return (
    <EditorItemsContainer>
      {props.value.map((emailViewModel: ContactMechViewModel, index: number) => {
        return (
          <EditorItemsWrapper className="editorItemsContainer" key={emailViewModel.rowId} isChildren={true}>
            <EditorItemRadioButton name="emails.isPrimary"
              value={emailViewModel.rowId}
              checked={primaryRowId === emailViewModel.rowId}
              label={t("PartyContactMech.IsPrimary") as string}
              onChange={onPrimaryChange}
            />
            <Field name={`emails[${index}].value`}
              component={EditorItemInput}
              editorItemDistance="Far"
              inputSize="Large"
              validator={customValidator}
            />
            <Field name={`emails[${index}].type`}
              component={EditorItemDropdownList}
              data={types}
              dataItemKey="name"
              textField="name"
              editorItemDistance="Middle"
              dropdownListSize={isMobile ? "Large" : "Small"}
            />
            {props.value.length > 1 && <IconLink icon={faTrashAlt}
              linkText={t("Com.Remove")}
              className="removeItem"
              marginTop={isMobile ? "" : "6px"}
              marginLeft={isMobile ? "" : "16px"}
              isError={true}
              wrapperStyleDisableFlex={true}
              onClick={_ => onRemove(emailViewModel.rowId)}
            />}
          </EditorItemsWrapper>
        );
      })}
      <EditorItemsAddActionWrapper>
        <IconLink icon={faPlus}
          linkText={t("Com.New")}
          onClick={onAdd}
        />
      </EditorItemsAddActionWrapper>
    </EditorItemsContainer>
  );
}

interface PostalAddressProps extends FieldArrayRenderProps {
  value: postalAddressViewModel[];
  primaryId: string;
  onPrimaryChange: (rowId: string) => void;
  types: ContactMechType[];
  customValidator?: (value: string) => string;
  isMobile: boolean;
}
const PostalAddressEditor = (props: PostalAddressProps) => {
  const { t } = useTranslation();
  // Initailize primary row ID state.
  const primaryPostalAddress = props.value.find(
    (postalAddressViewModel: postalAddressViewModel) => postalAddressViewModel.isPrimary
  ) || props.value[0];

  const [primaryRowId, setPrimaryRowId] = useState<string>(primaryPostalAddress.rowId);
  const { isMobile, cityTownInfoValidator, addressValidator } = props;

  function onAdd() {
    props.onPush({
      value: {
        rowId: uuidv4(),
        cityId: '',
        townshipId: '',
        type: props.types?.length > 0 ? props.types[0] : undefined,
        isPrimary: false,
      },
    });
  }
  const onPrimaryChange: RadioButtonProps['onChange'] = (event: RadioButtonChangeEvent) => {
    setPrimaryRowId(event.value);
    props.onPrimaryChange(event.value);
  }
  function onRemove(rowId: string) {
    // Remove the target when re-rendering.
    const index = props.value.findIndex((postalAddressViewModel: postalAddressViewModel) => postalAddressViewModel.rowId === rowId);
    props.onRemove({ index });

    // Removed one is primary.
    if (primaryRowId === rowId) {
      const targetIndex = 0;
      const newPrimaryPostalAddress = props.value[targetIndex];
      newPrimaryPostalAddress.isPrimary = true;
      setPrimaryRowId(newPrimaryPostalAddress.rowId);
      props.onPrimaryChange(newPrimaryPostalAddress.rowId);
    }
  }

  return (
    <EditorItemsContainer>
      {props.value.map((postalAddressViewModel: postalAddressViewModel, index: number) => {
        return (
          <EditorItemsWrapper className="editorItemsContainer" key={postalAddressViewModel.rowId} isChildren={true}>
            <EditorItemRadioButton name="postalAddresses.isPrimary"
              value={postalAddressViewModel.rowId}
              checked={primaryRowId === postalAddressViewModel.rowId}
              label={t("PartyContactMech.IsPrimary") as string}
              onChange={onPrimaryChange}
            />
            <FieldArray name={`postalAddresses[${index}].cityTownInfo`}
              component={EditorItemPostalAddressDropdownlist}
              editorItemDistance="Far"
              validator={cityTownInfoValidator}
              value={`${postalAddressViewModel.cityId},${postalAddressViewModel.townshipId}`}
            />
            <Field name={`postalAddresses[${index}].value`}
              component={EditorItemInput}
              editorItemDistance="Middle"
              inputSize="Large"
              validator={addressValidator}
            />
            <Field name={`postalAddresses[${index}].type`}
              component={EditorItemDropdownList}
              data={props.types}
              dataItemKey="name"
              textField="name"
              editorItemDistance="Middle"
              dropdownListSize={isMobile ? "Large" : "Small"}
            />
            {props.value.length > 1 && <IconLink icon={faTrashAlt}
              linkText={t("Com.Remove")}
              className="removeItem"
              marginTop={isMobile ? "" : "6px"}
              marginLeft={isMobile ? "" : "16px"}
              isError={true}
              wrapperStyleDisableFlex={true}
              onClick={_ => onRemove(postalAddressViewModel.rowId)}
            />}
          </EditorItemsWrapper>
        );
      })}
      <EditorItemsAddActionWrapper>
        <IconLink icon={faPlus}
          linkText={t("Com.New")}
          onClick={onAdd}
        />
      </EditorItemsAddActionWrapper>
    </EditorItemsContainer>
  );
}

function Editor(props: EditorProps) {
  const { t } = useTranslation();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const { customer, submitBtnRef } = useContext(CustomerContext) || {};
  const themeContext = useContext(ThemeContext);
  const isMobile = useMedia(`(${themeContext?.devices.mobile.mediaQuery})`);
  const [nationalities] = useState<string[]>(props.nationalities);
  const [primaryWorkingCareerRowId, setPrimaryWorkingCareerRowId] = useState<string>('');
  const [primaryMobileRowId, setPrimaryMobileRowId] = useState<string>('');
  const [primaryPhoneRowId, setPrimaryPhoneRowId] = useState<string>('');
  const [primaryFaxRowId, setPrimaryFaxRowId] = useState<string>('');
  const [primaryEmailRowId, setPrimaryEmailRowId] = useState<string>('');
  const [primaryPostalAddressRowId, setprimaryPostalAddressRowId] = useState<string>('');
  const radioButtonGroupData = [
    {
      value: "Unknown",
      label: t("EnumGender.Unknow"),
    },
    {
      value: "Male",
      label: t("EnumGender.Male"),
    },
    {
      value: "Female",
      label: t("EnumGender.Female"),
    },
  ];
  const nameValidator = (value: string) => !value ? `${t("WebFormRequireType.Name")}${t("Customer.Valid.IsRequiredField")}` : '';
  const firstNameValidator = (value: string) => checkIfIsRequired('CustomerEditModel.FirstName') && !value ? `${t("Com.FirstName")}${t("Customer.Valid.IsRequiredField")}` : '';
  const lastNameValidator = (value: string) => checkIfIsRequired('CustomerEditModel.LastName') && !value ? `${t("Com.LastName")}${t("Customer.Valid.IsRequiredField")}` : '';
  const nickNameValidator = (value: string) => checkIfIsRequired('CustomerEditModel.NickName') && !value ? `${t("CustomerEditModel.Nickname")}${t("Customer.Valid.IsRequiredField")}` : '';
  const globalNameValidator = (value: string) => checkIfIsRequired('CustomerEditModel.GlobalName') && !value ? `${t("CustomerEditModel.GlobalName")}${t("Customer.Valid.IsRequiredField")}` : '';
  const companyValidator = (value: Company) => checkIfIsRequired('CustomerEditModel.WorkingCompanyName') && !value?.id ? `${t("User.Custom.KeyWord.Company")}${t("Customer.Valid.IsRequiredField")}` : '';
  const jobTitleValidator = (value: string) => checkIfIsRequired('CustomerEditModel.ProfessionalTitle') && !value ? `${t("User.Custom.KeyWord.ProfessionalTitle")}${t("Customer.Valid.IsRequiredField")}` : '';
  const mobileValidator = (value: string) => checkIfIsRequired('ContactMech.TelecomNum.MobilePhone') && !value ? `${t("ContactMechType.MobilePhoneType")}${t("Customer.Valid.IsRequiredField")}` : '';
  const phoneValidator = (value: string) => checkIfIsRequired('ContactMech.TelecomNum.FixedPhone') && !value ? `${t("ContactMechType.FixedPhoneType")}${t("Customer.Valid.IsRequiredField")}` : '';
  const faxValidator = (value: string) => checkIfIsRequired('ContactMechType.FaxType') && !value ? `${t("ContactMechType.FaxType")}${t("Customer.Valid.IsRequiredField")}` : '';
  const emailValidator = (value: string) => checkIfIsRequired('ContactMech.ElectronicAddress') && !value
    ? `${t("ContactMechType.EmailType")}${t("Customer.Valid.IsRequiredField")}`
    : !value || emailRegex.test(value)
      ? ''
      : `${t("ContactMechType.EmailType")}${t("Api.FormatMismatch")}。`;
  const nationalityValidator = (value: string) => checkIfIsRequired('ContactMechType.PostalAddressType') && !value ? `${t("CustomerEditModel.Nationality")}${t("Customer.Valid.IsRequiredField")}` : '';
  // 這邊用了 FieldArray 的驗證，所以會是 value: any ( 不太好 )addressValidator
  const cityTownInfoValidator = (value: any) => {
    if (checkIfIsRequired('postalAddresses')) {
      if (!value || !value[0].town) return `${t("Com.TownCity")}${t("Customer.Valid.IsRequiredField")}`;
    }
  }
  const addressValidator = (value: string) => checkIfIsRequired('CustomerEditModel.Nationality') && !value ? `${t("ContactMechType.PostalAddressType")}${t("Customer.Valid.IsRequiredField")}` : '';

  useEffectOnce(() => {
    // Initialize view models.
    const workingCareers = customer!.workingCareers?.length > 0
      ? customer!.workingCareers
      : [{
        isPrimary: true,
        isOnTheJob: true,
      }];
    const workingCareerViewModels = workingCareers.map<WorkingCareerViewModel>(
      (workingCareer: WorkingCareer) => (
        {
          ...workingCareer,
          company: workingCareer.company?.id
            ? { ...workingCareer.company }
            : undefined,
          rowId: uuidv4(),
        }
      )
    );
    const mobiles = customer!.mobiles?.length > 0
      ? customer!.mobiles
      : [{ isPrimary: true }];
    const mobileViewModels = mobiles.map<ContactMechViewModel>(
      (mobile: ContactMech) => (
        {
          ...mobile,
          type: mobile.type?.name
            ? { ...mobile.type }
            : props.mobileTypes.length > 0 ? { ...props.mobileTypes[0] } : undefined,
          rowId: uuidv4(),
        }
      )
    );
    const phones = customer!.phones?.length > 0
      ? customer!.phones
      : [{ isPrimary: true }];
    const phoneViewModels = phones.map<ContactMechViewModel>(
      (phone: ContactMech) => (
        {
          ...phone,
          type: phone.type?.name
            ? { ...phone.type }
            : props.phoneTypes.length > 0 ? { ...props.phoneTypes[0] } : undefined,
          rowId: uuidv4(),
        }
      )
    );
    const faxes = customer!.faxes?.length > 0
      ? customer!.faxes
      : [{ isPrimary: true }];
    const faxViewModels = faxes.map<ContactMechViewModel>(
      (fax: ContactMech) => (
        {
          ...fax,
          type: fax.type?.name
            ? { ...fax.type }
            : props.faxTypes.length > 0 ? { ...props.faxTypes[0] } : undefined,
          rowId: uuidv4(),
        }
      )
    );
    const emails = customer!.emails?.length > 0
      ? customer!.emails
      : [{ isPrimary: true }];
    const emailViewModels = emails.map<ContactMechViewModel>(
      (email: ContactMech) => (
        {
          ...email,
          type: email.type?.name
            ? { ...email.type }
            : props.emailTypes.length > 0 ? { ...props.emailTypes[0] } : undefined,
          rowId: uuidv4(),
        }
      )
    );
    const postalAddresses = customer!.postalAddresses?.length > 0
      ? customer!.postalAddresses
      : [{
        cityId: '',
        townshipId: '',
        isPrimary: true,
      }];
    const postalAddressViewModels = postalAddresses.map<postalAddressViewModel>(
      (postalAddress: PostalAddress) => (
        {
          ...postalAddress,
          type: postalAddress.type?.name
            ? { ...postalAddress.type }
            : props.addressTypes.length > 0 ? { ...props.addressTypes[0] } : undefined,
          rowId: uuidv4(),
          cityTownInfo: []
        }
      )
    );
    customer!.workingCareers = workingCareerViewModels;
    customer!.mobiles = mobileViewModels;
    customer!.phones = phoneViewModels;
    customer!.faxes = faxViewModels;
    customer!.emails = emailViewModels;
    customer!.postalAddresses = postalAddressViewModels;

    // Initailize primary row ID state.
    const primaryWorkingCareer = workingCareerViewModels.find(
      (workingCareerViewModel: WorkingCareerViewModel) => workingCareerViewModel.isPrimary
    ) || workingCareerViewModels[0];
    setPrimaryWorkingCareerRowId(primaryWorkingCareer.rowId);
    const primaryMobile = mobileViewModels.find(
      (mobileViewModel: ContactMechViewModel) => mobileViewModel.isPrimary
    ) || mobileViewModels[0];
    setPrimaryMobileRowId(primaryMobile.rowId);
    const primaryPhone = phoneViewModels.find(
      (phoneViewModel: ContactMechViewModel) => phoneViewModel.isPrimary
    ) || phoneViewModels[0];
    setPrimaryPhoneRowId(primaryPhone.rowId);
    const primaryFax = faxViewModels.find(
      (faxViewModel: ContactMechViewModel) => faxViewModel.isPrimary
    ) || faxViewModels[0];
    setPrimaryFaxRowId(primaryFax.rowId);
    const primaryEmail = emailViewModels.find(
      (emailViewModel: ContactMechViewModel) => emailViewModel.isPrimary
    ) || emailViewModels[0];
    setPrimaryEmailRowId(primaryEmail.rowId);
    const primaryPostalAddress = postalAddressViewModels.find(
      (postalAddressViewModel: postalAddressViewModel) => postalAddressViewModel.isPrimary
    ) || postalAddressViewModels[0];
    setprimaryPostalAddressRowId(primaryPostalAddress.rowId);
    setIsInitialized(true);
  });


  function checkIfIsRequired(columnName: string) {
    return props.requiredColumnNames.includes(columnName);
  }

  function onPrimaryWorkingCareerChange(rowId: string) {
    setPrimaryWorkingCareerRowId(rowId);
  }

  function onPrimaryMobileChange(rowId: string) {
    setPrimaryMobileRowId(rowId);
  }

  function onPrimaryPhoneChange(rowId: string) {
    setPrimaryPhoneRowId(rowId);
  }

  function onPrimaryFaxChange(rowId: string) {
    setPrimaryFaxRowId(rowId);
  }

  function onPrimaryEmailChange(rowId: string) {
    setPrimaryEmailRowId(rowId);
  }

  function onPrimaryPostalAddressChange(rowId: string) {
    setprimaryPostalAddressRowId(rowId);
  }

  function onSubmit(dataItem: { [name: string]: any }) {
    const customer: Customer = { ...dataItem } as Customer;

    // Update customer data by custom state that controls which working career is primary.
    customer.workingCareers = customer.workingCareers.map((workingCareer: WorkingCareer) => {
      const viewModel = workingCareer as WorkingCareerViewModel;
      return {
        ...workingCareer,
        isPrimary: viewModel.rowId === primaryWorkingCareerRowId,
      };
    });

    // Update customer data by custom state that controls which mobile is primary.
    customer.mobiles = customer.mobiles.map((mobile: ContactMech) => {
      const viewModel = mobile as ContactMechViewModel;
      return {
        ...mobile,
        isPrimary: viewModel.rowId === primaryMobileRowId,
      };
    });

    // Update customer data by custom state that controls which phone is primary.
    customer.phones = customer.phones.map((phone: ContactMech) => {
      const viewModel = phone as ContactMechViewModel;
      return {
        ...phone,
        isPrimary: viewModel.rowId === primaryPhoneRowId,
      };
    });

    // Update customer data by custom state that controls which fax is primary.
    customer.faxes = customer.faxes.map((fax: ContactMech) => {
      const viewModel = fax as ContactMechViewModel;
      return {
        ...fax,
        isPrimary: viewModel.rowId === primaryFaxRowId,
      };
    });

    // Update customer data by custom state that controls which email is primary.
    customer.emails = customer.emails.map((email: ContactMech) => {
      const viewModel = email as ContactMechViewModel;
      return {
        ...email,
        isPrimary: viewModel.rowId === primaryEmailRowId,
      };
    });

    // Update customer data by custom state that controls which postalAddress is primary.
    customer.postalAddresses = customer.postalAddresses.map((postalAddress: PostalAddress) => {
      const viewModel = postalAddress as postalAddressViewModel;
      const { city, town } = viewModel.cityTownInfo![0] || {};
      return {
        ...postalAddress,
        isPrimary: viewModel.rowId === primaryPostalAddressRowId,
        cityId: city || '',
        townshipId: town || '',
      };
    });

    props.onSubmit?.(customer);
  }

  return (
    isInitialized ? <Form initialValues={customer}
      onSubmit={onSubmit}
      render={(formRenderProps: FormRenderProps) => (
        <StyledForm>
          <EditorItemsWrapper>
            <EditorItemTitle isRequired={true}>{t("NoteImport.CustomerName", { "User.Custom.KeyWord.Customer": t("User.Custom.KeyWord.Customer") })}</EditorItemTitle>
            <Field name="name"
              component={EditorItemInput}
              validator={nameValidator}
              inputSize={isMobile ? "Large" : "Middle"}
            />
            <div className="nameDetailContent">
              <div className="nameField">
                <EditorItemDelimiter distance="Far">（</EditorItemDelimiter>
                <Field name="lastName"
                  label={t("Com.FirstName")}
                  component={EditorItemInput}
                  validator={lastNameValidator}
                  inputDistance="Middle"
                  inputDisableMobileWidthStyle={true}
                  inputSize="Small"
                />
              </div>
              <div className="nameField">
                <Field name="firstName"
                  label={t("Com.LastName")}
                  component={EditorItemInput}
                  validator={firstNameValidator}
                  editorItemDistance="Far"
                  inputDistance="Middle"
                  inputDisableMobileWidthStyle={true}
                  inputSize="Small"
                />
                <EditorItemDelimiter>）</EditorItemDelimiter>
              </div>
            </div>
          </EditorItemsWrapper>
          <EditorItemsWrapper>
            <EditorItemTitle isRequired={checkIfIsRequired('CustomerEditModel.NickName')}>{t("CustomerEditModel.Nickname")}</EditorItemTitle>
            <Field name="nickname"
              component={EditorItemInput}
              validator={nickNameValidator}
              inputSize="Large"
            />
          </EditorItemsWrapper>
          <EditorItemsWrapper>
            <EditorItemTitle isRequired={checkIfIsRequired('CustomerEditModel.GlobalName')}>{t("CustomerEditModel.GlobalName")}</EditorItemTitle>
            <Field name="globalName"
              component={EditorItemInput}
              validator={globalNameValidator}
              inputSize="Large"
            />
          </EditorItemsWrapper>
          <HorizontalLine />
          <EditorItemsWrapper isFirst={true} isLast={true}>
            <EditorItemTitle className="boldEditorItemTitle" isRequired={checkIfIsRequired('CustomerEditModel.WorkingCompanyName') || checkIfIsRequired('CustomerEditModel.ProfessionalTitle')}>
              {t("CompanyModel", { "User.Custom.KeyWord.Company": t("User.Custom.KeyWord.Company") })}
            </EditorItemTitle>
            <FieldArray name="workingCareers"
              component={WorkingCareerEditor}
              onPrimaryChange={onPrimaryWorkingCareerChange}
              companyValidator={companyValidator}
              jobTitleValidator={jobTitleValidator}
              isMobile={isMobile}
            />
          </EditorItemsWrapper>
          <HorizontalLine />
          <EditorItemsWrapper isFirst={true} isLast={true}>
            <EditorItemTitle className="boldEditorItemTitle" isRequired={checkIfIsRequired('ContactMech.TelecomNum.MobilePhone')}>{t("ContactMechType.MobilePhoneType")}</EditorItemTitle>
            <FieldArray name="mobiles"
              component={MobileEditor}
              primaryId={primaryMobileRowId}
              onPrimaryChange={onPrimaryMobileChange}
              types={props.mobileTypes}
              customValidator={mobileValidator}
              isMobile={isMobile}
            />
          </EditorItemsWrapper>
          <HorizontalLine />
          <EditorItemsWrapper isFirst={true} isLast={true}>
            <EditorItemTitle className="boldEditorItemTitle" isRequired={checkIfIsRequired('ContactMech.TelecomNum.FixedPhone')}>{t("ContactMechType.FixedPhoneType")}</EditorItemTitle>
            <FieldArray name="phones"
              component={PhoneEditor}
              primaryId={primaryPhoneRowId}
              onPrimaryChange={onPrimaryPhoneChange}
              types={props.phoneTypes}
              customValidator={phoneValidator}
              isMobile={isMobile}
            />
          </EditorItemsWrapper>
          <HorizontalLine />
          <EditorItemsWrapper isFirst={true} isLast={true}>
            <EditorItemTitle className="boldEditorItemTitle" isRequired={checkIfIsRequired('ContactMechType.FaxType')}>{t("ContactMechType.FaxType")}</EditorItemTitle>
            <FieldArray name="faxes"
              component={FaxEditor}
              primaryId={primaryFaxRowId}
              onPrimaryChange={onPrimaryFaxChange}
              types={props.faxTypes}
              customValidator={faxValidator}
              isMobile={isMobile}
            />
          </EditorItemsWrapper>
          <HorizontalLine />
          <EditorItemsWrapper isFirst={true} isLast={true}>
            <EditorItemTitle className="boldEditorItemTitle" isRequired={checkIfIsRequired('CustomerEditModel.Nationality')}>{t("ContactMechType.PostalAddressType")}</EditorItemTitle>
            <FieldArray name="postalAddresses"
              component={PostalAddressEditor}
              primaryId={primaryPostalAddressRowId}
              types={props.addressTypes}
              onPrimaryChange={onPrimaryPostalAddressChange}
              cityTownInfoValidator={cityTownInfoValidator}
              addressValidator={addressValidator}
              isMobile={isMobile}
            />
          </EditorItemsWrapper>
          <HorizontalLine />
          <EditorItemsWrapper isFirst={true} isLast={true}>
            <EditorItemTitle className="boldEditorItemTitle" isRequired={checkIfIsRequired('ContactMech.ElectronicAddress')}>{t("ContactMechType.EmailType")}</EditorItemTitle>
            <FieldArray name="emails"
              component={EmailEditor}
              primaryId={primaryEmailRowId}
              onPrimaryChange={onPrimaryEmailChange}
              types={props.emailTypes}
              customValidator={emailValidator}
              isMobile={isMobile}
            />
          </EditorItemsWrapper>
          <HorizontalLine />
          <EditorItemsWrapper isFirst={true}>
            <EditorItemTitle isRequired={checkIfIsRequired('ContactMechType.PostalAddressType')}>{t("CustomerEditModel.Nationality")}</EditorItemTitle>
            <Field name="nationality"
              component={EditorItemDropdownList}
              isPrimitive={true}
              data={nationalities}
              enablePleaseSelectAsDefaultItem={true}
              isRequired={checkIfIsRequired('ContactMechType.PostalAddressType')}
              validator={nationalityValidator}
              dropdownListSize={isMobile ? "Large" : "Huge"}
            />
          </EditorItemsWrapper>
          <EditorItemsWrapper>
            <EditorItemTitle isRequired={checkIfIsRequired('CustomerEditModel.Gender')}>{t("CustomerEditModel.Gender")}</EditorItemTitle>
            <Field
              name="gender"
              component={EditorItemRadioButtonGroup}
              data={radioButtonGroupData}
              value={customer?.gender || 'Unknown'}
              layout="horizontal"
            />
          </EditorItemsWrapper>
          <button type="button"
            ref={submitBtnRef}
            disabled={!formRenderProps.allowSubmit}
            onClick={formRenderProps.onSubmit}
            style={{ marginTop: 16, display: "none" }}>{t("Com.Submit")}</button>
        </StyledForm>
      )} />
      : null
  );
}

export default Editor;
