import { RadioGroup } from "@progress/kendo-react-inputs";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import styled from "styled-components";
import { RadioGroupStyle } from "./shared/RadioButtonGroup";

const StyledRadioGroup = styled(RadioGroup)`
  ${RadioGroupStyle}
`

export const EditorItemRadioButtonGroup = (props: FieldRenderProps) => {
  const {
    name,
    data,
    layout,
    value,
    ...others
  } = props;

  return (
    <FieldWrapper>
      <StyledRadioGroup
        name={name}
        data={data}
        value={value}
        layout={layout}
        {...others}
      />
    </FieldWrapper>
  );
};
