import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

interface TabLinkProps {
  label: string;
  paths: string[];
}

const StyledLink = styled(Link) <{ $isActive: boolean }>`
  font-size: 16px;
  color: ${props => props.$isActive ? props.theme.colorPrimary : props.theme.colorGray600};
  padding-bottom: 8px;
  font-weight: 500;
  ${props => props.$isActive ? `border-bottom: 3px solid ${props.theme.colorPrimary}` : ""};
`


const TabLink = (props: TabLinkProps) => {
  const { label, paths } = props;
  const { pathname } = useLocation();
  const isActive = paths.includes(pathname);
  const linkTo = paths.at(0) || '';


  return <>
    <StyledLink to={linkTo} $isActive={isActive}>{label}</StyledLink>
  </>
}

export default TabLink;