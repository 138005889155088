export const citiesData = [
  {
    "id": "TaipeiCity",
    "name": "台北市",
    "townships": [
      {
        "id": "100ZhongzhengDist",
        "name": "中正區"
      },
      {
        "id": "103DatongDist",
        "name": "大同區"
      },
      {
        "id": "104ZhongshanDist",
        "name": "中山區"
      },
      {
        "id": "105SongshanDist",
        "name": "松山區"
      },
      {
        "id": "106DaanDist",
        "name": "大安區"
      },
      {
        "id": "108WanhuaDist",
        "name": "萬華區"
      },
      {
        "id": "110XinyiDist",
        "name": "信義區"
      },
      {
        "id": "111ShilinDist",
        "name": "士林區"
      },
      {
        "id": "112BeitouDist",
        "name": "北投區"
      },
      {
        "id": "114NeihuDist",
        "name": "內湖區"
      },
      {
        "id": "115NangangDist",
        "name": "南港區"
      },
      {
        "id": "116WenshanDist",
        "name": "文山區"
      }
    ]
  },
  {
    "id": "KeelungCity",
    "name": "基隆市",
    "townships": [
      {
        "id": "200RenaiDist",
        "name": "仁愛區"
      },
      {
        "id": "201XinyiDist",
        "name": "信義區"
      },
      {
        "id": "202ZhongzhengDist",
        "name": "中正區"
      },
      {
        "id": "203ZhongshanDist",
        "name": "中山區"
      },
      {
        "id": "204AnleDist",
        "name": "安樂區"
      },
      {
        "id": "205NuannuanDist",
        "name": "暖暖區"
      },
      {
        "id": "206QiduDist",
        "name": "七堵區"
      }
    ]
  },
  {
    "id": "NewTaipeiCity",
    "name": "新北市",
    "townships": [
      {
        "id": "207WanliDist",
        "name": "萬里區"
      },
      {
        "id": "208JinshanDist",
        "name": "金山區"
      },
      {
        "id": "220BanqiaoDist",
        "name": "板橋區"
      },
      {
        "id": "221XizhiDist",
        "name": "汐止區"
      },
      {
        "id": "222ShenkengDist",
        "name": "深坑區"
      },
      {
        "id": "223ShidingDist",
        "name": "石碇區"
      },
      {
        "id": "224RuifangDist",
        "name": "瑞芳區"
      },
      {
        "id": "226PingxiDist",
        "name": "平溪區"
      },
      {
        "id": "227ShuangxiDist",
        "name": "雙溪區"
      },
      {
        "id": "228GongliaoDist",
        "name": "貢寮區"
      },
      {
        "id": "231XindianDist",
        "name": "新店區"
      },
      {
        "id": "232PinglinDist",
        "name": "坪林區"
      },
      {
        "id": "233WulaiDist",
        "name": "烏來區"
      },
      {
        "id": "234YongheDist",
        "name": "永和區"
      },
      {
        "id": "235ZhongheDist",
        "name": "中和區"
      },
      {
        "id": "236TuchengDist",
        "name": "土城區"
      },
      {
        "id": "237SanxiaDist",
        "name": "三峽區"
      },
      {
        "id": "238ShulinDist",
        "name": "樹林區"
      },
      {
        "id": "239YinggeDist",
        "name": "鶯歌區"
      },
      {
        "id": "241SanchongDist",
        "name": "三重區"
      },
      {
        "id": "242XinzhuangDist",
        "name": "新莊區"
      },
      {
        "id": "243TaishanDist",
        "name": "泰山區"
      },
      {
        "id": "244LinkouDist",
        "name": "林口區"
      },
      {
        "id": "247LuzhouDist",
        "name": "蘆洲區"
      },
      {
        "id": "248WuguDist",
        "name": "五股區"
      },
      {
        "id": "249BaliDist",
        "name": "八里區"
      },
      {
        "id": "251DanshuiDist",
        "name": "淡水區"
      },
      {
        "id": "252SanzhiDist",
        "name": "三芝區"
      },
      {
        "id": "253ShimenDist",
        "name": "石門區"
      }
    ]
  },
  {
    "id": "LienchiangCounty",
    "name": "連江縣",
    "townships": [
      {
        "id": "209NanganTownship",
        "name": "南竿鄉"
      },
      {
        "id": "210BeiganTownship",
        "name": "北竿鄉"
      },
      {
        "id": "211JuguangTownship",
        "name": "莒光鄉"
      },
      {
        "id": "212DongyinTownship",
        "name": "東引鄉"
      }
    ]
  },
  {
    "id": "YilanCounty",
    "name": "宜蘭縣",
    "townships": [
      {
        "id": "260YilanCity",
        "name": "宜蘭市"
      },
      {
        "id": "261TouchengTownship",
        "name": "頭城鎮"
      },
      {
        "id": "262JiaoxiTownship",
        "name": "礁溪鄉"
      },
      {
        "id": "263ZhuangweiTownship",
        "name": "壯圍鄉"
      },
      {
        "id": "264YuanshanTownship",
        "name": "員山鄉"
      },
      {
        "id": "265LuodongTownship",
        "name": "羅東鎮"
      },
      {
        "id": "266SanxingTownship",
        "name": "三星鄉"
      },
      {
        "id": "267DatongTownship",
        "name": "大同鄉"
      },
      {
        "id": "268WujieTownship",
        "name": "五結鄉"
      },
      {
        "id": "269DongshanTownship",
        "name": "冬山鄉"
      },
      {
        "id": "270SuaoTownship",
        "name": "蘇澳鎮"
      },
      {
        "id": "272NanaoTownship",
        "name": "南澳鄉"
      }
    ]
  },
  {
    "id": "HsinchuCity",
    "name": "新竹市",
    "townships": [
      {
        "id": "300EastDist",
        "name": "東區"
      },
      {
        "id": "300NorthDist",
        "name": "北區"
      },
      {
        "id": "300XiangshanDist",
        "name": "香山區"
      }
    ]
  },
  {
    "id": "HsinchuCounty",
    "name": "新竹縣",
    "townships": [
      {
        "id": "302ZhubeiCity",
        "name": "竹北市"
      },
      {
        "id": "303HukouTownship",
        "name": "湖口鄉"
      },
      {
        "id": "304XinfengTownship",
        "name": "新豐鄉"
      },
      {
        "id": "305XinpuTownship",
        "name": "新埔鎮"
      },
      {
        "id": "306GuanxiTownship",
        "name": "關西鎮"
      },
      {
        "id": "307QionglinTownship",
        "name": "芎林鄉"
      },
      {
        "id": "308BaoshanTownship",
        "name": "寶山鄉"
      },
      {
        "id": "310ZhudongTownship",
        "name": "竹東鎮"
      },
      {
        "id": "311WufengTownship",
        "name": "五峰鄉"
      },
      {
        "id": "312HengshanTownship",
        "name": "橫山鄉"
      },
      {
        "id": "313JianshiTownship",
        "name": "尖石鄉"
      },
      {
        "id": "314BeipuTownship",
        "name": "北埔鄉"
      },
      {
        "id": "315EmeiTownship",
        "name": "峨眉鄉"
      }
    ]
  },
  {
    "id": "TaoyuanCounty",
    "name": "桃園市",
    "townships": [
      {
        "id": "320ZhongliCity",
        "name": "中壢區"
      },
      {
        "id": "324PingzhenCity",
        "name": "平鎮區"
      },
      {
        "id": "325LongtanTownship",
        "name": "龍潭區"
      },
      {
        "id": "326YangmeiCity",
        "name": "楊梅區"
      },
      {
        "id": "327XinwuTownship",
        "name": "新屋區"
      },
      {
        "id": "328GuanyinTownship",
        "name": "觀音區"
      },
      {
        "id": "330TaoyuanCity",
        "name": "桃園區"
      },
      {
        "id": "333GuishanTownship",
        "name": "龜山區"
      },
      {
        "id": "334BadeCity",
        "name": "八德區"
      },
      {
        "id": "335DaxiTownship",
        "name": "大溪區"
      },
      {
        "id": "336FuxingTownship",
        "name": "復興區"
      },
      {
        "id": "337DayuanTownship",
        "name": "大園區"
      },
      {
        "id": "338LuzhuTownship",
        "name": "蘆竹區"
      }
    ]
  },
  {
    "id": "MiaoliCounty",
    "name": "苗栗縣",
    "townships": [
      {
        "id": "350ZhunanTownship",
        "name": "竹南鎮"
      },
      {
        "id": "351ToufenTownship",
        "name": "頭份市"
      },
      {
        "id": "352SanwanTownship",
        "name": "三灣鄉"
      },
      {
        "id": "353NanzhuangTownship",
        "name": "南庄鄉"
      },
      {
        "id": "354ShitanTownship",
        "name": "獅潭鄉"
      },
      {
        "id": "356HoulongTownship",
        "name": "後龍鎮"
      },
      {
        "id": "357TongxiaoTownship",
        "name": "通霄鎮"
      },
      {
        "id": "358YuanliTownship",
        "name": "苑裡鎮"
      },
      {
        "id": "360MiaoliCity",
        "name": "苗栗市"
      },
      {
        "id": "361ZaoqiaoTownship",
        "name": "造橋鄉"
      },
      {
        "id": "362TouwuTownship",
        "name": "頭屋鄉"
      },
      {
        "id": "363GongguanTownship",
        "name": "公館鄉"
      },
      {
        "id": "364DahuTownship",
        "name": "大湖鄉"
      },
      {
        "id": "365TaianTownship",
        "name": "泰安鄉"
      },
      {
        "id": "366TongluoTownship",
        "name": "銅鑼鄉"
      },
      {
        "id": "367SanyiTownship",
        "name": "三義鄉"
      },
      {
        "id": "368XihuTownship",
        "name": "西湖鄉"
      },
      {
        "id": "369ZhuolanTownship",
        "name": "卓蘭鎮"
      }
    ]
  },
  {
    "id": "TaichungCity",
    "name": "台中市",
    "townships": [
      {
        "id": "400CentralDist",
        "name": "中區"
      },
      {
        "id": "401EastDist",
        "name": "東區"
      },
      {
        "id": "402SouthDist",
        "name": "南區"
      },
      {
        "id": "403WestDist",
        "name": "西區"
      },
      {
        "id": "404NorthDist",
        "name": "北區"
      },
      {
        "id": "406BeitunDist",
        "name": "北屯區"
      },
      {
        "id": "407XitunDist",
        "name": "西屯區"
      },
      {
        "id": "408NantunDist",
        "name": "南屯區"
      },
      {
        "id": "411TaipingDist",
        "name": "太平區"
      },
      {
        "id": "412DaliDist",
        "name": "大里區"
      },
      {
        "id": "413WufengDist",
        "name": "霧峰區"
      },
      {
        "id": "414WuriDist",
        "name": "烏日區"
      },
      {
        "id": "420FengyuanDist",
        "name": "豐原區"
      },
      {
        "id": "421HouliDist",
        "name": "后里區"
      },
      {
        "id": "422ShigangDist",
        "name": "石岡區"
      },
      {
        "id": "423DongshiDist",
        "name": "東勢區"
      },
      {
        "id": "424HepingDist",
        "name": "和平區"
      },
      {
        "id": "426XinsheDist",
        "name": "新社區"
      },
      {
        "id": "427TanziDist",
        "name": "潭子區"
      },
      {
        "id": "428DayaDist",
        "name": "大雅區"
      },
      {
        "id": "429ShengangDist",
        "name": "神岡區"
      },
      {
        "id": "432DaduDist",
        "name": "大肚區"
      },
      {
        "id": "433ShaluDist",
        "name": "沙鹿區"
      },
      {
        "id": "434LongjingDist",
        "name": "龍井區"
      },
      {
        "id": "435WuqiDist",
        "name": "梧棲區"
      },
      {
        "id": "436QingshuiDist",
        "name": "清水區"
      },
      {
        "id": "437DajiaDist",
        "name": "大甲區"
      },
      {
        "id": "438WaipuDist",
        "name": "外埔區"
      },
      {
        "id": "439DaanDist",
        "name": "大安區"
      }
    ]
  },
  {
    "id": "ChanghuaCounty",
    "name": "彰化縣",
    "townships": [
      {
        "id": "500ChanghuaCity",
        "name": "彰化市"
      },
      {
        "id": "502FenyuanTownship",
        "name": "芬園鄉"
      },
      {
        "id": "503HuatanTownship",
        "name": "花壇鄉"
      },
      {
        "id": "504XiushuiTownship",
        "name": "秀水鄉"
      },
      {
        "id": "505LugangTownship",
        "name": "鹿港鎮"
      },
      {
        "id": "506FuxingTownship",
        "name": "福興鄉"
      },
      {
        "id": "507XianxiTownship",
        "name": "線西鄉"
      },
      {
        "id": "508HemeiTownship",
        "name": "和美鎮"
      },
      {
        "id": "509ShengangTownship",
        "name": "伸港鄉"
      },
      {
        "id": "510YuanlinTownship",
        "name": "員林市"
      },
      {
        "id": "511ShetouTownship",
        "name": "社頭鄉"
      },
      {
        "id": "512YongjingTownship",
        "name": "永靖鄉"
      },
      {
        "id": "513PuxinTownship",
        "name": "埔心鄉"
      },
      {
        "id": "514XihuTownship",
        "name": "溪湖鎮"
      },
      {
        "id": "515DacunTownship",
        "name": "大村鄉"
      },
      {
        "id": "516PuyanTownship",
        "name": "埔鹽鄉"
      },
      {
        "id": "520TianzhongTownship",
        "name": "田中鎮"
      },
      {
        "id": "521BeidouTownship",
        "name": "北斗鎮"
      },
      {
        "id": "522TianweiTownship",
        "name": "田尾鄉"
      },
      {
        "id": "523PitouTownship",
        "name": "埤頭鄉"
      },
      {
        "id": "524XizhouTownship",
        "name": "溪州鄉"
      },
      {
        "id": "525ZhutangTownship",
        "name": "竹塘鄉"
      },
      {
        "id": "526ErlinTownship",
        "name": "二林鎮"
      },
      {
        "id": "527DachengTownship",
        "name": "大城鄉"
      },
      {
        "id": "528FangyuanTownship",
        "name": "芳苑鄉"
      },
      {
        "id": "530ErshuiTownship",
        "name": "二水鄉"
      }
    ]
  },
  {
    "id": "NantouCounty",
    "name": "南投縣",
    "townships": [
      {
        "id": "540NantouCity",
        "name": "南投市"
      },
      {
        "id": "541ZhongliaoTownship",
        "name": "中寮鄉"
      },
      {
        "id": "542CaotunTownship",
        "name": "草屯鎮"
      },
      {
        "id": "544GuoxingTownship",
        "name": "國姓鄉"
      },
      {
        "id": "545PuliTownship",
        "name": "埔里鎮"
      },
      {
        "id": "546RenaiTownship",
        "name": "仁愛鄉"
      },
      {
        "id": "551MingjianTownship",
        "name": "名間鄉"
      },
      {
        "id": "552JijiTownship",
        "name": "集集鎮"
      },
      {
        "id": "553ShuiliTownship",
        "name": "水里鄉"
      },
      {
        "id": "555YuchiTownship",
        "name": "魚池鄉"
      },
      {
        "id": "556XinyiTownship",
        "name": "信義鄉"
      },
      {
        "id": "557ZhushanTownship",
        "name": "竹山鎮"
      },
      {
        "id": "558LuguTownship",
        "name": "鹿谷鄉"
      }
    ]
  },
  {
    "id": "ChiayiCity",
    "name": "嘉義市",
    "townships": [
      {
        "id": "600EastDist",
        "name": "東區"
      },
      {
        "id": "600WestDist",
        "name": "西區"
      }
    ]
  },
  {
    "id": "ChiayiCounty",
    "name": "嘉義縣",
    "townships": [
      {
        "id": "602FanluTownship",
        "name": "番路鄉"
      },
      {
        "id": "603MeishanTownship",
        "name": "梅山鄉"
      },
      {
        "id": "604ZhuqiTownship",
        "name": "竹崎鄉"
      },
      {
        "id": "605AlishanTownship",
        "name": "阿里山鄉"
      },
      {
        "id": "606ZhongpuTownship",
        "name": "中埔鄉"
      },
      {
        "id": "607DapuTownship",
        "name": "大埔鄉"
      },
      {
        "id": "608ShuishangTownship",
        "name": "水上鄉"
      },
      {
        "id": "611LucaoTownship",
        "name": "鹿草鄉"
      },
      {
        "id": "612TaibaoCity",
        "name": "太保市"
      },
      {
        "id": "613PuziCity",
        "name": "朴子市"
      },
      {
        "id": "614DongshiTownship",
        "name": "東石鄉"
      },
      {
        "id": "615LiujiaoTownship",
        "name": "六腳鄉"
      },
      {
        "id": "616XingangTownship",
        "name": "新港鄉"
      },
      {
        "id": "621MinxiongTownship",
        "name": "民雄鄉"
      },
      {
        "id": "622DalinTownship",
        "name": "大林鎮"
      },
      {
        "id": "623XikouTownship",
        "name": "溪口鄉"
      },
      {
        "id": "624YizhuTownship",
        "name": "義竹鄉"
      },
      {
        "id": "625BudaiTownship",
        "name": "布袋鎮"
      }
    ]
  },
  {
    "id": "YunlinCounty",
    "name": "雲林縣",
    "townships": [
      {
        "id": "630DounanTownship",
        "name": "斗南鎮"
      },
      {
        "id": "631DapiTownship",
        "name": "大埤鄉"
      },
      {
        "id": "632HuweiTownship",
        "name": "虎尾鎮"
      },
      {
        "id": "633TukuTownship",
        "name": "土庫鎮"
      },
      {
        "id": "634BaozhongTownship",
        "name": "褒忠鄉"
      },
      {
        "id": "635DongshiTownship",
        "name": "東勢鄉"
      },
      {
        "id": "636TaixiTownship",
        "name": "台西鄉"
      },
      {
        "id": "637LunbeiTownship",
        "name": "崙背鄉"
      },
      {
        "id": "638MailiaoTownship",
        "name": "麥寮鄉"
      },
      {
        "id": "640DouliuCity",
        "name": "斗六市"
      },
      {
        "id": "643LinneiTownship",
        "name": "林內鄉"
      },
      {
        "id": "646GukengTownship",
        "name": "古坑鄉"
      },
      {
        "id": "647CitongTownship",
        "name": "莿桐鄉"
      },
      {
        "id": "648XiluoTownship",
        "name": "西螺鎮"
      },
      {
        "id": "649ErlunTownship",
        "name": "二崙鄉"
      },
      {
        "id": "651BeigangTownship",
        "name": "北港鎮"
      },
      {
        "id": "652ShuilinTownship",
        "name": "水林鄉"
      },
      {
        "id": "653KouhuTownship",
        "name": "口湖鄉"
      },
      {
        "id": "654SihuTownship",
        "name": "四湖鄉"
      },
      {
        "id": "655YuanchangTownship",
        "name": "元長鄉"
      }
    ]
  },
  {
    "id": "TainanCity",
    "name": "台南市",
    "townships": [
      {
        "id": "700WestCentralDist",
        "name": "中西區"
      },
      {
        "id": "701EastDist",
        "name": "東區"
      },
      {
        "id": "702SouthDist",
        "name": "南區"
      },
      {
        "id": "704NorthDist",
        "name": "北區"
      },
      {
        "id": "708AnpingDist",
        "name": "安平區"
      },
      {
        "id": "709AnnanDist",
        "name": "安南區"
      },
      {
        "id": "710YongkangDist",
        "name": "永康區"
      },
      {
        "id": "711GuirenDist",
        "name": "歸仁區"
      },
      {
        "id": "712XinhuaDist",
        "name": "新化區"
      },
      {
        "id": "713ZuozhenDist",
        "name": "左鎮區"
      },
      {
        "id": "714YujingDist",
        "name": "玉井區"
      },
      {
        "id": "715NanxiDist",
        "name": "楠西區"
      },
      {
        "id": "716NanhuaDist",
        "name": "南化區"
      },
      {
        "id": "717RendeDist",
        "name": "仁德區"
      },
      {
        "id": "718GuanmiaoDist",
        "name": "關廟區"
      },
      {
        "id": "719LongqiDist",
        "name": "龍崎區"
      },
      {
        "id": "720GuantianDist",
        "name": "官田區"
      },
      {
        "id": "721MadouDist",
        "name": "麻豆區"
      },
      {
        "id": "722JialiDist",
        "name": "佳里區"
      },
      {
        "id": "723XigangDist",
        "name": "西港區"
      },
      {
        "id": "724QiguDist",
        "name": "七股區"
      },
      {
        "id": "725JiangjunDist",
        "name": "將軍區"
      },
      {
        "id": "726XuejiaDist",
        "name": "學甲區"
      },
      {
        "id": "727BeimenDist",
        "name": "北門區"
      },
      {
        "id": "730XinyingDist",
        "name": "新營區"
      },
      {
        "id": "731HoubiDist",
        "name": "後壁區"
      },
      {
        "id": "732BaiheDist",
        "name": "白河區"
      },
      {
        "id": "733DongshanDist",
        "name": "東山區"
      },
      {
        "id": "734LiujiaDist",
        "name": "六甲區"
      },
      {
        "id": "735XiayingDist",
        "name": "下營區"
      },
      {
        "id": "736LiuyingDist",
        "name": "柳營區"
      },
      {
        "id": "737YanshuiDist",
        "name": "鹽水區"
      },
      {
        "id": "741ShanhuaDist",
        "name": "善化區"
      },
      {
        "id": "742DaneiDist",
        "name": "大內區"
      },
      {
        "id": "743ShanshangDist",
        "name": "山上區"
      },
      {
        "id": "744XinshiDist",
        "name": "新市區"
      },
      {
        "id": "745AndingDist",
        "name": "安定區"
      }
    ]
  },
  {
    "id": "KaohsiungCity",
    "name": "高雄市",
    "townships": [
      {
        "id": "800XinxingDist",
        "name": "新興區"
      },
      {
        "id": "801QianjinDist",
        "name": "前金區"
      },
      {
        "id": "802LingyaDist",
        "name": "苓雅區"
      },
      {
        "id": "803YanchengDist",
        "name": "鹽埕區"
      },
      {
        "id": "804GushanDist",
        "name": "鼓山區"
      },
      {
        "id": "805QijinDist",
        "name": "旗津區"
      },
      {
        "id": "806QianzhenDist",
        "name": "前鎮區"
      },
      {
        "id": "807SanminDist",
        "name": "三民區"
      },
      {
        "id": "811NanziDist",
        "name": "楠梓區"
      },
      {
        "id": "812XiaogangDist",
        "name": "小港區"
      },
      {
        "id": "813ZuoyingDist",
        "name": "左營區"
      },
      {
        "id": "814RenwuDist",
        "name": "仁武區"
      },
      {
        "id": "815DasheDist",
        "name": "大社區"
      },
      {
        "id": "820GangshanDist",
        "name": "岡山區"
      },
      {
        "id": "821LuzhuDist",
        "name": "路竹區"
      },
      {
        "id": "822AlianDist",
        "name": "阿蓮區"
      },
      {
        "id": "823TianliaoDist",
        "name": "田寮區"
      },
      {
        "id": "824YanchaoDist",
        "name": "燕巢區"
      },
      {
        "id": "825QiaotouDist",
        "name": "橋頭區"
      },
      {
        "id": "826ZiguanDist",
        "name": "梓官區"
      },
      {
        "id": "827MituoDist",
        "name": "彌陀區"
      },
      {
        "id": "828YonganDist",
        "name": "永安區"
      },
      {
        "id": "829HuneiDist",
        "name": "湖內區"
      },
      {
        "id": "830FengshanDist",
        "name": "鳳山區"
      },
      {
        "id": "831DaliaoDist",
        "name": "大寮區"
      },
      {
        "id": "832LinyuanDist",
        "name": "林園區"
      },
      {
        "id": "833NiaosongDist",
        "name": "鳥松區"
      },
      {
        "id": "840DashuDist",
        "name": "大樹區"
      },
      {
        "id": "842QishanDist",
        "name": "旗山區"
      },
      {
        "id": "843MeinongDist",
        "name": "美濃區"
      },
      {
        "id": "844LiuguiDist",
        "name": "六龜區"
      },
      {
        "id": "845NeimenDist",
        "name": "內門區"
      },
      {
        "id": "846ShanlinDist",
        "name": "杉林區"
      },
      {
        "id": "847JiaxianDist",
        "name": "甲仙區"
      },
      {
        "id": "848TaoyuanDist",
        "name": "桃源區"
      },
      {
        "id": "849NamaxiaDist",
        "name": "那瑪夏區"
      },
      {
        "id": "851MaolinDist",
        "name": "茂林區"
      },
      {
        "id": "852QiedingDist",
        "name": "茄萣區"
      }
    ]
  },
  {
    "id": "NanhaiIslands",
    "name": "南海島",
    "townships": [
      {
        "id": "817DongshaIslands",
        "name": "東沙群島"
      },
      {
        "id": "819NanshaIslands",
        "name": "南沙群島"
      }
    ]
  },
  {
    "id": "PenghuCounty",
    "name": "澎湖縣",
    "townships": [
      {
        "id": "880MagongCity",
        "name": "馬公市"
      },
      {
        "id": "881XiyuTownship",
        "name": "西嶼鄉"
      },
      {
        "id": "882WanganTownship",
        "name": "望安鄉"
      },
      {
        "id": "883QimeiTownship",
        "name": "七美鄉"
      },
      {
        "id": "884BaishaTownship",
        "name": "白沙鄉"
      },
      {
        "id": "885HuxiTownship",
        "name": "湖西鄉"
      }
    ]
  },
  {
    "id": "KinmenCounty",
    "name": "金門縣",
    "townships": [
      {
        "id": "890JinshaTownship",
        "name": "金沙鎮"
      },
      {
        "id": "891JinhuTownship",
        "name": "金湖鎮"
      },
      {
        "id": "892JinningTownship",
        "name": "金寧鄉"
      },
      {
        "id": "893JinchengTownship",
        "name": "金城鎮"
      },
      {
        "id": "894LieyuTownship",
        "name": "烈嶼鄉"
      },
      {
        "id": "896WuqiuTownship",
        "name": "烏坵鄉"
      }
    ]
  },
  {
    "id": "PingtungCounty",
    "name": "屏東縣",
    "townships": [
      {
        "id": "900PingtungCity",
        "name": "屏東市"
      },
      {
        "id": "901SandimenTownship",
        "name": "三地門鄉"
      },
      {
        "id": "902WutaiTownship",
        "name": "霧台鄉"
      },
      {
        "id": "903MajiaTownship",
        "name": "瑪家鄉"
      },
      {
        "id": "904JiuruTownship",
        "name": "九如鄉"
      },
      {
        "id": "905LigangTownship",
        "name": "里港鄉"
      },
      {
        "id": "906GaoshuTownship",
        "name": "高樹鄉"
      },
      {
        "id": "907YanpuTownship",
        "name": "鹽埔鄉"
      },
      {
        "id": "908ChangzhiTownship",
        "name": "長治鄉"
      },
      {
        "id": "909LinluoTownship",
        "name": "麟洛鄉"
      },
      {
        "id": "911ZhutianTownship",
        "name": "竹田鄉"
      },
      {
        "id": "912NeipuTownship",
        "name": "內埔鄉"
      },
      {
        "id": "913WandanTownship",
        "name": "萬丹鄉"
      },
      {
        "id": "920ChaozhouTownship",
        "name": "潮州鎮"
      },
      {
        "id": "921TaiwuTownship",
        "name": "泰武鄉"
      },
      {
        "id": "922LaiyiTownship",
        "name": "來義鄉"
      },
      {
        "id": "923WanluanTownship",
        "name": "萬巒鄉"
      },
      {
        "id": "924KandingTownship",
        "name": "崁頂鄉"
      },
      {
        "id": "925XinpiTownship",
        "name": "新埤鄉"
      },
      {
        "id": "926NanzhouTownship",
        "name": "南州鄉"
      },
      {
        "id": "927LinbianTownship",
        "name": "林邊鄉"
      },
      {
        "id": "928DonggangTownship",
        "name": "東港鎮"
      },
      {
        "id": "929LiuqiuTownship",
        "name": "琉球鄉"
      },
      {
        "id": "931JiadongTownship",
        "name": "佳冬鄉"
      },
      {
        "id": "932XinyuanTownship",
        "name": "新園鄉"
      },
      {
        "id": "940FangliaoTownship",
        "name": "枋寮鄉"
      },
      {
        "id": "941FangshanTownship",
        "name": "枋山鄉"
      },
      {
        "id": "942ChunriTownship",
        "name": "春日鄉"
      },
      {
        "id": "943ShiziTownship",
        "name": "獅子鄉"
      },
      {
        "id": "944ChechengTownship",
        "name": "車城鄉"
      },
      {
        "id": "945MudanTownship",
        "name": "牡丹鄉"
      },
      {
        "id": "946HengchunTownship",
        "name": "恆春鎮"
      },
      {
        "id": "947ManzhouTownship",
        "name": "滿州鄉"
      }
    ]
  },
  {
    "id": "TaitungCounty",
    "name": "台東縣",
    "townships": [
      {
        "id": "950TaitungCity",
        "name": "台東市"
      },
      {
        "id": "951LudaoTownship",
        "name": "綠島鄉"
      },
      {
        "id": "952LanyuTownship",
        "name": "蘭嶼鄉"
      },
      {
        "id": "953YanpingTownship",
        "name": "延平鄉"
      },
      {
        "id": "954BeinanTownship",
        "name": "卑南鄉"
      },
      {
        "id": "955LuyeTownship",
        "name": "鹿野鄉"
      },
      {
        "id": "956GuanshanTownship",
        "name": "關山鎮"
      },
      {
        "id": "957HaiduanTownship",
        "name": "海端鄉"
      },
      {
        "id": "958ChishangTownship",
        "name": "池上鄉"
      },
      {
        "id": "959DongheTownship",
        "name": "東河鄉"
      },
      {
        "id": "961ChenggongTownship",
        "name": "成功鎮"
      },
      {
        "id": "962ChangbinTownship",
        "name": "長濱鄉"
      },
      {
        "id": "963TaimaliTownship",
        "name": "太麻里鄉"
      },
      {
        "id": "964JinfengTownship",
        "name": "金峰鄉"
      },
      {
        "id": "965DawuTownship",
        "name": "大武鄉"
      },
      {
        "id": "966DarenTownship",
        "name": "達仁鄉"
      }
    ]
  },
  {
    "id": "HualienCounty",
    "name": "花蓮縣",
    "townships": [
      {
        "id": "970HualienCity",
        "name": "花蓮市"
      },
      {
        "id": "971XinchengTownship",
        "name": "新城鄉"
      },
      {
        "id": "972XiulinTownship",
        "name": "秀林鄉"
      },
      {
        "id": "973JianTownship",
        "name": "吉安鄉"
      },
      {
        "id": "974ShoufengTownship",
        "name": "壽豐鄉"
      },
      {
        "id": "975FenglinTownship",
        "name": "鳳林鎮"
      },
      {
        "id": "976GuangfuTownship",
        "name": "光復鄉"
      },
      {
        "id": "977FengbinTownship",
        "name": "豐濱鄉"
      },
      {
        "id": "978RuisuiTownship",
        "name": "瑞穗鄉"
      },
      {
        "id": "979WanrongTownship",
        "name": "萬榮鄉"
      },
      {
        "id": "981YuliTownship",
        "name": "玉里鎮"
      },
      {
        "id": "982ZhuoxiTownship",
        "name": "卓溪鄉"
      },
      {
        "id": "983FuliTownship",
        "name": "富里鄉"
      }
    ]
  }
]