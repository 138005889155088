import {
  RadioGroup,
  RadioGroupChangeEvent,
  RadioGroupProps,
} from "@progress/kendo-react-inputs";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import { useState } from "react";

export const RadioGroupStyle = css`
  .k-radio-item {
    gap: 0;
    .k-radio {
      width: 16px;
      height: 16px;
      border: 1px solid ${(props) => props.theme.colorGray400};
      background-color: ${(props) => props.theme.colorWhite};

      &:checked {
        border-color: ${(props) => props.theme.colorPrimary};
        background-color: ${(props) => props.theme.colorPrimary};
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3ccircle cx='50%25' cy='50%25' r='3' fill='%23${(props) => props.theme.colorWhite.substring(1)}'/%3e%3c/svg%3e");
      }

      &:disabled {
        border: 1px solid ${(props) => props.theme.colorGray300};
        background-color: ${(props) => props.theme.colorGray200};
        &:hover {
          border: 1px solid ${(props) => props.theme.colorGray300};
          box-shadow: none;
        }
      }

      &:disabled:checked {
        border: 1px solid ${(props) => props.theme.colorGray300};
        background-color: ${(props) => props.theme.colorGray200};
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3ccircle cx='50%25' cy='50%25' r='3' fill='%23${(props) => props.theme.colorGray400.substring(1)}'/%3e%3c/svg%3e");
        &:hover {
          box-shadow: none;
        }
      }

      &:focus {
        box-shadow: none;
      }

      &:hover {
        border: 1px solid ${(props) => props.theme.colorPrimary};
        box-shadow: 0px 0px 0px 2px
          ${(props) => rgba(props.theme.colorPrimary, 0.2)};
      }
    }
    .k-radio-label {
      font-size: ${(props) => props.theme.fontSize};
      font-weight: 400;
      line-height: 20px;
      padding-left: 8px;
    }
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  ${RadioGroupStyle}
`;

const RadioButtonGroup = (props: RadioGroupProps) => {
  const { name, data, defaultValue, layout, ...others } = props;
  const [checkedValue, setCheckedValue] = useState(defaultValue);

  const handleChange = (event: RadioGroupChangeEvent) => {
    setCheckedValue(event.value);
  };

  return (
    <StyledRadioGroup
      name={name}
      data={data}
      defaultValue={checkedValue}
      layout={layout}
      onChange={handleChange}
      {...others}
    />
  );
};

export default RadioButtonGroup;
