import { Link, matchPath, useMatches, useLocation } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { useMedia } from "react-use";
import { useContext } from "react";
import { useRoutes, IRoute } from "../constants/Routes";

export interface BreadcrumbProps {
  breadcrumbShowName?: string;
  hasBreadcrumbArrow?: boolean;
}

export const defaultBreadcrumbProps: BreadcrumbProps = {
  breadcrumbShowName: "",
  hasBreadcrumbArrow: true,
}

const BreadcrumbsContent = styled.div<BreadcrumbProps>`
  display: flex;
  gap: 8px;
  font-size: 20px;
  font-weight: bold;
  margin-right: 32px;
  span {
    &:last-of-type {
      & > a {
        color: ${(props) => props.theme.colorGray900};
      }
      @media screen and (${(props) => props.theme.devices.mobile.mediaQuery}) {
        .breadcrumbFinalNode::before {
          content: ${props => props.hasBreadcrumbArrow ? "< " : ""};
        }
      }
    }
    &:not(:last-of-type) {
      @media screen and (${(props) => props.theme.devices.mobile.mediaQuery}) {
        display: none;
      }
      a {
        color: ${(props) => props.theme.colorGray500};
        &:hover {
          color: ${(props) => props.theme.colorGray600};
        }
        &:active {
          color: ${(props) => props.theme.colorGray700};
        }
      }
      &::after {
        // font-family: "Font Awesome 5 Pro";
        // font-weight: 400;
        // content: "\f054";
        content: ">";
        color: ${(props) => props.theme.colorGray500};
        padding-left: 8px;
      }
    }
    a {
      text-decoration: none;
    }
  }

  @media screen and (${props => props.theme.devices.tablet.mediaQuery}) {
    margin-left: 8px;
  }
`;

const Breadcrumb = (props: BreadcrumbProps) => {
  const { breadcrumbShowName, hasBreadcrumbArrow } = props;
  const themeContext = useContext(ThemeContext);
  const isMobile = useMedia(`(${themeContext?.devices.mobile.mediaQuery})`);

  /**
   * 目前型別辨識還有點問題, 先寫成 any
   * https://github.com/remix-run/react-router/discussions/9534
   */
  const matches: any = useMatches();
  const { pathname } = useLocation();
  // 找到有符合 path 的 route
  const routes = useRoutes();
  const matchRoutes = routes.filter((route) => {
    const paths = route.paths;
    const matchPaths = paths.filter((path) => matchPath(path, pathname));
    return matchPaths.length;
  });
  const matchRoute = matchRoutes[0];

  const renderBreadcrumb = (
    matchRoute: IRoute,
    matches: any
  ): React.ReactNode => {
    if (!matchRoute) return <></>;
    if (!matchRoute.isShowPageTitle && matchRoute.isShowBreadcrumbName) {
      const linkRoute = matches.slice(
        matches.length - 2,
        matches.length - 1
      )[0];
      const { pathname: linkUrl } = linkRoute;
      const breadcrumbFinalNodeRender = isMobile ? (
        <Link className="breadcrumbFinalNode" to={linkUrl}>
          {breadcrumbShowName}
        </Link>
      ) : (
        <span className="breadcrumbFinalNode">{breadcrumbShowName}</span>
      );
      return (
        <>
          <span>
            <Link to={linkUrl}>{matchRoute.pageTitle}</Link>
          </span>
          <span>{breadcrumbFinalNodeRender}</span>
        </>
      );
    }
    if (!matchRoute.isShowPageTitle) {
      return <span>{matchRoute.pageTitle}</span>;
    }
  };

  return (
    <BreadcrumbsContent className="breadcrumbContent" hasBreadcrumbArrow={hasBreadcrumbArrow}>
      {renderBreadcrumb(matchRoute, matches)}
    </BreadcrumbsContent>
  );
};

export default Breadcrumb;
