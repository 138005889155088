import styled from "styled-components";
import { Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import RadioButtonGroup from "../../components/shared/RadioButtonGroup";
import { ICustomerTaskCond, IFilterCond } from "./TaskList";
import { RadioGroupChangeEvent } from "@progress/kendo-react-inputs";
import { DropDownListChangeEvent } from "@progress/kendo-react-dropdowns";
import { TaskType as ITaskType } from "../../models/Task";
import Button from "../../components/shared/Button";
import CustomDropDownList from "../../components/shared/CustomDropDownList";
import React from "react";
import { IscrollStatus } from "../customer/CustomerShow";
import { useTranslation } from 'react-i18next';

interface ITaskFilterProps {
  filterVisible: boolean;
  isMobile: boolean;
  setFilterVisible: React.Dispatch<React.SetStateAction<boolean>>;
  filterRef: React.RefObject<HTMLDivElement>;
  toolbarRef: React.RefObject<HTMLDivElement>;
  customerTaskCond: ICustomerTaskCond;
  setCustomerTaskCond: React.Dispatch<React.SetStateAction<ICustomerTaskCond>>;
  setScrollStatus: React.Dispatch<React.SetStateAction<IscrollStatus>>;
  filterCond: IFilterCond;
  setFilterCond: React.Dispatch<React.SetStateAction<IFilterCond>>;
  primaryTaskTypeData: ITaskType[] | undefined;
  secondaryTaskTypeData: ITaskType[] | undefined;
}

interface styledWindowProps {
  isMobile: boolean;
}

const StyledWindow = styled(Window) <styledWindowProps>`
  border-radius: ${(props) => props.theme.windowBorderRadius};
  box-shadow: ${(props) => props.theme.windowBoxShadow};
  width: ${props => props.isMobile ? "100%" : "400px"};
  cursor: default;
  .k-window-titlebar {
    background-color: ${(props) => props.theme.colorWhite};
    color: ${(props) => props.theme.colorGray800};
    font-size: ${(props) => props.theme.fontSize};
    border-color: ${(props) => props.theme.colorGray200};
    .k-window-title {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .k-window-content {
    .fieldContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      padding: 8px 16px;
    }
  }
  .k-actions {
    background-color: ${(props) => props.theme.colorGray100};
    justify-content: flex-start;

    .rightAction {
      margin-left: auto;
    }
  }
`;

const TaskFilter = (props: ITaskFilterProps) => {
  const { t } = useTranslation();
  const {
    filterVisible,
    isMobile,
    setFilterVisible,
    filterRef,
    setCustomerTaskCond,
    setScrollStatus,
    filterCond,
    setFilterCond,
    primaryTaskTypeData,
    secondaryTaskTypeData,
  } = props;
  const taskOwnerData = [
    {
      value: "Mine",
      label: t("Com.Mine"),
    },
    {
      value: "All",
      label: t("Com.All"),
    },
  ];
  const taskStatusData = [
    {
      id: "all",
      text: t("Com.All"),
    },
    {
      id: "upcoming",
      text: t("Com.Upcoming"),
    },
    {
      id: "assigned",
      text: t("Com.Assigned"),
    },
    {
      id: "completed",
      text: t("Com.Completed"),
    },
    {
      id: "upcoming,assigned",
      text: t("Com.UpcomingAndAssigned"),
    },
  ];

  const handleResetFilter: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setFilterCond({
      taskOwner: "Mine",
      taskStatus: { id: "", text: "" },
      primaryTaskType: { id: "", name: "", sortOrder: 0 },
      secondaryTaskType: { id: "", name: "", sortOrder: 0 },
    });
  };

  const handleTaskFilterClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setCustomerTaskCond(prevCond => {
      return {
        ...prevCond,
        currentStatus: filterCond.taskStatus.id,
        primaryTypeIds: [filterCond.primaryTaskType.id],
        secondaryTypeIds: [filterCond.secondaryTaskType.id],
        page: 1,
      }
    })
    setScrollStatus("Idle");
    setFilterVisible(false);
  };

  const handleTaskOwnerChange = (e: RadioGroupChangeEvent) => {
    setFilterCond((prevCond) => {
      return {
        ...prevCond,
        taskOwner: e.value,
      };
    });
  };

  const handleTaskStatusChange = (e: DropDownListChangeEvent) => {
    const taskStatus = e.value;
    setFilterCond((prevCond) => {
      return {
        ...prevCond,
        taskStatus: taskStatus,
      };
    });
  };

  const handlePrimaryTaskTypeChange = (e: DropDownListChangeEvent) => {
    setFilterCond((prevCond) => {
      return {
        ...prevCond,
        primaryTaskType: e.value,
      };
    });
  };

  const handleSecondaryTaskTypeChange = (e: DropDownListChangeEvent) => {
    setFilterCond((prevCond) => {
      return {
        ...prevCond,
        secondaryTaskType: e.value,
      };
    });
  };

  return (
    <>
      {filterVisible && (
        <StyledWindow
          isMobile={isMobile}
          onClose={() => setFilterVisible(!filterVisible)}
          appendTo={filterRef?.current}
          initialTop={40}
          title={t("Com.Filters")}
          initialHeight={430}
          draggable={false}
          resizable={false}
          doubleClickStageChange={false}
          maximizeButton={() => null}
          minimizeButton={() => null}
          style={{ right: "7px", left: "auto" }}
        >
          <div className="fieldContainer">
            <div className="fieldTitle">{t("User.Custom.KeyWord.Task") + t("ImportantDateModel.ImportantDateType")}</div>
            <div className="fieldContent">
              <RadioButtonGroup
                name="taskOwner"
                data={taskOwnerData}
                layout="horizontal"
                value={filterCond.taskOwner}
                onChange={handleTaskOwnerChange}
              />
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldTitle">{t("User.Custom.KeyWord.Task") + t("Task.CurrentStatus")}</div>
            <div className="fieldContent">
              <CustomDropDownList
                data={taskStatusData}
                dataItemKey="id"
                textField="text"
                value={filterCond.taskStatus}
                onChange={handleTaskStatusChange}
                style={{ width: "230px" }}
              />
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldTitle">{t("User.Custom.KeyWord.PrimaryTaskType")}</div>
            <div className="fieldContent">
              <CustomDropDownList
                data={primaryTaskTypeData}
                dataItemKey="id"
                textField="name"
                value={filterCond.primaryTaskType}
                onChange={handlePrimaryTaskTypeChange}
                style={{ width: "230px" }}
              />
            </div>
          </div>
          <div className="fieldContainer">
            <div className="fieldTitle">{t("User.Custom.KeyWord.SecondaryTaskType")}</div>
            <div className="fieldContent">
              <CustomDropDownList
                data={secondaryTaskTypeData}
                dataItemKey="id"
                textField="name"
                value={filterCond.secondaryTaskType}
                onChange={handleSecondaryTaskTypeChange}
                style={{ width: "230px" }}
              />
            </div>
          </div>
          <WindowActionsBar>
            <Button isGrayBorder={true} onClick={handleResetFilter}>
              {t("Com.ResetCondition")}
            </Button>
            <Button className="rightAction" isGrayBorder={true} onClick={() => setFilterVisible(!filterVisible)}>
              {t("Com.Cancel")}
            </Button>
            <Button isPrimary={true} onClick={handleTaskFilterClick}>
              {t("Com.Save")}
            </Button>
          </WindowActionsBar>
        </StyledWindow>
      )}
    </>
  );
};

export default TaskFilter;
