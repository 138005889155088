import { ContactMech } from "./ContactMech";
import { WorkingCareer } from "./WorkingCareer";
import { PostalAddress } from "./PostalAddress";
import { DataPrivilege, emptyDataPrivilege } from "./DataPrivilege";
import { Label } from "./Label";

export interface Customer {
  id: string;
  customerNo: string;
  name: string;
  firstName: string;
  lastName: string;
  nickName: string;
  globalName: string;
  gender: 'Male' | 'Female' | 'Unknown';
  nationality?: string;
  createDate: string;
  createUserName: string;
  modifyDate: string;
  modifyUserName: string;
  mobiles: ContactMech[];
  faxes: ContactMech[];
  phones: ContactMech[];
  postalAddresses: PostalAddress[];
  emails: ContactMech[];
  websites: ContactMech[];
  workingCareers: WorkingCareer[];
  dataPrivilegeData: DataPrivilege;
  labels: Label[];
}

export const emptyCustomer: Customer = {
  id: '',
  customerNo: '',
  name: '',
  firstName: '',
  lastName: '',
  nickName: '',
  globalName: '',
  gender: 'Unknown',
  createDate: '',
  createUserName: '',
  modifyDate: '',
  modifyUserName: '',
  mobiles: [],
  faxes: [],
  phones: [],
  postalAddresses: [],
  emails: [],
  websites: [],
  workingCareers: [],
  dataPrivilegeData: emptyDataPrivilege,
  labels: [],
}