export interface User {
  id: string;
  name: string;
  account: string;
  locale: string;
}


export const emptyUser: User = {
  id: "",
  name: "",
  account: "",
  locale: "",
}