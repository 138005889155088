import { rgba } from "polished";
import { DefaultTheme } from "styled-components";

const defaultTheme: DefaultTheme = {
    devices: {
      mobile: {
        mediaQuery: 'max-width: 485px',
      },
      tablet: {
        mediaQuery: 'max-width: 960px',
      },
    },

    fontFamily: '"PingFang TC", "Microsoft JhengHei", sans-serif',
    fontSize: '14px',

    colorPrimary: '#0E86FE',
    colorError: '#EB5000',
    colorAlarm500: '#EB5000',
    colorSuccess500: '#2BCD86',

    colorGray900: '#272727',
    colorGray800: '#43434B',
    colorGray700: '#63636F',
    colorGray600: '#848492',
    colorGray500: '#A8A8B2',
    colorGray400: '#C8C8D0',
    colorGray300: '#DEDEE2',
    colorGray200: '#F0F0F2',
    colorGray100: '#F9F9FA',
    colorOpacityGray900: rgba("#232332", 1),
    colorOpacityGray800: rgba("#232332", 0.85),
    colorOpacityGray700: rgba("#232332", 0.7),
    colorOpacityGray600: rgba("#232332", 0.55),
    colorOpacityGray500: rgba("#232332", 0.4),
    colorOpacityGray400: rgba("#232332", 0.25),
    colorOpacityGray300: rgba("#232332", 0.15),
    colorOpacityGray200: rgba("#232332", 0.08),
    colorOpacityGray100: rgba("#232332", 0.03),
    colorWhite: '#FFFFFF',
    colorBg: '#F1F1F1',

    backgroundPrimary: 'linear-gradient(45deg, #33CFFF 0%, #0E86FE 100%)',

    buttonBackgroundPrimary: 'linear-gradient(45deg, #33CFFF 0%, #0E86FE 100%)',
    buttonBackgroundHover: 'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), linear-gradient(45deg, #33CFFF 0%, #0E86FE 100%)',
    buttonBackgroundPrimaryDisabled: 'linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), linear-gradient(45deg, #33CFFF 0%, #0E86FE 98.09%)',
    buttonShadowDefault: '0px 6px 10px 0px rgba(67, 67, 75, 0.1)',
    buttonShadowDisabled: '0px 6px 10px 0px rgba(35, 35, 50, 0.1)',
    buttonShadowPrimary: '0px 6px 10px 0px rgba(14, 134, 254, 0.30)',
    buttonShadowPrimaryDisabled: '0px 6px 10px 0px rgba(14, 134, 254, 0.30)',

    buttonBorderRadiusDefault: '16px',
    buttonBorderGray: '1px solid #DEDEE2',

    windowBorderRadius: '4px',
    windowBoxShadow: '0px 6px 20px 0px #43434B33',

}

export { defaultTheme };