import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { DataResult, State } from '@progress/kendo-data-query';
import LoadingPanel from "../LoadingPanel";
import { useCrmClient } from '../../contexts/CrmClientContext';

interface GridLoaderProps {
  apiUrl: string;
  pageState: State,
  onDataReceived: (opportunities: DataResult) => void,
  keyword: string;
  condition?: any;
}



const GridLoader = <T extends {}>(props: GridLoaderProps) => {
  const { apiUrl, pageState, onDataReceived, keyword, condition } = props;
  const { take = 10, skip = 0 } = pageState;
  const lastSuccess = useRef<string>('');
  const pending = useRef<string>('');
  let searchKey = keyword === "" ? '' : keyword;
  const crmClient = useCrmClient();
  const gridCondition = {
    ...condition,
    isQuickSearchMode: searchKey !== "" ? true : false,
    keyword: searchKey,
    page: (skip / take) + 1,
    pageSize: pageState.take
  }



  const requestDataIfNeeded = () => {
    const conditionData = JSON.stringify({ ...pageState, keyword: keyword });
    if (pending.current || conditionData === lastSuccess.current) {
      return;
    }
    pending.current = conditionData;
    crmClient!.get<T[]>(apiUrl, {
      params: gridCondition
    })
      .then(response => {
        lastSuccess.current = pending.current;
        pending.current = '';
        if (conditionData === lastSuccess.current) {
          onDataReceived({
            data: response.data,
            total: parseInt(response.headers['x-vital-crm-total-entities'])
          })
        } else {
          requestDataIfNeeded();
        }
      });
  }

  requestDataIfNeeded();
  return pending.current ? <LoadingGridPanel /> : null;
}


const LoadingGridPanel = () => {
  const gridContent = document && document.querySelector('.k-grid-content');
  return gridContent ? createPortal(<LoadingPanel />, gridContent) : null;
}

export default GridLoader;