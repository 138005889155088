import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { DataResult, State } from '@progress/kendo-data-query';
import { useCrmClient } from "../../contexts/CrmClientContext";
import { CustomerGridData } from "../../models/CustomerGridData";
import LoadingPanel from '../../components/LoadingPanel';

interface CustomersLoaderProps {
  pageState: State,
  onDataReceived: (customers: DataResult) => void,
  keyword: string
}

interface CustomerCondition {
  name?: string;
  phone?: string;
  email?: string;
  isQuickSearchMode?: boolean;
  page?: number;
  pageSize?: number;
}

export const CustomersLoader = (props: CustomersLoaderProps) => {
  const {take = 10, skip = 0} = props.pageState;
  const lastSuccess = React.useRef<string>('');
  const pending = React.useRef<string>('');
  let searchKey = props.keyword === "" ? '%' : props.keyword;
  const crmClient = useCrmClient();
  const condition: CustomerCondition = {
    name: searchKey,
    phone: searchKey,
    email: searchKey,
    isQuickSearchMode: true,
    page: (skip / take) + 1,
    pageSize: props.pageState.take,
  };

  const requestDataIfNeeded = () => {
    const conditionData = JSON.stringify({...props.pageState,keyword:props.keyword});
    if (pending.current || conditionData === lastSuccess.current) {
      return;
    }
    pending.current = conditionData;
    crmClient!.get<CustomerGridData[]>('customers', {
      params: condition,
    })
    .then(response => {
      lastSuccess.current = pending.current;
      pending.current = '';
      if (conditionData === lastSuccess.current) {
        props.onDataReceived.call(undefined, {
          data: response.data,
          total: parseInt(response.headers['x-vital-crm-total-entities'])
        });
      }else{
        requestDataIfNeeded();
      }
    });
  }

  requestDataIfNeeded();
  return pending.current ? <LoadingGridPanel /> : null;
}


const LoadingGridPanel = () => {
  const gridContent = document && document.querySelector('.k-grid-content');
  return gridContent ? ReactDOM.createPortal(<LoadingPanel />, gridContent) : null;
}