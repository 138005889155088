import { RadioButton, RadioButtonChangeEvent } from "@progress/kendo-react-inputs";
import React from "react";
import styled from "styled-components";
import { rgba } from "polished";

export interface EditorItemRadioButtonProps {
  name: string;
  value?: any;
  checked?: boolean;
  label?: string;
  labelStyle?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  onChange: (event: RadioButtonChangeEvent) => void;
}

const StyledRadioButtonWrapper = styled.div`
  .k-radio{
    width: 16px;
    height: 16px;
    border: 1px solid ${(props) => props.theme.colorGray400};
    background-color: ${(props) => props.theme.colorWhite};

    &:checked{
      border-color: ${props => props.theme.colorPrimary};
      background-color: ${props => props.theme.colorPrimary};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3ccircle cx='50%25' cy='50%25' r='3' fill='%23${(props) => props.theme.colorWhite.substring(1)}'/%3e%3c/svg%3e");
    }

    &:disabled{
      border: 1px solid ${(props) => props.theme.colorGray300};
      background-color: ${(props) => props.theme.colorGray200};
      &:hover{
        border: 1px solid ${(props) => props.theme.colorGray300};
        box-shadow: none;
      }
    }

    &:disabled:checked{
      border: 1px solid ${(props) => props.theme.colorGray300};
      background-color: ${(props) => props.theme.colorGray200};
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14'%3e%3ccircle cx='50%25' cy='50%25' r='3' fill='%23${(props) => props.theme.colorGray400.substring(1)}'/%3e%3c/svg%3e");
      &:hover{
        box-shadow: none;
      }
    }

    &:focus {
      box-shadow: none;
    }

    &:hover{
      border: 1px solid ${(props) => props.theme.colorPrimary};
      box-shadow: 0px 0px 0px 2px ${(props) => rgba(props.theme.colorPrimary, 0.2)};
    }

    &-label{
      font-size: ${(props) => props.theme.fontSize};
      line-height: 20px;
      padding-left: 8px;
      margin: 0;
    }
  }
`

export const EditorItemRadioButton = (props: EditorItemRadioButtonProps) => {
  const {
    name,
    value,
    checked,
    label,
    wrapperStyle,
    onChange,
  } = props;

  return (
    <StyledRadioButtonWrapper style={wrapperStyle}>
      <RadioButton name={name}
        value={value}
        checked={checked}
        label={label}
        onChange={onChange}
      />
    </StyledRadioButtonWrapper>
  );
}
