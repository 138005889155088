import styled from "styled-components";

interface HorizontalLineProps {
  styleProperties?: React.CSSProperties;
}

const StyledHorizontalLine = styled.div<HorizontalLineProps>`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${(props) => props.theme.colorGray200};
`;

const HorizontalLine = (props: HorizontalLineProps) => {
  const { styleProperties } = props;
  return <StyledHorizontalLine style={styleProperties} />;
};

export default HorizontalLine;
