import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Store } from "../models/Store";
import { User } from "../models/User";
import { useCrmClient } from "../contexts/CrmClientContext";
import { useClickOutside } from "../hooks/useClickOutside";
import defaultUserProfilePicture from "../images/user-profile-picture-default.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/pro-regular-svg-icons";
import Link from "../components/Link";
import axios from "axios";
import { useLoginUserContext } from "../contexts/LoginUserContext";
import { useTranslation } from 'react-i18next';

interface UserProfilePictureProps {
  imageUrl: string;
}

interface UserProfileProps {
  isOpened: boolean;
}

const UserProfileContainer = styled.div`
  position: relative;
`;

const UserProfilePicture = styled.div<UserProfilePictureProps>`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-size: 40px;
  cursor: pointer;
`;

const UserProfile = styled.div<UserProfileProps>`
  display: ${(props) => (props.isOpened ? "" : "none")};
  position: absolute;
  top: 48px;
  right: 0;
  width: 280px;
  border-radius: 4px;
  box-shadow: 0px 5px 25px 0px #43434b4d;
  background-color: #fff;
  z-index: 1;
  cursor: auto;
`;

const AccountInformationContainer = styled.div`
  display: flex;
  margin: 12px;
`;
const AccountInformation = styled.div`
  margin-left: 12px;
`;
const AccountName = styled.div`
  color: ${(props) => props.theme.colorGray800};
  line-height: 20px;
`;
const Account = styled.div`
  margin-top: 4px;
  color: ${(props) => props.theme.colorGray500};
  font-size: 12px;
  line-height: 12px;
`;

const StoreInformation = styled.div`
  padding: 12px 12px 18px 12px;
  border-top: 1px solid ${(props) => props.theme.colorGray200};
`;
const InformationTitle = styled.div`
  font-size: 12px;
  line-height: 12px;
  color: ${(props) => props.theme.colorGray500};
`;
const CurrentStore = styled.div`
  margin-top: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props) => props.theme.colorGray800};
`;

const ActionList = styled.div`
  border-top: 1px solid ${(props) => props.theme.colorGray200};
`;
const ActionLink = styled(Link)`
  padding: 18px 12px;
  font-weight: 400;
  cursor: pointer;

  // Override link style.
  color: ${(props) => props.theme.colorGray700};

  &:hover {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: ${(props) => props.theme.colorGray100};
  }
`;
const ActionText = styled.span`
  margin-left: 8px;
`;



const UserProfilePanel = () => {
  const crmClient = useCrmClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentStore, setCurrentStore] = useState<Store>({
    siteName: "",
    storeName: "",
  });
  const { loginUser, setLoginUser } = useLoginUserContext();
  const [userProfilePictureUrl, setUserProfilePictureUrl] = useState(
    defaultUserProfilePicture
  );
  const userProfileRef = useRef<HTMLDivElement>(null);
  useClickOutside(userProfileRef.current, () => setIsUserProfileOpened(false));
  const [isUserProfileOpened, setIsUserProfileOpened] =
    useState<boolean>(false);

  useEffect(() => {
    crmClient!
      .get<Store>("stores/mine")
      .then(
        (response) => {
          setCurrentStore(response.data);
          return crmClient!.get<User>("users/me");
        },
        (error) => Promise.reject(error)
      )
      .then(
        (response) => {
          setLoginUser(response.data);

          return crmClient!.get(`users/${response.data.id}/profilepicture`, {
            responseType: "blob",
            params: {
              isThumbnail: true,
            },
          });
        },
        (error) => Promise.reject(error)
      )
      .then(
        (response) => {
          const file = new Blob([response.data]);
          const url = URL.createObjectURL(file);
          setUserProfilePictureUrl(url);
        },
        (error) => Promise.reject(error)
      )
      .catch((error) => {
        console.error("An error occurs:", error);
        if (axios.isAxiosError(error)) {
          switch (error.response?.status) {
            case 401:
              navigate("/login", { replace: true });
          }
        }
      });
  }, [crmClient, navigate]);

  const onUserProfilePictureClick = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    event.stopPropagation();
    // 點擊 - UserProfile
    if (
      userProfileRef.current &&
      userProfileRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    // 點擊 - UserProfilePicture
    setIsUserProfileOpened(!isUserProfileOpened);
  };

  const onLogout = () => {
    localStorage.removeItem("crm.currentSiteName");
    localStorage.removeItem(`crm.${currentStore?.siteName}.credential`);
    navigate("/login", { replace: true });
  };

  return (
    <UserProfileContainer>
      <UserProfilePicture
        imageUrl={userProfilePictureUrl}
        onClick={onUserProfilePictureClick}
      >
        <UserProfile isOpened={isUserProfileOpened} ref={userProfileRef}>
          <AccountInformationContainer>
            <UserProfilePicture imageUrl={userProfilePictureUrl} />
            <AccountInformation>
              <AccountName>{loginUser.name}</AccountName>
              <Account>{loginUser.account}</Account>
            </AccountInformation>
          </AccountInformationContainer>
          <StoreInformation>
            <InformationTitle>{t("AccountPanel.CurrentStore")}</InformationTitle>
            <CurrentStore>{currentStore.storeName}</CurrentStore>
          </StoreInformation>
          <ActionList>
            <ActionLink
              target="_blank"
              href={`${process.env.REACT_APP_CRM_GSS_COULD_MEMBER_BASE_URL}/cas/logout`}
              isNewLine={true}
              onClick={onLogout}
            >
              <FontAwesomeIcon icon={faSignOutAlt} width="20px" />
              <ActionText>{t("Com.SingOut.Account")}</ActionText>
            </ActionLink>
          </ActionList>
        </UserProfile>
      </UserProfilePicture>
    </UserProfileContainer>
  );
};


export default UserProfilePanel;