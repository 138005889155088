import { css } from "styled-components";

export interface ButtonStyleProps {
  isPrimary?: boolean;
  isGrayBorder?: boolean;
  isDisabled?: boolean;
  isSubmit?: boolean;
  content?: string;
  hasChildren?: boolean;
}

const BaseButtonStyle = css<ButtonStyleProps>`
  padding: 6px 16px;
  border-radius: ${props => props.theme.buttonBorderRadiusDefault};
  color: ${props => props.theme.colorWhite};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const ButtonStyle = css<ButtonStyleProps>`
  ${BaseButtonStyle}
  position: relative;
  border: ${props =>{
    if (props.isDisabled) return 'none';
    if (props.isGrayBorder) return props.theme.buttonBorderGray;
    return 'none';
  }};
  outline: none;
  box-shadow: ${props => {
    if (props.isDisabled && props.isPrimary) return props.theme.buttonShadowPrimaryDisabled;
    if (props.isDisabled ) return props.theme.buttonShadowDisabled;
    if (props.isPrimary === true) return props.theme.buttonShadowPrimary;
    if (props.isGrayBorder === true) return 'none';
    return props.theme.buttonShadowDefault;
  }};
  background: ${props => {
    if (props.isDisabled && props.isPrimary) return props.theme.buttonBackgroundPrimaryDisabled;
    if (props.isDisabled) return props.theme.colorWhite;
    if (props.isPrimary) return props.theme.buttonBackgroundPrimary;
    return props.theme.colorWhite;
  }};
  color: ${props => {
    if (props.isDisabled && props.isPrimary) return props.theme.colorWhite;
    if (props.isDisabled) return props.theme.colorOpacityGray300;
    if (props.isPrimary) return props.theme.colorWhite;
    return props.theme.colorGray800;
  }};
  cursor: ${props => props.isDisabled ? 'not-allowed' : 'pointer'};

  ${props => props.isDisabled || !props.isPrimary ? '' : css<ButtonStyleProps>`
    ${props => props.hasChildren
      ? css`
        &:hover {
          background: ${props => props.theme.buttonBackgroundHover};
        }
      `
      : css<ButtonStyleProps>`
        &::before {
          ${BaseButtonStyle}
          content: '${props => props.content}';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: ${props => props.theme.buttonBackgroundHover};
          opacity: 0;
          transition: opacity 0.3s;
        }

        &:hover::before {
          opacity: 1;
        }
      `
    }
  `}
`;

export default ButtonStyle;
