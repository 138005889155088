import styled, { ThemeContext, css } from "styled-components";
import { useContext } from "react";
import { CalendarEvent, TaskListItem } from "../../models/Task";
import TaskAvatar from "../../images/avatar/task.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faUser, faFolder, faCalendarAlt, faExpandAlt, faCheck, faExclamation, faVideo } from "@fortawesome/pro-regular-svg-icons";
import dayjs from "dayjs";
import Label from "../shared/Label";
import clsx from "clsx";
import { useCrmClient } from "../../contexts/CrmClientContext";
import axios, { AxiosRequestConfig } from "axios";
import * as microsoftTeams from "@microsoft/teams-js";
import Link from "../Link";
import { useMedia } from "react-use";
import HorizontalLine from "../shared/HorizontalLine";

interface TaskItemProps {
  task: TaskListItem;
  handleTaskOpen: (e: React.MouseEvent<HTMLDivElement>, taskId: string) => void;
  afterMicrosoftTeamsMeetingCreated: React.MouseEventHandler<HTMLDivElement>;
  isLastItem?: boolean;
}

const avatarContainerStyle = css`
  .avatarContainer {
    position: relative;
    display: flex;
    .baseStatusIcon {
      height: 22px;
      width: 22px;
      border: 2px solid ${(props) => props.theme.colorWhite};
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      color: ${(props) => props.theme.colorWhite};
      position: absolute;
      right: -2px;
      bottom: -2px;
      &.overdueIcon {
        background: ${(props) => props.theme.colorAlarm500};
        filter: drop-shadow(0px 2px 6px rgba(235, 80, 0, 0.20));
      }
      &.doneIcon {
        background: ${(props) => props.theme.colorSuccess500};
        filter: drop-shadow(0px 2px 6px rgba(43, 205, 134, 0.20));
      }
    }
  }
`;

const toolBarStyle = css`
  .toolBar {
    min-width: 135px;
    margin-left: auto;
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: flex-start;
    color: ${(props) => props.theme.colorPrimary};
    div {
      display: flex;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      cursor: pointer;
      flex-grow: 1;
    }
  }
`;

const LastTaskItem = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colorGray400};
  line-height: 20px;
`;
const StyledTaskItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 16px 8px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props=> props.theme.colorGray200};
  }
  ${avatarContainerStyle}
  .taskInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    .content {
      font-size: 16px;
      color: ${(props) => props.theme.colorGray900};
      font-weight: bold;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 690px;
    }
    .subtopic {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;
      .dateInfo,
      .assigneeInfo,
      .typeInfo {
        display: flex;
        align-items: center;
        gap: 4px;
        color: ${(props) => props.theme.colorGray600};
      }
      .overdueInfo {
        color: ${(props) => props.theme.colorAlarm500};
        font-size: 14px;
        font-weight: bold;
      }
      .typeInfo {
        gap: 8px;
        svg {
          margin-top: 3px;
        }
        &:last-of-type(span) {
          padding-left: 4px;
        }
      }
    }
    .meetingInfo {
      svg {
        color: ${(props) => props.theme.colorGray600};
      }
      .meetingLink {
        color: ${(props) => props.theme.colorGray800};
        text-decoration: underline;
        &:first-of-type {
          margin-left: 4px;
        }
      }
    }
  }
  ${toolBarStyle}
`;

const parseAuthenticationResult = (result: string = '') => {
  let isSuccessful: boolean = false;
  let error: string = '';
  
  result.split('_vitalcrm-result_')
    .forEach((parameter: string) => {
      const pair = parameter.split('_vitalcrm-parameter_');
      switch (pair[0]) {
        case 'isSuccessful':
          isSuccessful = pair[1] === 'true';
          break;
        default:
          error = pair[1];
          break;
      }
    });
  
  return { isSuccessful, error };
}

const TaskItem = (props: TaskItemProps) => {
  const { task, handleTaskOpen, afterMicrosoftTeamsMeetingCreated: afterMicrosoftTeamsEventCreated, isLastItem } = props;
  const crmClient = useCrmClient();
  const themeContext = useContext(ThemeContext);
  const isMobile = useMedia(`(${themeContext?.devices.mobile.mediaQuery})`);

  if (Object.keys(task).length === 0) return <></>;

  const nowDate = dayjs().format();
  const isDateBefore = dayjs(task.dueDate).isBefore(nowDate);
  const statusIcon = task.currentStatus === "Done" ? "doneIcon" : isDateBefore === true ? "overdueIcon" : "";
  const avatarStatusClass = statusIcon === "" ? "" : clsx("baseStatusIcon", statusIcon);

  const onCreateMicrosoftTeamsMeetingClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, task: TaskListItem) => {
    const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
      successCallback: (result: any) => {
        crmClient
          ?.post(
            `tasks/${task.id}/msteams-events`,
            {
              subject: task.content,
              beginDate: task.beginDate,
              dueDate: task.dueDate,
              location: task.location,
            },
            {
              headers: {
                "x-mst-at": result,
              },
            } as AxiosRequestConfig
          )
          .then(
            (response) => {
              afterMicrosoftTeamsEventCreated(event);
            },
            (error) => {
              if (axios.isAxiosError(error)) {
                switch (error.response?.status) {
                  case 400:
                    const { errorCode } = error.response.data as { errorCode: number; messages: string[] };
                    if (errorCode === 71400000) {
                      microsoftTeams.authentication.authenticate({
                        url: `${process.env.REACT_APP_CRM_TEAMS_API_BASE_URL}/authentication/login?scheme=azuread&oauthRedirectMethod={oauthRedirectMethod}&authId={authId}`,
                        isExternal: true,
                        width: 1200,
                        height: 800,
                        successCallback: (result: string) => {
                          const { isSuccessful, error } = parseAuthenticationResult(result);
                          if (isSuccessful) {
                            onCreateMicrosoftTeamsMeetingClick(event, task);
                          } else {
                            console.error(`Azure AD login failed, error: ${error}.`);
                          }
                        },
                        failureCallback: (result: string) => {
                          const { error } = parseAuthenticationResult(result);
                          try {
                            console.error(`Azure AD login failed, error: ${error || result}.`);
                          } catch {
                            console.error(`Azure AD login failed, result: ${result}.`);
                          }
                        },
                      } as microsoftTeams.authentication.AuthenticateParameters);
                    }
                    break;
                }
              }
            }
          );
      },
      failureCallback: (error: any) => {
        console.error(`fail, error: ${JSON.stringify(error)}`);
      },
    };
    microsoftTeams.initialize(() => {
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
    });
  };

  return (
    <>
      {task.id === "" && <LastTaskItem className="lastTaskItem" style={{paddingTop: '8px'}}>已經到最底囉！</LastTaskItem>}

      {task.id !== "" && isMobile === false && (
        <StyledTaskItem>
          <div className="avatarContainer">
            <div style={{ position: "relative" }}>
              <img src={TaskAvatar} alt="TaskAvatar" height="44px" />
              <div className={avatarStatusClass}>
                {statusIcon !== null && statusIcon === "doneIcon" && <FontAwesomeIcon icon={faCheck} />}
                {statusIcon !== null && statusIcon === "overdueIcon" && <FontAwesomeIcon icon={faExclamation} />}
              </div>
            </div>
          </div>
          <div className="taskInfo">
            <div className="content">{task.content}</div>
            <div className="subtopic">
              <div className="dateInfo">
                <FontAwesomeIcon icon={faClock} />
                {dayjs(task.beginDate).format("MM/DD HH:mm") !== "Invalid Date" && dayjs(task.beginDate).format("MM/DD HH:mm")} - {dayjs(task.dueDate).format("MM/DD HH:mm")}
              </div>
              {statusIcon === "overdueIcon" && <div className="overdueInfo">逾期未完成</div>}
              {task.latestAssigneeName && (
                <div className="assigneeInfo">
                  <FontAwesomeIcon icon={faUser} />
                  <span>{task.latestAssigneeName}</span>
                </div>
              )}
              {task.primaryTaskType.id !== null && (
                <div className="typeInfo">
                  <FontAwesomeIcon icon={faFolder} />
                  <Label isCustom={true} color={task.primaryTaskType.frontColor} bgColor={task.primaryTaskType.backgroundColor}>
                    {task.primaryTaskType.name}
                  </Label>
                  <Label isCustom={true} color={task.secondaryTaskType.frontColor} bgColor={task.secondaryTaskType.backgroundColor}>
                    {task.secondaryTaskType.name}
                  </Label>
                </div>
              )}
            </div>
            {task.teamsCalendarItems?.length > 0 && (
              <div className="meetingInfo">
                <FontAwesomeIcon icon={faVideo} />
                {task.teamsCalendarItems.map((calendarEvent: CalendarEvent, index: number) => {
                  return (
                    <span key={calendarEvent.id}>
                      {index === 0 ? <></> : <span>、</span>}
                      <Link target="_blank" href={calendarEvent.onlineMeetingJoinUrl} className="meetingLink">
                        {calendarEvent.subject}
                      </Link>
                    </span>
                  );
                })}
              </div>
            )}
          </div>
          <div className="toolBar">
            <div onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => onCreateMicrosoftTeamsMeetingClick(event, task)}>
              <FontAwesomeIcon icon={faCalendarAlt} />
              <span>建立會議</span>
            </div>
            <div onClick={(e) => handleTaskOpen(e, task.id)}>
              <FontAwesomeIcon icon={faExpandAlt} />
              <span>開啟</span>
            </div>
          </div>
        </StyledTaskItem>
      )}

      {task.id !== "" && isMobile === true && (
        <>
          <StyledMobileTaskItem>
            <div className="header">
              <div className="avatarContainer">
                <img src={TaskAvatar} alt="TaskAvatar" height="44px" />
                <div className={avatarStatusClass}>
                  {statusIcon !== null && statusIcon === "doneIcon" && <FontAwesomeIcon icon={faCheck} />}
                  {statusIcon !== null && statusIcon === "overdueIcon" && <FontAwesomeIcon icon={faExclamation} />}
                </div>
              </div>
              <div className="toolBar">
                <div onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => onCreateMicrosoftTeamsMeetingClick(event, task)}>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  <span>建立會議</span>
                </div>
                <div onClick={(e) => handleTaskOpen(e, task.id)}>
                  <FontAwesomeIcon icon={faExpandAlt} />
                  <span>開啟</span>
                </div>
              </div>
            </div>
            <div className="container">
              {task.content && <div className="content">{task.content}</div>}
              <div className="subtopic">
                <div className="dateInfo">
                  <FontAwesomeIcon icon={faClock} />
                  {dayjs(task.beginDate).format("MM/DD HH:mm") !== "Invalid Date" && dayjs(task.beginDate).format("MM/DD HH:mm")} - {dayjs(task.dueDate).format("MM/DD HH:mm")}
                  {statusIcon === "overdueIcon" && <div className="overdueText">逾期未完成</div>}
                </div>
                {task.latestAssigneeName && (
                  <div className="assigneeInfo">
                    <FontAwesomeIcon icon={faUser} />
                    <span>{task.latestAssigneeName}</span>
                  </div>
                )}
                {task.primaryTaskType.id !== null && (
                  <div className="typeInfo">
                    <FontAwesomeIcon icon={faFolder} />
                    <Label isCustom={true} color={task.primaryTaskType.frontColor} bgColor={task.primaryTaskType.backgroundColor}>
                      {task.primaryTaskType.name}
                    </Label>
                    <Label isCustom={true} color={task.secondaryTaskType.frontColor} bgColor={task.secondaryTaskType.backgroundColor}>
                      {task.secondaryTaskType.name}
                    </Label>
                  </div>
                )}
                {task.teamsCalendarItems?.length > 0 && (
                  <div className="meetingInfo">
                    <FontAwesomeIcon icon={faVideo} />
                    {task.teamsCalendarItems.map((calendarEvent: CalendarEvent, index: number) => {
                      return (
                        <span key={calendarEvent.id}>
                          {index === 0 ? <></> : <span>、</span>}
                          <Link target="_blank" href={calendarEvent.onlineMeetingJoinUrl} className="meetingLink">
                            {calendarEvent.subject}
                          </Link>
                        </span>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </StyledMobileTaskItem>
          {isLastItem !== true && <HorizontalLine />}
        </>
      )}
    </>
  );
};

const StyledMobileTaskItem = styled.div`
  padding: 16px;
  .header {
    display: flex;
    margin-bottom: 12px;
    ${avatarContainerStyle}
    ${toolBarStyle}
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: ${(props) => props.theme.colorGray600};
    font-size: 14px;
    .content {
      font-size: 16px;
      color: ${(props) => props.theme.colorGray900};
      line-height: 24px;
      margin-bottom: 4px;
    }
    .subtopic {
      display: flex;
      flex-direction: column;
      gap: 4px;
      .dateInfo,
      .assigneeInfo,
      .typeInfo,
      .meetingInfo {
        display: flex;
        align-items: center;
        svg {
          margin-right: 4px;
        }
        .overdueText {
          margin-left: 8px;
          color: ${(props) => props.theme.colorAlarm500};
          font-weight: 500;
        }
      }
      .typeInfo > span:not(:last-child) {
        margin-right: 8px;
      }
      .meetingInfo {
        svg {
          color: ${(props) => props.theme.colorGray600};
        }
        .meetingLink {
          color: ${(props) => props.theme.colorGray800};
          text-decoration: underline;
          &:first-of-type {
            margin-left: 4px;
          }
        }
      }
    }
  }
`;

export default TaskItem;
