import {
  IntlProvider,
  LocalizationProvider,
  loadMessages,
  load,
} from "@progress/kendo-react-intl";
// CLDR Data
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
// zh-Hant
import numbers from 'cldr-numbers-full/main/zh-Hant/numbers.json';
import currencies from 'cldr-numbers-full/main/zh-Hant/currencies.json';
import calendar from 'cldr-dates-full/main/zh-Hant/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/zh-Hant/dateFields.json';

import CrmClientContextProvider from "./contexts/CrmClientContext";
import styled, { ThemeProvider, StyleSheetManager } from "styled-components";
import { defaultTheme } from "./styles/themes/defaultTheme";
import { GlobalStyle } from "./styles";
import twMessages from "./lang/zh-TW/zh-TW.json";
import { PageRoutes } from "./routes/PageRoutes";
import { LoginUserProvider } from "./contexts/LoginUserContext";
import { ComponentType } from "react";


type ProviderArray = [ComponentType<any>, object?];

const CrmApp = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize};
  background-color: ${(props) => props.theme.colorBg};

  button,
  button::before {
    font-family: ${(props) => props.theme.fontFamily};
  }
`;

function App() {
  load(
    likelySubtags,
    weekData,
    currencyData,
    numbers,
    currencies,
    calendar,
    dateFields
  );
  loadMessages(twMessages, "zh-Hant");

  const InitialApp = () => (
    <CrmApp>
      <GlobalStyle />
      <PageRoutes />
    </CrmApp>
  )

  const providers: ProviderArray[] = [
    [StyleSheetManager, { shouldForwardProp: () => true }],
    [CrmClientContextProvider],
    [ThemeProvider, { theme: defaultTheme }],
    [LocalizationProvider, { language: "zh-Hant" }],
    [IntlProvider, { locale: "zh-Hant" }],
    [LoginUserProvider]
  ];



  return providers.reduceRight((children, [Provider, props = {}]) => {
    return <Provider {...props}>{children}</Provider>;
  }, <InitialApp /> );
}

export default App;