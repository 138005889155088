import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import styled from "styled-components";


const StyledCheckbox = styled.div`
  span {
    font-size: ${(props) => props.theme.fontSize};
    color: ${(props) => props.theme.colorGray700};
    cursor: pointer;
  }
`;

export const EditorItemCheckbox = (fieldRenderProps: FieldRenderProps) => {
  const {
    name,
    label,
    labelStyle,
    wrapperStyle,
    validationMessage,
    ...others
  } = fieldRenderProps;

  return (
    <FieldWrapper>
      <StyledCheckbox style={wrapperStyle}>
        {label && (
          <Label>
            <Checkbox name={name} {...others} />
            <span style={labelStyle}>{label}</span>
          </Label>
        )}
      </StyledCheckbox>
    </FieldWrapper>
  );
};
