import { DropDownListFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import { FieldRenderProps } from "@progress/kendo-react-form";
import React, { useRef, useState, useCallback } from "react";
import { useCrmClient } from "../contexts/CrmClientContext";
import { Company } from "../models/Company";
import { EditorItemDropdownList } from "./EditorItemDropdownList";
import { useTranslation } from 'react-i18next';

const NoDataRender = (props: any) => {
  const { t } = useTranslation();
  return (
    <div>
      <div style={{ fontSize: "1em" }}>{t("Home.Index.NoData")}</div>
      <div style={{ fontSize: "1em" }}>{t("Com.InputCustomKeyQuery", { 0: t("CaseMemberDto.CompanyName", { "User.Custom.KeyWord.Company": t("User.Custom.KeyWord.Company")}) })}</div>
      {props.children}
    </div>
  );
}

const apiDelay = 700;

export const EditorItemCompanyDropdownList = (props: FieldRenderProps) => {
  const { t } = useTranslation();
  const apiDelayTimerRef = useRef<any>();
  const crmClient = useCrmClient();
  const [dataSource, setDataSource] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onFilterChange = useCallback((event: DropDownListFilterChangeEvent) => {
    if (isLoading || !event.filter.value) {
      return;
    }
    setDataSource([{ id: "", name: t("Com.Searching") }]);

    clearTimeout(apiDelayTimerRef.current);
    apiDelayTimerRef.current = setTimeout(() => {
      crmClient!.get<Company[]>('companies/autocomplete', {
        params: {
          name: event.filter.value,
        },
        transformRequest: (data) => {
          setIsLoading(true);
          return data;
        }
      }).then((response) => {
        setDataSource(response.data);
      }, (error) => {
        console.error('company dropdown list, search companies, failed, error:', error);
      }).finally(() => {
        setIsLoading(false);
      });
    }, apiDelay);
  }, [isLoading, t, crmClient]);

  const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
    return React.cloneElement(element, { ...element.props }, <NoDataRender />);
  }

  return (
    <EditorItemDropdownList
      data={dataSource}
      dataItemKey="id"
      textField="name"
      filterable={true}
      filterChange={onFilterChange}
      loading={isLoading}
      listNoDataRender={listNoDataRender}
      {...props}
    />
  );
}