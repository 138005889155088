import { useState } from "react";
import styled, { css } from "styled-components";

interface CircleCheckboxProps {
  hoverBorderColor?: string;
  hoverCheckColor?: string;
  checkedState?: boolean;
  checkedBgColor?: string;
  checkedBorderColor?: string;
  checkedCheckColor?: string;
  id?: string;
  onChecked?: (arg: string) => void;
  onUnchecked?: (arg: string) => void;
  style?: React.CSSProperties;
  size?: string;
}

const afterStyle = css<CircleCheckboxProps>`
  border-width: medium medium ${(props) => (props.size ? parseInt(props.size) * 0.12 : 3)}px ${(props) => (props.size ? parseInt(props.size) * 0.12 : 3)}px;
  border-style: none none solid solid;
  content: "";
  position: absolute;
  width: ${(props) => (props.size ? parseInt(props.size) * 0.3 : 7)}px;
  height: ${(props) => (props.size ? parseInt(props.size) * 0.12 : 3)}px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  opacity: 0.3;
`;

const CircleCheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

const StyledCheckbox = styled.div<CircleCheckboxProps>`
  position: relative;
  box-sizing: border-box;
  height: ${(props) => props.size || "24px"};
  width: ${(props) => props.size || "24px"};
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.colorGray400};
  cursor: pointer;
  &:hover {
    border: 1px solid
      ${(props) => props.hoverBorderColor ?? props.theme.colorGray500};
    &::after {
      ${afterStyle}
      color: ${(props) => props.hoverCheckColor ?? props.theme.colorGray400};
    }
  }
  &.checked {
    background-color: ${(props) =>
    props.checkedBgColor ?? props.theme.colorSuccess500};
    border-color: ${(props) =>
    props.checkedCheckColor ?? props.theme.colorSuccess500};
    &::after {
      ${afterStyle}
      opacity: 1;
      color: ${(props) => props.checkedCheckColor ?? props.theme.colorWhite};
    }
  }
`;

export const CircleCheckbox = ({
  checkedState = false,
  onChecked: handleChecked,
  onUnchecked: handleUnchecked,
  style,
  id,
  size,
  ...others
}: CircleCheckboxProps) => {
  const [checked, setChecked] = useState<boolean>(checkedState);

  const handleChange = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    if (newChecked) {
      // 這寫法不好，變成一定要傳id才能用，再研究看看有沒有更好的寫法
      handleChecked && id && handleChecked(id);
    } else {
      handleUnchecked && id && handleUnchecked(id);
    }
  };

  return (
    <CircleCheckboxContainer>
      <HiddenCheckbox checked={checked} onChange={handleChange} />
      <StyledCheckbox
        className={checked ? "checked" : ""}
        style={style}
        size={size}
        {...others}
      />
    </CircleCheckboxContainer>
  );
};