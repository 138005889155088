import styled from "styled-components";

export interface LabelProps {
  styleId?: string;
  fontSize?: string;
  isCustom?: boolean;
  color?: string;
  bgColor?: string;
}

interface ColorMap {
  [id: string]: {
    FrontColor: string;
    BackgroundColor: string;
  }
}

const colorMap: ColorMap = {
  "a25fa25f4c2e43878980a74b16bd0805": { "FrontColor": "#07A271", "BackgroundColor": "#DEEFE6" },
  "c84b8d8c927447d49cfff780d906e24b": { "FrontColor": "#0096F0", "BackgroundColor": "#DDF1FC" },
  "ea5f64156c1a4b51a550db938ea60f95": { "FrontColor": "#005EAE", "BackgroundColor": "#D5E2F3" },
  "0be7c90f223d452abe010c9bf65bc718": { "FrontColor": "#325078", "BackgroundColor": "#E2EDF4" },
  "e3e6bc9fc7bd4a949357b5685682d065": { "FrontColor": "#4B4B4B", "BackgroundColor": "#EAEAEA" },
  "6ed3b267995a4eb293b9b3742cc35977": { "FrontColor": "#9D5EA2", "BackgroundColor": "#EEE4F0" },
  "1bbae1d3cf9143c9ad2753039274d3df": { "FrontColor": "#DEEFE6", "BackgroundColor": "#07A271" },
  "46516bd4b65b4a619318940cd6d801a6": { "FrontColor": "#DDF1FC", "BackgroundColor": "#0096F0" },
  "0f01a8ae33434dbba9abbf78f31f51c7": { "FrontColor": "#D5E2F3", "BackgroundColor": "#005EAE" },
  "6fefd83015324c5cb527d74597c54c3e": { "FrontColor": "#E2EDF4", "BackgroundColor": "#325078" },
  "5075cdd49da947ccbf847a477326a43d": { "FrontColor": "#EAEAEA", "BackgroundColor": "#4B4B4B" },
  "de7f02a2b48e4c029bbabb9e6b8f79a8": { "FrontColor": "#EEE4F0", "BackgroundColor": "#9D5EA2" },
  "20a470b848a04efd84fd2be02755bae8": { "FrontColor": "#97C100", "BackgroundColor": "#E3FFB7" },
  "1788ef90725c483a841defcc1d2d81f0": { "FrontColor": "#996633", "BackgroundColor": "#ECD9C6" },
  "91c6da272a784a4c9abda6a60cf3f648": { "FrontColor": "#F29100", "BackgroundColor": "#FFFFB7" },
  "57aa2ef72694403a952727b9fdb3af2d": { "FrontColor": "#EB5C1B", "BackgroundColor": "#FBE8BB" },
  "bb851e1338a340f49c085a5e4f0aa048": { "FrontColor": "#C82209", "BackgroundColor": "#FDEAE2" },
  "fd0cde836ab24afd9b724497e996e5ee": { "FrontColor": "#E73785", "BackgroundColor": "#FBE6EF" },
  "dbdf2cdfa0cf4e81b5228fb20e430432": { "FrontColor": "#E3FFB7", "BackgroundColor": "#97C100" },
  "fb85ccc1da154659a74fc42e5cfda472": { "FrontColor": "#ECD9C6", "BackgroundColor": "#996633" },
  "6a57ca534dba45d8b509e14df88b6e4d": { "FrontColor": "#FFFFB7", "BackgroundColor": "#F29100" },
  "2a6ce404488943f78fbe5fdd6c7db640": { "FrontColor": "#FBE8BB", "BackgroundColor": "#EB5C1B" },
  "95f2ec2b4645427583dd318238fcca71": { "FrontColor": "#FDEAE2", "BackgroundColor": "#C82209" },
  "a91ebe3c7474498cadfad76d01e6928b": { "FrontColor": "#FBE6EF", "BackgroundColor": "#E73785" },
  "plus": { "FrontColor": "#63636F", "BackgroundColor": "#FFFFFF" }
}

const Label = styled.span<LabelProps>`
  font-size: 12px;
  color: ${props => {
    if (props.isCustom) return props.color;
    if (!props.styleId) return props.theme.colorWhite;
    return colorMap[props.styleId].FrontColor;
  }};
  background-color: ${props => {
    if (props.isCustom) return props.bgColor;
    if (!props.styleId) return props.theme.colorPrimary;
    return colorMap[props.styleId].BackgroundColor;
  }};
  line-height: 16px;
  border-radius: 10px;
  padding: 0px 10px;
`;

export default Label;
