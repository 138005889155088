import { useState, useRef, useEffect, useContext, useMemo, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import styled, { ThemeContext, css } from "styled-components";
import TaskItem from "../../components/task/TaskItem";
import { useMedia } from "react-use";
import { TaskListItem, Task as ITask, TaskOwner, TaskStatus as ITaskStatus, TaskType, CalendarEvent } from "../../models/Task";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faFilter, faSyncAlt, faPencilAlt, faCopy, faTrashAlt, faMapMarkedAlt, faCalendarAlt, faClock, faUser } from "@fortawesome/pro-regular-svg-icons";
import IconButton from "../../components/shared/IconButton";
import Button from "../../components/shared/Button";
import { IscrollStatus } from "../customer/CustomerShow";
import { Customer as ICustomer } from "../../models/Customer";
import { Skeleton } from "@progress/kendo-react-indicators";
import { useCrmClient } from "../../contexts/CrmClientContext";
import defaultCustomerPicture from "../../images/user-profile-picture-default.gif";
import CustomerPictureContent from "../customer/CustomerPictureContent";
import dayjs from "dayjs";
import Link from "../../components/Link";
import TaskFilter from "./TaskFIlter";
import axios, { AxiosRequestConfig } from "axios";
import * as microsoftTeams from "@microsoft/teams-js";
import teamsCalendarImage from "../../images/teams-calendar.png";
import { CircleCheckbox } from "../../components/shared/CircleCheckbox";
import TaskForm from "./TaskForm";
import { Account } from "../../models/DataPrivilege";
import Label from "../../components/shared/Label";

interface taskListProps {
  customer: ICustomer;
  scrollStatus: IscrollStatus;
  setScrollStatus: React.Dispatch<React.SetStateAction<IscrollStatus>>;
}
export interface IFilterCond {
  taskOwner: TaskOwner;
  taskStatus: ITaskStatusData;
  primaryTaskType: TaskType;
  secondaryTaskType: TaskType;
}
interface ITaskStatusData {
  id: ITaskStatus;
  text: string;
}

export interface ICustomerTaskCond {
  customerId: CustomerIdType;
  currentStatus: ITaskStatus;
  assigneeIds: string[];
  teamIds: string[];
  primaryTypeIds: string[];
  secondaryTypeIds: string[];
  orderBy: "DueDate";
  sortDirection: "Descending";
  page: number;
}

// 新增 / 修改 task 的資料
export interface TaskFormDataType {
  id?: string;
  isAllDay: boolean;
  startDate: Date | undefined;
  endDate: Date | undefined;
  content: string;
  primaryTaskType: TaskType;
  secondaryTaskType: TaskType;
  latestAssignee: { id: string; name: string };
  location: string;
}

export interface IFormState {
  visible: boolean;
  formAction: "createTask" | "updateTask";
  isTriggerFromTaskDetail: boolean;
}
type CustomerIdType = string | undefined;

const ToolBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .rightTool {
    font-size: 14px;
    font-weight: 400;
    margin-left: auto;
  }
  .filterBtn {
    position: relative;
  }
`;

export const StyledDialog = styled(Dialog) <StyledDialogProps>`
  .k-dialog {
    border-radius: ${(props) => props.theme.windowBorderRadius};
    box-shadow: ${(props) => props.theme.windowBoxShadow};
    width: ${(props) => (props.isMobile ? "calc(100vw - 24px)" : "")};
    max-height: ${(props) => (props.isMobile ? "calc(100vh - 24px)" : "")};
    .k-dialog-titlebar {
      font-size: 18px;
      font-weight: 500;
      border-radius: 4px 4px 0px 0px;
      background: ${(props) => props.theme.colorWhite};
      color: ${(props) => props.theme.colorOpacityGray800};
    }
    .k-dialog-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      .headContent {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        padding: ${props => props.isMobile ? "16px 16px 0 16px" : ""};
        .titleContainer {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .content {
            color: ${(props) => props.theme.colorGray800};
            font-size: 20px;
            line-height: 30px;
            font-weight: bold;
            max-width: 550px;
          }
          .typeInfo {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
          }
        }
        .actionsContent {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 6px;
          border-radius: 4px;
          background-color: ${(props) => props.theme.colorGray200};
          color: ${(props) => props.theme.colorGray700};
          font-size: 18px;
          margin-left: auto;
          svg {
            padding: 4px;
            cursor: pointer;
          }
        }
      }
      .mobileTitleContent {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 16px 16px 0 16px;
        .content {
          color: ${(props) => props.theme.colorGray800};
          font-size: 20px;
        }
        .typeInfo {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
        }
      }
      .detailContent {
        display: flex;
        flex-direction: column;
        margin: 32px 0px 23px;
        margin-left: ${props => props.isMobile && "0"};
        margin-right: ${props => props.isMobile && "0"};
      }
      .footerContent {
        font-size: 12px;
        color: ${(props) => props.theme.colorGray600};
        line-height: 14px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 0px 16px 16px 16px;
        .createrInfo {
          display: flex;
          justify-content: flex-end;
          .createUser {
          }
          .createDate {
          }
        }
        .modifyInfo {
          display: flex;
          justify-content: flex-end;
          .modifyUser {
          }
          .modifyDate {
          }
        }
      }
      .dialogBody {
        max-height: ${(props) => (props.isMobile ? "" : "417px")};
        overflow-y: auto;
      }
      .fieldContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: ${(props) => props.isMobile ? "24px 24px 0 24px" : "24px 44px 0 44px"};
        &:first-of-type {
          padding-top: ${(props) => (props.isMobile ? "0px" : "24px")};
        }
        &:last-of-type {
          padding-bottom: 24px;
        }
        .fieldTitle {
          display: flex;
          font-size: ${(props) => props.theme.fontSize};
          color: ${(props) => props.theme.colorGray800};
          line-height: 20px;
          &:has(.actionContent) {
            flex-direction: column;
            gap: 8px;
          }
          .actionContent {
            color: ${(props) => props.theme.colorPrimary};
            font-weight: bold;
            cursor: pointer;
          }
          .validationMessage {
            margin-left: 4px;
            color: ${(props) => props.theme.colorAlarm500};
            font-weight: 400;
          }
        }
        .fieldContent {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          gap: 10px;
          font-size: 14px;
          color: ${(props) => props.theme.colorGray800};
          font-weight: bold;
          &:has(.meetingContainer),
          &:has(.datetimeContainer) {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
          }
          .validationMessage {
            margin-left: 4px;
            color: ${(props) => props.theme.colorAlarm500};
            font-weight: 400;
          }
          .latestAssignerInfo {
            font-size: 12px;
            font-weight: 400;
            color: ${(props) => props.theme.colorGray600};
          }
          .meetingContainer {
            display: flex;
            flex-direction: column;
            gap: 12px;
            background-color: ${(props) => props.theme.colorGray100};
            border: 1px solid ${(props) => props.theme.colorGray300};
            border-radius: 4px;
            padding: 12px 16px;
            width: ${props => props.isMobile ? 'calc(100vw - 110px)' : '400px'};
            .headContent {
              color: ${(props) => props.theme.colorGray800};
              font-size: 18px;
            }
            .meetingInfo {
              display: flex;
              flex-direction: column;
              gap: 12px;
              .dateInfo,
              .convenerInfo {
                display: flex;
                align-items: center;
                gap: 10px;
                color: ${(props) => props.theme.colorGray800};
                font-weight: 400;
                .convenerDescription {
                  font-size: 12px;
                  color: ${(props) => props.theme.colorGray600};
                }
              }
            }
            .buttonContent {
              display: flex;
              gap: 8px;
            }
          }
          .datetimeContainer {
            width: 100%;
          }
        }
      }
    }
    .k-actions-stretched {
      justify-content: space-between;
    }
    ${(props) => {
    return props.disableButtonWrapperStyle
      ? css`
          .k-dialog-actions {
            border: none;
          }
        `
      : css`
          .k-actions {
            border-color: ${(props) => props.theme.colorGray200};
            background-color: ${(props) => props.theme.colorGray100};
          }
        `;
  }}
  }
`;
interface StyledDialogProps {
  disableButtonWrapperStyle?: boolean;
  isMobile?: boolean;
}
const TeamsButton = styled.div<{ isPurple?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isPurple === true ? "#FFFFFF" : "##242424")};
  background-color: ${(props) => (props.isPurple === true ? "#5B5FC7" : "#FFFFFF")};
  border: 1px solid ${(props) => (props.isPurple === true ? "#5B5FC7" : "#D1D1D1")};
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  min-width: 96px;
  height: 32px;
  cursor: pointer;
`;

const SkeletonDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 24px;
`;

const parseAuthenticationResult = (result: string = '') => {
  let isSuccessful: boolean = false;
  let error: string = '';
  
  result.split('_vitalcrm-result_')
    .forEach((parameter: string) => {
      const pair = parameter.split('_vitalcrm-parameter_');
      switch (pair[0]) {
        case 'isSuccessful':
          isSuccessful = pair[1] === 'true';
          break;
        default:
          error = pair[1];
          break;
      }
    });
  
  return { isSuccessful, error };
}

const TaskList = (props: taskListProps) => {
  const { customer, scrollStatus, setScrollStatus } = props;
  const { t } = useTranslation();
  const themeContext = useContext(ThemeContext);
  const isMobile = useMedia(`(${themeContext?.devices.mobile.mediaQuery})`);
  const crmClient = useCrmClient();
  const [primaryTaskTypeData, setPrimaryTaskTypeData] = useState<TaskType[] | undefined>(undefined);
  const [secondaryTaskTypeData, setSecondaryTaskTypeData] = useState<TaskType[] | undefined>(undefined);
  const [taskList, setTaskList] = useState<TaskListItem[] | undefined>(undefined);
  const defaultCustomerTaskCond: ICustomerTaskCond = {
    customerId: customer.id,
    currentStatus: "all",
    assigneeIds: [],
    teamIds: [],
    primaryTypeIds: [],
    secondaryTypeIds: [],
    orderBy: "DueDate",
    sortDirection: "Descending",
    page: 1,
  };
  const [customerTaskCond, setCustomerTaskCond] = useState<ICustomerTaskCond>(defaultCustomerTaskCond);

  const [latestAssigneeData, setLatestAssigneeData] = useState<Account[] | undefined>(undefined);
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [filterCond, setFilterCond] = useState<IFilterCond>({
    taskOwner: "Mine",
    taskStatus: { id: "", text: "" },
    primaryTaskType: { id: "", name: "", sortOrder: 0 },
    secondaryTaskType: { id: "", name: "", sortOrder: 0 },
  });
  const [taskFormState, setTaskFormState] = useState<IFormState>({
    visible: false,
    formAction: "createTask",
    isTriggerFromTaskDetail: false,
  });
  const emptyTaskFormData: TaskFormDataType = {
    id: "",
    content: "",
    isAllDay: false,
    latestAssignee: {
      id: "",
      name: "",
    },
    location: "",
    primaryTaskType: { id: "", name: "", sortOrder: 0 },
    secondaryTaskType: { id: "", name: "", sortOrder: 0 },
    startDate: undefined,
    endDate: undefined,
  };
  const [taskFormData, setTaskFormData] = useState<TaskFormDataType>(emptyTaskFormData);
  const [taskDetailVisible, setTaskDetailVisible] = useState<boolean>(false);
  const emptyTask: ITask = {
    id: "",
    isAllDay: false,
    reminder: {
      number: 0,
      unit: "Minute",
      isRemind: false,
    },
    reminderContacts: [""],
    overdueContacts: [""],
    completeContacts: [""],
    booked: false,
    subType: "Task",
    content: "",
    latestAssignee: "",
    latestAssigneeName: "",
    latestAssigner: "",
    latestAssignerName: "",
    beginDate: undefined,
    dueDate: undefined,
    currentStatus: "Initial",
    primaryTaskType: {
      backgroundColor: "",
      frontColor: "",
      id: "",
      name: "",
      sortOrder: 0,
      styleId: "",
    },
    secondaryTaskType: {
      backgroundColor: "",
      frontColor: "",
      id: "",
      name: "",
      sortOrder: 0,
      styleId: "",
    },
    createDate: undefined,
    createUser: "",
    modifyDate: undefined,
    modifyUser: "",
    relEntity: {
      additionalInfo: "",
      id: "",
      name: "",
      type: "Customer",
    },
    location: "",
    recurringTaskId: "",
    teamsCalendarItems: [],
  };
  const [openingTask, setOpeningTask] = useState<ITask>(emptyTask);

  const emptyTaskListItem: TaskListItem = useMemo(
    () => ({
      id: "",
      subType: "Task",
      content: "",
      latestAssignee: "",
      latestAssigneeName: "",
      latestAssigner: "",
      latestAssignerName: "",
      beginDate: undefined,
      dueDate: undefined,
      currentStatus: "Initial",
      primaryTaskType: {
        backgroundColor: "",
        frontColor: "",
        id: "",
        name: "",
        sortOrder: 0,
        styleId: "",
      },
      secondaryTaskType: {
        backgroundColor: "",
        frontColor: "",
        id: "",
        name: "",
        sortOrder: 0,
        styleId: "",
      },
      createDate: undefined,
      createUser: "",
      modifyDate: undefined,
      modifyUser: "",
      relEntity: {
        additionalInfo: "",
        id: "",
        name: "",
        type: "Customer",
      },
      location: "",
      recurringTaskId: "",
      teamsCalendarItems: [],
    }),
    []
  );
  const [taskLatestAssigneeAvatarUrl, setTaskLatestAssigneeAvatarUrl] = useState<string>("");

  const [createMicrosoftTeamsMeetingDialogState, setCreateMicrosoftTeamsMeetingDialogState] = useState<{
    visible: boolean;
    taskData: TaskListItem;
  }>({
    visible: false,
    taskData: emptyTaskListItem,
  });
  const [copyTaskState, setCopyTaskState] = useState<boolean>(false);
  const toolbarRef = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLDivElement>(null);

  // get task type
  useEffect(() => {
    if (crmClient === undefined) return;
    // get primary task type
    crmClient
      .get("stores/mine/settings/getEntityType?subtypes=PrimaryTaskType")
      .then((res) => {
        if (res.status === 200) {
          setPrimaryTaskTypeData(res.data);
        }
      })
      .catch((error) => console.error("get primary task type ERROR", error));

    // get secondary task type
    crmClient
      .get("stores/mine/settings/getEntityType?subtypes=SecondaryTaskType")
      .then((res) => {
        if (res.status === 200) {
          setSecondaryTaskTypeData(res.data);
        }
      })
      .catch((error) => console.error("get secondary task type ERROR", error));
  }, [crmClient]);

  // get latestAssigneeData
  useEffect(() => {
    if (crmClient === undefined) return;
    crmClient
      .get("users")
      .then((res) => {
        if (res.status === 200) {
          setLatestAssigneeData(res.data);
        }
      })
      .catch((error) => console.error("get latestAssigneeData ERROR", error));
  }, [crmClient]);

  // get task list
  useEffect(() => {
    if (crmClient === undefined) return;
    if (scrollStatus === "Final" || scrollStatus === "Complete") return;

    if (scrollStatus === "NeedToLoad") {
      setCustomerTaskCond((prevCond) => {
        return {
          ...prevCond,
          page: prevCond.page + 1,
        };
      });
      setScrollStatus("Loading");
      return;
    }

    crmClient
      .post("tasks/list", customerTaskCond)
      .then((res) => {
        if (res.status !== 200) return;
        // get no data && not initial   = final
        if (res.data.length === 0 && taskList !== undefined) {
          setTaskList((prevTaskList) => {
            if (prevTaskList?.length === 0 || !prevTaskList?.some(item => item === emptyTaskListItem)) {
              return prevTaskList?.concat(emptyTaskListItem);
            }
            return prevTaskList;
          });
          setScrollStatus("Final");
          return;
        }

        if (["Idle", "Loading", "Refresh"].includes(scrollStatus)) {
          const taskListData = res.data;
          setTaskList((prevTaskList) => {
            if (prevTaskList === undefined || customerTaskCond.page === 1) return taskListData;
            return prevTaskList.concat(taskListData);
          });
          setScrollStatus("Complete");
        }
      })
      .catch((error) => console.error("get custom task list ERROR", error));
  }, [crmClient, customerTaskCond, scrollStatus, setScrollStatus, taskList, emptyTaskListItem]);

  const handleRefreshTaskList: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    setCustomerTaskCond(defaultCustomerTaskCond);
    setScrollStatus("Idle");
  };

  const handleCreateTaskVisible: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    setTaskFormState((prevState) => {
      return {
        ...prevState,
        visible: !prevState.visible,
        formAction: "createTask",
        isTriggerFromTaskDetail: false,
      };
    });
    setTaskFormData(emptyTaskFormData);
  };

  const handleFilterTaskVisible: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    setFilterVisible(!filterVisible);
  };

  const handleTaskOpen = (e: React.MouseEvent<HTMLDivElement>, taskId: string) => {
    e.preventDefault();
    setTaskDetailVisible(!taskDetailVisible);

    // get task by id
    crmClient
      ?.get<ITask>(`tasks/${taskId}`)
      .then((res) => {
        if (res.status === 200) {
          const foundTask = taskList?.find((task) => task.id === taskId);
          if (foundTask !== undefined) {
            res.data.primaryTaskType = foundTask.primaryTaskType;
            res.data.secondaryTaskType = foundTask.secondaryTaskType;
          }
          const taskData = res.data;
          setOpeningTask(taskData);
          // get latestAssignee avatar
          crmClient
            ?.get(`/users/${taskData.latestAssignee}/ProfilePicture`, { responseType: "blob" })
            .then((res) => {
              if (res.status === 200) {
                const imgFile = new Blob([res.data]);
                const imgUrl = URL.createObjectURL(imgFile);
                setTaskLatestAssigneeAvatarUrl(imgUrl);
              }
            })
            .catch((error) => {
              if (error.response.status === 404) {
                setTaskLatestAssigneeAvatarUrl(defaultCustomerPicture);
                return;
              }
              console.error("get opening task avatar ERROR", error);
            });
        } else {
          console.error("get task -> got error", res);
        }
      })
      .catch((error) => console.error("get task ERROR", error));
  };

  const handleUpdateTaskClick = (taskId: string) => {
    setTaskFormData((prevState) => {
      if (openingTask) {
        const assignee = latestAssigneeData?.find((a) => a.id === openingTask.latestAssignee);
        return {
          ...prevState,
          id: taskId,
          startDate: openingTask.beginDate && new Date(openingTask.beginDate),
          endDate: openingTask.dueDate && new Date(openingTask.dueDate),
          content: openingTask.content,
          primaryTaskType: openingTask.primaryTaskType,
          secondaryTaskType: openingTask.secondaryTaskType,
          latestAssignee: {
            id: assignee?.id || "",
            name: assignee?.name || "",
          },
          location: openingTask.location,
        };
      }
      return { ...prevState };
    });
    // 把新增/修改工作的 dialog 打開
    setTaskFormState((prevState) => {
      return {
        ...prevState,
        visible: true,
        formAction: "updateTask",
        isTriggerFromTaskDetail: true,
      };
    });
    // 關掉工作詳細內容 dialog
    setTaskDetailVisible(!taskDetailVisible);
  };

  const handleCopyTaskClick = () => {
    setTaskFormData({
      id: "",
      content: openingTask.content,
      isAllDay: openingTask.isAllDay,
      latestAssignee: {
        id: openingTask.latestAssignee,
        name: "",
      },
      location: openingTask.location,
      primaryTaskType: openingTask.primaryTaskType,
      secondaryTaskType: openingTask.secondaryTaskType,
      startDate: openingTask.beginDate,
      endDate: openingTask.dueDate,
    });
    setCopyTaskState(true);
  };

  const handleDeleteTaskClick = (taskId: string) => {
    crmClient
      ?.delete(`tasks/${taskId}`)
      .then((response) => {
        if (response.status === 200) {
        } else {
          console.error("delete task -> got error", response);
        }
      })
      .catch((error) => console.error("delete task ERROR", error))
      .finally(() => {
        setTaskDetailVisible(false);
        setScrollStatus("Idle");
        setFilterVisible(false);
      });
  };

  const createTask = useCallback(() => {
    const createTaskData = {
      id: "",
      isAllDay: taskFormData.isAllDay,
      beginDate: taskFormData.startDate,
      dueDate: taskFormData.endDate,
      reminder: {
        unit: "Minute", // Minute, Hour, Day, Week
        number: "0", // 打 0 的話，表示不提醒
      },
      reminderContacts: [],
      overdueContacts: [],
      completeContacts: [],
      content: taskFormData.content,
      relEntity: {
        type: "Customer",
        id: customer.id,
        name: customer.name,
      },
      booked: false,
      primaryTaskType: {
        id: taskFormData.primaryTaskType.id,
        name: taskFormData.primaryTaskType.name,
      },
      secondaryTaskType: {
        id: taskFormData.secondaryTaskType.id,
        name: taskFormData.secondaryTaskType.name,
      },
      latestAssignee: taskFormData.latestAssignee?.id,
      latestAssigner: "",
      location: taskFormData.location,
    };

    crmClient
      ?.post<TaskListItem>("tasks", createTaskData)
      .then((response) => {
        setCreateMicrosoftTeamsMeetingDialogState({
          visible: true,
          taskData: response.data,
        });
      })
      .catch((error) => {
        setScrollStatus("Idle");
        setFilterVisible(false);
        setTaskDetailVisible(false);
      })
      .finally(() => {
        setTaskFormState((prevState) => {
          return {
            ...prevState,
            visible: false,
            isTriggerFromTaskDetail: false,
          };
        });
      });
  }, [taskFormData, customer, crmClient, setScrollStatus]);

  const updateTask = () => {
    const updateTaskData = {
      id: taskFormData.id,
      isAllDay: taskFormData.isAllDay,
      beginDate: taskFormData.startDate,
      dueDate: taskFormData.endDate,
      reminder: {
        unit: "Minute", // Minute, Hour, Day, Week
        number: "0", // 打 0 的話，表示不提醒
      },
      reminderContacts: [],
      overdueContacts: [],
      completeContacts: [],
      content: taskFormData.content,
      relEntity: {
        type: "Customer",
        id: customer.id,
        name: customer.name,
      },
      booked: false,
      primaryTaskType: {
        id: taskFormData.primaryTaskType?.id,
        name: taskFormData.primaryTaskType?.name,
      },
      secondaryTaskType: {
        id: taskFormData.secondaryTaskType?.id,
        name: taskFormData.secondaryTaskType?.name,
      },
      latestAssignee: taskFormData.latestAssignee?.id,
      latestAssigner: "",
      location: taskFormData.location,
    };



    crmClient
      ?.put(`tasks/${taskFormData.id}`, updateTaskData)
      .then((response) => {
        // 如果是從工作詳細內容頁面進來的，就返回工作詳細內容，並且更新工作詳細內容頁面的資料
        if (taskFormState.isTriggerFromTaskDetail === true) {
          setTaskDetailVisible(true);
          setOpeningTask((prevState) => ({
            ...prevState,
            isAllDay: taskFormData.isAllDay,
            beginDate: taskFormData.startDate,
            dueDate: taskFormData.endDate,
            content: taskFormData.content,
            relEntity: {
              ...prevState.relEntity,
              id: customer.id,
              name: customer.name,
            },
            primaryTaskType: {
              ...prevState.primaryTaskType,
              id: taskFormData.primaryTaskType?.id,
              name: taskFormData.primaryTaskType?.name,
            },
            secondaryTaskType: {
              ...prevState.secondaryTaskType,
              id: taskFormData.secondaryTaskType?.id,
              name: taskFormData.secondaryTaskType?.name,
            },
            latestAssignee: taskFormData.latestAssignee?.id,
            location: taskFormData.location,
          }));
        } else {
          setTaskDetailVisible(false);
          setScrollStatus("Idle");
        }
        setTaskFormState((prevState) => {
          return {
            ...prevState,
            visible: false,
            isTriggerFromTaskDetail: false,
          };
        });
      })
      .catch((error) => console.error("update task ERROR", error));
  };

  const onCreateMicrosoftTeamsMeetingOKClick: React.MouseEventHandler<HTMLButtonElement> = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
      successCallback: (result: any) => {
        crmClient
          ?.post<CalendarEvent>(
            `tasks/${createMicrosoftTeamsMeetingDialogState.taskData.id}/msteams-events`,
            {
              subject: createMicrosoftTeamsMeetingDialogState.taskData.content,
              beginDate: createMicrosoftTeamsMeetingDialogState.taskData.beginDate,
              dueDate: createMicrosoftTeamsMeetingDialogState.taskData.dueDate,
              location: createMicrosoftTeamsMeetingDialogState.taskData.location,
            },
            {
              headers: {
                "x-mst-at": result,
              },
            } as AxiosRequestConfig
          )
          .then(
            (response) => {
              setCreateMicrosoftTeamsMeetingDialogState({
                visible: false,
                taskData: emptyTaskListItem,
              });
              setScrollStatus("Idle");
              setFilterVisible(false);
              setTaskDetailVisible(false);
            },
            (error) => {
              if (axios.isAxiosError(error)) {
                switch (error.response?.status) {
                  case 400:
                    const { errorCode } = error.response.data as { errorCode: number; messages: string[] };
                    if (errorCode === 71400000) {
                      microsoftTeams.authentication.authenticate({
                        url: `${process.env.REACT_APP_CRM_TEAMS_API_BASE_URL}/authentication/login?scheme=azuread&oauthRedirectMethod={oauthRedirectMethod}&authId={authId}`,
                        isExternal: true,
                        width: 1200,
                        height: 800,
                        successCallback: (result: string) => {
                          const { isSuccessful, error } = parseAuthenticationResult(result);
                          if (isSuccessful) {
                            onCreateMicrosoftTeamsMeetingOKClick(event);
                          } else {
                            console.error(`Azure AD login failed, error: ${error}.`);
                          }
                        },
                        failureCallback: (result: string) => {
                          const { error } = parseAuthenticationResult(result);
                          try {
                            console.error(`Azure AD login failed, error: ${error || result}.`);
                          } catch {
                            console.error(`Azure AD login failed, result: ${result}.`);
                          }
                        },
                      } as microsoftTeams.authentication.AuthenticateParameters);
                    }
                    break;
                }
              }
            }
          );
      },
      failureCallback: (error: any) => {
        console.error(`fail, error: ${JSON.stringify(error)}`);
      },
    };
    microsoftTeams.initialize(() => {
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
    });
  };

  const onCreateMicrosoftTeamsMeetingCancelClick: React.MouseEventHandler<HTMLButtonElement> = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setCreateMicrosoftTeamsMeetingDialogState({
      visible: false,
      taskData: emptyTaskListItem,
    });
    setScrollStatus("Idle");
    setFilterVisible(false);
    setTaskDetailVisible(false);
  };

  const onCreateMicrosoftTeamsMeetingClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, task: ITask) => {
    const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
      successCallback: (result: any) => {
        crmClient
          ?.post<CalendarEvent>(
            `tasks/${task.id}/msteams-events`,
            {
              subject: task.content,
              beginDate: task.beginDate,
              dueDate: task.dueDate,
              location: task.location,
            },
            {
              headers: {
                "x-mst-at": result,
              },
            } as AxiosRequestConfig
          )
          .then(
            (response) => {
              const calendarEvents: CalendarEvent[] = openingTask.teamsCalendarItems.map((ce) => {
                return { ...ce };
              });
              calendarEvents.push(response.data);
              setOpeningTask((previousTask: ITask) => ({
                ...previousTask,
                teamsCalendarItems: calendarEvents,
              }));
              setTaskList((previousTaskListItems: TaskListItem[] | undefined) => {
                if (previousTaskListItems && previousTaskListItems?.length > 0) {
                  const taskListItemIndex = previousTaskListItems.findIndex((taskListItem) => taskListItem.id === openingTask.id);
                  if (taskListItemIndex >= 0) {
                    const taskListItemCalendarEvents: CalendarEvent[] = previousTaskListItems[taskListItemIndex].teamsCalendarItems.map((ce) => {
                      return { ...ce };
                    });
                    taskListItemCalendarEvents.push({ ...response.data });
                    previousTaskListItems[taskListItemIndex] = {
                      ...previousTaskListItems[taskListItemIndex],
                      teamsCalendarItems: taskListItemCalendarEvents,
                    };
                  }
                }
                return previousTaskListItems;
              });
            },
            (error) => {
              if (axios.isAxiosError(error)) {
                switch (error.response?.status) {
                  case 400:
                    const { errorCode } = error.response.data as { errorCode: number; messages: string[] };
                    if (errorCode === 71400000) {
                      microsoftTeams.authentication.authenticate({
                        url: `${process.env.REACT_APP_CRM_TEAMS_API_BASE_URL}/authentication/login?scheme=azuread&oauthRedirectMethod={oauthRedirectMethod}&authId={authId}`,
                        isExternal: true,
                        width: 1200,
                        height: 800,
                        successCallback: (result: string) => {
                          // Use 'result' directly, because it will be parsed to object automatically in Android device.
                          // Use 'result' after URI decoding, because it will be encoded in iOS device.
                          // Use 'result' to be parsed to object in desktop and web devices.
                          const { isSuccessful, error } = (typeof result === "string" ? JSON.parse(decodeURIComponent(result)) : result) as { isSuccessful: boolean; error: string };
                          if (isSuccessful) {
                            onCreateMicrosoftTeamsMeetingClick(event, task);
                          } else {
                            console.error(`Azure AD login failed, error: ${error}.`);
                          }
                        },
                        failureCallback: (result: string) => {
                          try {
                            // Use 'result' directly, because it will be parsed to object automatically in Android device.
                            // Use 'result' after URI decoding, because it will be encoded in iOS device.
                            // Use 'result' to be parsed to object in desktop and web devices.
                            const { error } = (typeof result === "string" ? JSON.parse(decodeURIComponent(result)) : result) as { isSuccessful: boolean; accessToken: string; error: string };
                            console.error(`Azure AD login failed, error: ${error}.`);
                          } catch {
                            console.error(`Azure AD login failed, result: ${result}.`);
                          }
                        },
                      } as microsoftTeams.authentication.AuthenticateParameters);
                    }
                    break;
                }
              }
            }
          );
      },
      failureCallback: (error: any) => {
        console.error(`fail, error: ${JSON.stringify(error)}`);
      },
    };
    microsoftTeams.initialize(() => {
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
    });
  };

  const handleCompleteTask = (taskId: string) => {
    crmClient
      ?.put(`tasks/${taskId}/status?status=done`)
      .then((response) => {
        setCustomerTaskCond(defaultCustomerTaskCond);
        setScrollStatus("Idle");
      })
      .catch((error) => console.error("complete task ERROR", error));
  };

  const handleUnCompleteTask = (taskId: string) => {
    crmClient
      ?.put(`tasks/${taskId}/status?status=initial`)
      .then((response) => {
        setCustomerTaskCond(defaultCustomerTaskCond);
        setScrollStatus("Idle");
      })
      .catch((error) => console.error("complete task ERROR", error));
  };

  useEffect(() => {
    if (copyTaskState === true) {
      createTask();
      setCopyTaskState(false);
    }
  }, [copyTaskState, createTask]);

  return (
    <>
      <ToolBar ref={toolbarRef}>
        <IconButton handleClick={handleRefreshTaskList}>
          <FontAwesomeIcon icon={faSyncAlt} />
          {/* 重載資料，載入時，出現三個 Skeleton */}
          {isMobile ? "" : t("Com.Refresh")}
        </IconButton>
        <Button className="rightTool" onClick={handleCreateTaskVisible} isGrayBorder={true}>
          <FontAwesomeIcon icon={faPlus} />
          {t("Com.New")}{t("User.Custom.KeyWord.Task")}
        </Button>
        <IconButton handleClick={handleFilterTaskVisible} refObj={filterRef} isActive={filterVisible} className="filterBtn">
          <FontAwesomeIcon icon={faFilter} />
        </IconButton>
      </ToolBar>
      <TaskForm
        isMobile={isMobile}
        taskFormState={taskFormState}
        setTaskFormState={setTaskFormState}
        taskFormData={taskFormData}
        setTaskFormData={setTaskFormData}
        primaryTaskTypeData={primaryTaskTypeData}
        secondaryTaskTypeData={secondaryTaskTypeData}
        createTask={createTask}
        updateTask={updateTask}
        latestAssigneeData={latestAssigneeData}
      />
      {createMicrosoftTeamsMeetingDialogState.visible && (
        <StyledDialog disableButtonWrapperStyle={true}>
          <div style={{ padding: 32, textAlign: "center" }}>
            <img src={teamsCalendarImage} width={150} height={100} />
            <div style={{ margin: "24px 0" }}>{t("Teams.AddCalendarMeeting")}</div>
            <DialogActionsBar layout="stretched">
              <Button style={{ flexGrow: "initial", flexShrink: "initial", flexBasis: "initial" }} isGrayBorder={true} onClick={onCreateMicrosoftTeamsMeetingCancelClick}>
                {t("Com.Cancel")}
              </Button>
              <Button style={{ flexGrow: "initial", flexShrink: "initial", flexBasis: "initial" }} isPrimary={true} content={t("Com.Confirm") || ""} onClick={onCreateMicrosoftTeamsMeetingOKClick}>
                {t("Com.Confirm")}
              </Button>
            </DialogActionsBar>
          </div>
        </StyledDialog>
      )}
      <TaskFilter
        filterVisible={filterVisible}
        isMobile={isMobile}
        setFilterVisible={setFilterVisible}
        filterRef={filterRef}
        toolbarRef={toolbarRef}
        customerTaskCond={customerTaskCond}
        setCustomerTaskCond={setCustomerTaskCond}
        setScrollStatus={setScrollStatus}
        filterCond={filterCond}
        setFilterCond={setFilterCond}
        primaryTaskTypeData={primaryTaskTypeData}
        secondaryTaskTypeData={secondaryTaskTypeData}
      />
      {taskDetailVisible === true && openingTask !== undefined && (
        <StyledDialog
          title={t("User.Custom.KeyWord.Task") + t("Com.DetailContent") || ""}
          onClose={() => setTaskDetailVisible(!taskDetailVisible)}
          isMobile={isMobile}
          contentStyle={isMobile ? {} : { maxHeight: "80vh", padding: "24px", width: "600px" }}
          style={isMobile ? {} : { minWidth: "250px" }}
        >
          <div className="headContent">
            <>
              <CircleCheckbox
                size="31px"
                id={openingTask.id}
                onChecked={() => openingTask.id && handleCompleteTask(openingTask.id)}
                onUnchecked={() => openingTask.id && handleUnCompleteTask(openingTask.id)}
              />
              {isMobile === false && (
                <div className="titleContainer">
                  <div className="content">{openingTask.content}</div>
                  <div className="typeInfo">
                    <Label isCustom={true} color={openingTask.primaryTaskType.frontColor} bgColor={openingTask.primaryTaskType.backgroundColor}>
                      {openingTask.primaryTaskType.name}
                    </Label>
                    <Label isCustom={true} color={openingTask.secondaryTaskType.frontColor} bgColor={openingTask.secondaryTaskType.backgroundColor}>
                      {openingTask.secondaryTaskType.name}
                    </Label>
                  </div>
                </div>
              )}
              <div className="actionsContent">
                <FontAwesomeIcon icon={faPencilAlt} onClick={() => openingTask.id && handleUpdateTaskClick(openingTask.id)} />
                <FontAwesomeIcon icon={faCopy} onClick={() => handleCopyTaskClick()} />
                <FontAwesomeIcon icon={faTrashAlt} onClick={() => openingTask.id && handleDeleteTaskClick(openingTask.id)} />
              </div>
            </>
          </div>
          {isMobile && (
            <div className="mobileTitleContent">
              <div className="content">{openingTask.content}</div>
              <div className="typeInfo">
                <Label isCustom={true} color={openingTask.primaryTaskType.frontColor} bgColor={openingTask.primaryTaskType.backgroundColor}>
                  {openingTask.primaryTaskType.name}
                </Label>
                <Label isCustom={true} color={openingTask.secondaryTaskType.frontColor} bgColor={openingTask.secondaryTaskType.backgroundColor}>
                  {openingTask.secondaryTaskType.name}
                </Label>
              </div>
            </div>
          )}
          <div className="detailContent">
            <div className="fieldContainer">
              <div className="fieldTitle">{t("TaskModel.LatestAssignee")}：</div>
              <div className="fieldContent">
                <CustomerPictureContent imgUrl={taskLatestAssigneeAvatarUrl} isImgLoading={taskLatestAssigneeAvatarUrl === "" ? true : false} width={32} />
                {openingTask.latestAssigneeName}
                {!!openingTask.latestAssignerName && <span className="latestAssignerInfo">({t("Task.TaskAssigner", { 0: openingTask.latestAssignerName })})</span>}
              </div>
            </div>
            <div className="fieldContainer">
              <div className="fieldTitle">{t("ImportantDate.Date")}：</div>
              <div className="fieldContent">
                {dayjs(openingTask.beginDate).format("YYYY/MM/DD HH:mm") !== "Invalid Date" && dayjs(openingTask.beginDate).format("YYYY/MM/DD HH:mm")} ~{" "}
                {dayjs(openingTask.dueDate).format("YYYY/MM/DD HH:mm")}
              </div>
            </div>
            {!!openingTask.location && (
              <div className="fieldContainer">
                <div className="fieldTitle">{t("TaskModel.Location")}：</div>
                <div className="fieldContent">
                  {openingTask.location}
                  <Link href={`https://www.google.com/maps?q=${openingTask.location}`} target="_blank">
                    <FontAwesomeIcon icon={faMapMarkedAlt} />
                    <span style={{ paddingLeft: "4px" }}>{t("TaskModel.Location.Map")}</span>
                  </Link>
                </div>
              </div>
            )}
            <div className="fieldContainer">
              <div className="fieldTitle">
                {t("Teams.Meeting")}：
                <span className="actionContent" onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => onCreateMicrosoftTeamsMeetingClick(event, openingTask)}>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  <span style={{ paddingLeft: "4px" }}>{t("Teams.CreateMeeting")}</span>
                </span>
              </div>
              {openingTask.teamsCalendarItems?.length > 0 && (
                <div className="fieldContent">
                  {openingTask.teamsCalendarItems.map((calendarEvent: CalendarEvent) => {
                    return (
                      <div className="meetingContainer" key={calendarEvent.id}>
                        <div className="headContent">{calendarEvent.subject}</div>
                        <div className="meetingInfo">
                          <div className="dateInfo">
                            <FontAwesomeIcon icon={faClock} />
                            <span>
                              {dayjs(calendarEvent.beginDate).format("YYYY/MM/DD HH:mm")} ~ {dayjs(calendarEvent.dueDate).format("YYYY/MM/DD HH:mm")}
                            </span>
                          </div>
                          <div className="convenerInfo">
                            <FontAwesomeIcon icon={faUser} />
                            <span className="convener">{calendarEvent.convener}</span>
                            <span className="convenerDescription">{t("Teams.Convener")}</span>
                          </div>
                          <div className="buttonContent">
                            <TeamsButton isPurple={true}>
                              <a target="_blank" href={calendarEvent.onlineMeetingJoinUrl}>
                                加入
                              </a>
                            </TeamsButton>
                            {/*
                              Clipboard API will work if use `<iframe allow="clipboard-write"></iframe>`.
                              <TeamsButton>複製連結</TeamsButton>
                            */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="footerContent">
            {openingTask.modifyDate && (
              <div className="modifyInfo">
                <span className="modifyUser">{openingTask.modifyUser}</span>
                <span style={{ marginLeft: ".5em", marginRight: ".5em" }}> {t("Com.ModifiedAt", { 0: "" })} </span>
                <span className="modifyDate">{dayjs(openingTask.modifyDate).format("YYYY/MM/DD HH:mm")}</span>
              </div>
            )}
            <div className="createrInfo">
              <span className="createUser">{openingTask.createUser}</span>
              <span style={{ marginLeft: ".5em", marginRight: ".5em" }}>{t("Com.CreateDays.DateRange")}</span>
              <span className="createDate">{dayjs(openingTask.createDate).format("YYYY/MM/DD HH:mm")}</span>
            </div>
          </div>
        </StyledDialog>
      )}
      {scrollStatus === "Idle" ? (
        <div>
          <TaskSkeleton />
          <TaskSkeleton />
          <TaskSkeleton />
        </div>
      ) : (
        taskList?.map((task, index) => (
          <TaskItem key={task.id} task={task} handleTaskOpen={handleTaskOpen} afterMicrosoftTeamsMeetingCreated={handleRefreshTaskList} isLastItem={index === taskList.length - 1} />
        ))
      )}
      {scrollStatus === "Loading" && <TaskSkeleton />}
    </>
  );
};

const TaskSkeleton = () => {
  return (
    <SkeletonDiv>
      <Skeleton shape={"circle"} style={{ width: 44, height: 44 }} />
      <div style={{ flexGrow: 1 }}>
        <Skeleton shape={"text"} style={{ width: "100%" }} />
        <Skeleton shape={"text"} style={{ width: "100%" }} />
      </div>
    </SkeletonDiv>
  );
};
export default TaskList;
