import { useContext, useState } from "react";
import { useDebounce, useMedia } from "react-use";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridPagerSettings,
  GridRowClickEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { DataResult, State } from "@progress/kendo-data-query";
import styled, { ThemeContext } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import InputWithIcon from "../../components/shared/InputWithIcon";
import GridLoader from "../../components/shared/GridLoader";
import { useTranslation } from 'react-i18next';

const OverrideGrid = styled(Grid)`
  .k-grid-toolbar {
    .searchKey {
      @media ${(props) => props.theme.devices.mobile.mediaQuery} {
        flex-grow: 1;
      }
      border-radius: 4px;
    }
  }
`;


function OpportunityItemList() {
  const { t } = useTranslation();
  const [opportunityItems, setOpportunityItems] = useState<DataResult>({
    data: [],
    total: 0,
  });
  const [pageState, setPageState] = useState<State>({ take: 10, skip: 0 });
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [displayKeyword, setDisplayKeyword] = useState("");
  const [pagerSettings,] = useState({
    buttonCount: 5,
  } as GridPagerSettings);
  // const navigate = useNavigate();
  const themeContext = useContext(ThemeContext);
  const isMobile = useMedia(`(${themeContext?.devices.mobile.mediaQuery})`);

  const handleDataStateChange = (e: GridDataStateChangeEvent) => {
    setPageState(e.dataState);
    let gridContainer = document.querySelector(".k-grid-content");
    if (gridContainer) {
      gridContainer.scrollTop = 0;
    }
  };

  const handleDataReceived = (opportunities: DataResult) => {
    setOpportunityItems(opportunities);
  };

  const handleRowClick = (e: GridRowClickEvent) => {
    // const opportunityShowLink = `/opportunity/show/${e.dataItem.id}`;
    // navigate(opportunityShowLink, { replace: true });
  };

  useDebounce(
    () => {
      setSearchKeyWord(displayKeyword);
      setPageState({ take: 10, skip: 0 });
    },
    700,
    [displayKeyword]
  );

  // const LinkCell = (props: GridCellProps) => {
  //   const opportunityItemData = props.dataItem;
  //   const opportunityItemShowLink = `/opportunity/show/${opportunityItemData.id}`;
  //   return (
  //     <td>
  //       <Link to={opportunityItemShowLink} key={opportunityItemData.id}>
  //         {opportunityItemData.name}
  //       </Link>
  //     </td>
  //   );
  // };


  const columns = [
    {
      field: 'name',
      title: t("OpportunityDto.Name", { "User.Custom.KeyWord.Opportunity": t("User.Custom.KeyWord.Opportunity") }),
      width: 240,
      mobileShow: true
    },
    {
      field: 'placingCustomerName',
      title: t('User.Custom.KeyWord.Customer') + '/' + t("User.Custom.KeyWord.Company"),
      width: 180,
      mobileShow: true
    },
    {
      field: 'expectedCloseDate',
      title: t("OpportunityDto.ExpectedCloseDate"),
      width: 120,
    },
    {
      field: 'status',
      title: t("OpportunityDto.Status", { "User.Custom.KeyWord.Opportunity": t("User.Custom.KeyWord.Opportunity")}),
      width: 100,
    },
    {
      field: 'probability',
      title: t("OpportunityDto.Probability"),
      width: 100,
    },
    {
      field: 'salesRepresentativeName',
      title: t("OpportunityDto.SalesRepresentive"),
      width: 100,
    },
    {
      field: 'productName',
      title: t("OpportunityDto.ProductName"),
      width: 160,
    },
    {
      field: 'quantity',
      title: t("OpportunityDto.Quantity"),
      width: 90,
    },
    {
      field: 'unitPrice',
      title: t("OpportunityDto.UnitPrice"),
      width: 100,
    },
    {
      field: 'subtotal',
      title: t("OpportunityDto.Subtotal"),
      width: 100,
      mobileShow: true
    },
  ];

  return (
    <>
      <OverrideGrid
        filterable={false}
        sortable={false}
        pageable={pagerSettings}
        fixedScroll={true}
        {...pageState}
        data={opportunityItems}
        onDataStateChange={handleDataStateChange}
        onRowClick={handleRowClick}
      >
        <GridToolbar>
          <InputWithIcon icon={faSearch} placeholder={`${t("OpportunityDto.Name", { "User.Custom.KeyWord.Opportunity": t("User.Custom.KeyWord.Opportunity") })}、${t('User.Custom.KeyWord.Customer')}/${t("User.Custom.KeyWord.Company")}、${t("OrderItem.Product")}`} setDisplayKeyword={setDisplayKeyword} displayKeyword={displayKeyword} />
        </GridToolbar>
        {
          columns.map((column) => {
            if (isMobile === true && column.mobileShow !== true) return null;
            return <Column key={column.field} field={column.field} title={column.title} width={column.width} />
          })
        }

      </OverrideGrid>

      <GridLoader
        apiUrl="opportunities/itemList"
        keyword={searchKeyWord}
        pageState={pageState}
        onDataReceived={handleDataReceived}
      />
    </>
  );
}

export default OpportunityItemList;
