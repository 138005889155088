import { createContext, useContext } from "react";


interface OpportunityContextProp {
}

const OpportunityInit: OpportunityContextProp = {

}

const OpportunityContext = createContext(OpportunityInit);

export const OpportunityContextProvider = ({ children }: { children: React.ReactNode }) => {


  const value: OpportunityContextProp = {
  }

  return <OpportunityContext.Provider value={value}>
    {children}
  </OpportunityContext.Provider>
};


export const useOpportunityContext = () => {
  const context = useContext(OpportunityContext);
  if (context === undefined) {
    console.error('OpportunityContext is undefined');
    return null;
  }
  return context;
}

export default OpportunityContext;