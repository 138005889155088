import { useState } from "react";


type UseDelayedDisable = (initialState?: boolean, delay?: number) => [boolean, () => void];


const useDelayedDisable: UseDelayedDisable = (initialState = false, delay = 1000) => {
  const [isDisabled, setIsDisabled] = useState(initialState);

  const handleClick = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, delay);
  };

  return [isDisabled, handleClick];
}


export { useDelayedDisable };