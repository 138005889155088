import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { IFormState, StyledDialog, TaskFormDataType } from "./TaskList";
import Button from "../../components/shared/Button";
import RadioButtonGroup from "../../components/shared/RadioButtonGroup";
import CustomDateTimePicker from "../../components/shared/CustomDateTimePicker";
import CustomDropDownList from "../../components/shared/CustomDropDownList";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { InputChangeEvent, RadioGroupChangeEvent, TextAreaChangeEvent } from "@progress/kendo-react-inputs";
import { DropDownListChangeEvent, DropDownListFilterChangeEvent } from "@progress/kendo-react-dropdowns";
import HorizontalLine from "../../components/shared/HorizontalLine";
import { useEffect, useState } from "react";
import { TaskType } from "../../models/Task";
import { Account } from "../../models/DataPrivilege";
import { useLoginUserContext } from "../../contexts/LoginUserContext";
import { useDelayedDisable } from "../../hooks/useDelayedDisable";
import { useTranslation } from 'react-i18next';



interface taskFormErrorType {
  endDate: string;
  latestAssignee: string;
}

interface TaskFormProps {
  isMobile: boolean;
  taskFormState: IFormState;
  setTaskFormState: React.Dispatch<React.SetStateAction<IFormState>>;
  taskFormData: TaskFormDataType;
  setTaskFormData: React.Dispatch<React.SetStateAction<TaskFormDataType>>;
  primaryTaskTypeData?: TaskType[];
  secondaryTaskTypeData?: TaskType[];
  createTask: () => void;
  updateTask: () => void;
  latestAssigneeData?: Account[];
}

const TaskForm = ({ isMobile, taskFormState, setTaskFormState, taskFormData, setTaskFormData, primaryTaskTypeData, secondaryTaskTypeData, createTask, updateTask, latestAssigneeData }: TaskFormProps) => {
  const { t } = useTranslation();
  const [latestAssigneeQuery, setLatestAssigneeQuery] = useState<string>("");
  const { loginUser } = useLoginUserContext();
  const filterLatestAssigneeData = latestAssigneeData?.filter((latestAssignee) => {
    if (latestAssigneeQuery === "") return true;
    return latestAssignee.name.toLowerCase().includes(latestAssigneeQuery.toLowerCase());
  });
  const taskCreateTypeData = [
    { value: "Once", label: t("TaskModel.SingleForm") },
    { value: "AllDay", label: t("Calendar.AllDayText") },
  ];

  const defaultTaskFormError: taskFormErrorType = {
    endDate: "",
    latestAssignee: "",
  };
  const [taskFormErrors, setTaskFormErrors] = useState<taskFormErrorType>(defaultTaskFormError);
  const [isDisabled, handleDelayedDisable] = useDelayedDisable();


  useEffect(() => {
    if (loginUser.id === "") return;
    if (taskFormData.latestAssignee.id === "") {
      const defaultLatestAssignee = { id: loginUser.id, name: loginUser.name };
      setTaskFormData((prevState) => ({ ...prevState, latestAssignee: defaultLatestAssignee }));
    }
  }, [taskFormData, loginUser, setTaskFormData]);

  function toggleTaskFormVisible() {
    setTaskFormState((prevState) => {
      return {
        ...prevState,
        visible: !prevState.visible,
      };
    });
  }

  const handleLatestAssigneeFilter = (e: DropDownListFilterChangeEvent) => {
    const queryString = e.filter.value;
    setLatestAssigneeQuery(queryString);
  };

  const handleCreateTypeChange = (e: RadioGroupChangeEvent) => {
    const isAllDay = e.value === "AllDay" ? true : false;
    setTaskFormData((prevState) => {
      return {
        ...prevState,
        isAllDay: isAllDay,
      };
    });
  };

  const onCreateTaskStartDateChange = (date: Date | null) => {
    setTaskFormData((prevState) => ({
      ...prevState,
      startDate: date || new Date(),
    }));
  };

  const onCreateTaskEndDateChange = (date: Date | null) => {
    setTaskFormData((prevState) => ({
      ...prevState,
      endDate: date || new Date(),
    }));
  };

  const handleContentChange = (e: TextAreaChangeEvent) => {
    setTaskFormData((prevState) => {
      return {
        ...prevState,
        content: e.value,
      };
    });
  };

  const handlePrimaryTaskTypeChange = (e: DropDownListChangeEvent) => {
    setTaskFormData((prevState) => {
      return {
        ...prevState,
        primaryTaskType: e.value,
      };
    });
  };

  const handleSecondaryTaskTypeChange = (e: DropDownListChangeEvent) => {
    setTaskFormData((prevState) => {
      return {
        ...prevState,
        secondaryTaskType: e.value,
      };
    });
  };

  const handleLatestAssigneeChange = (e: DropDownListChangeEvent) => {
    setTaskFormData((prevState) => {
      return {
        ...prevState,
        latestAssignee: e.value,
      };
    });
  };

  const handleLocationChange = (e: InputChangeEvent) => {
    setTaskFormData((prevState) => {
      return {
        ...prevState,
        location: e.value,
      };
    });
  };

  const handleTaskFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const validateResult = validationTaskForm();
    if (!validateResult) return;

    handleDelayedDisable();
    if (taskFormState.formAction === "createTask") {
      createTask();
    } else {
      updateTask();
    }
  };

  /// api

  /// not sure

  /// validation
  const validationTaskForm = (): boolean => {
    const taskFormErrors: taskFormErrorType = defaultTaskFormError;
    // 到期日為必填
    if (taskFormData.endDate === undefined) {
      taskFormErrors.endDate = t("Com.Input") + t("Task.DueDate");
    }

    // 負責人為必填
    if (taskFormData.latestAssignee.id === "") {
      taskFormErrors.latestAssignee = t("Com.Choose.Owner");
    }

    setTaskFormErrors(taskFormErrors);

    if (Object.values(taskFormErrors).some((v) => v !== "")) return false;
    return true;
  };

  return (
    <>
      {taskFormState.visible && (
        <StyledDialog
          title={taskFormState.formAction === "createTask" ? t("Com.New") + t("User.Custom.KeyWord.Task") : t("Com.Update") + t("User.Custom.KeyWord.Task")}
          onClose={() => toggleTaskFormVisible()}
          isMobile={isMobile}
          contentStyle={isMobile ? {} : { maxHeight: "80vh", maxWidth: "550px" }}
          style={isMobile ? {} : { minWidth: "550px" }}
        >
          <form onSubmit={handleTaskFormSubmit}>
            <div className="dialogBody">
              <div className="fieldContainer">
                <div className="fieldTitle">{t("TaskModel.SubType")}</div>
                <div className="fieldContent">
                  <RadioButtonGroup
                    name="createType"
                    data={taskCreateTypeData}
                    defaultValue="Once"
                    layout="horizontal"
                    onChange={handleCreateTypeChange}
                    value={taskFormData.isAllDay === true ? "AllDay" : "Once"}
                  />
                </div>
              </div>
              <div className="fieldContainer">
                <div className="fieldTitle">
                  <span>{t("TaskModel.ChooseDate")}</span>
                  {taskFormErrors.endDate && <span className="validationMessage">{taskFormErrors.endDate}</span>}
                </div>
                <div className="fieldContent">
                  <div className="datetimeContainer">
                    {taskFormData.isAllDay === false && (
                      <CustomDateTimePicker
                        name="startDate"
                        placeholder={t("TaskModel.PleaseChooseStartDate")}
                        format={"yyyy/MM/dd HH:mm"}
                        isShowNowTime={true}
                        width={isMobile ? "75%" : 350}
                        value={taskFormData.startDate}
                        onDateTimeChange={onCreateTaskStartDateChange}
                      />
                    )}
                  </div>
                  <div className="datetimeContainer">
                    <CustomDateTimePicker
                      name="endDate"
                      placeholder={t("TaskModel.PleaseChooseEndDate")}
                      format={taskFormData.isAllDay === true ? "yyyy/MM/dd" : "yyyy/MM/dd HH:mm"}
                      isShowNowTime={true}
                      width={isMobile ? "75%" : 350}
                      value={taskFormData.endDate}
                      onDateTimeChange={onCreateTaskEndDateChange}
                    />
                  </div>
                  {/* 提醒的部分 待開發 */}
                  {/* <Checkbox label="產生提醒" />
                      <Checkbox label="逾期額外通知" />
                      <Checkbox label="完成額外通知" /> */}
                </div>
              </div>
              <HorizontalLine styleProperties={{ paddingTop: "16px", paddingBottom: "16px", marginLeft: "24px", width: "90%" }} />
              <div className="fieldContainer">
                <div className="fieldTitle">{t("Com.Content")}</div>
                <div className="fieldContent">
                  <TextArea name="content" rows={4} style={{ width: "470px" }} onChange={handleContentChange} value={taskFormData.content} />
                </div>
              </div>
              {/* 關聯客戶 待開發 */}
              {/* <div className="fieldContainer">
                <div className="fieldTitle">關聯客戶</div>
                <div className="fieldContent">
                  <Input />
                </div>
              </div> */}
              <div className="fieldContainer">
                <div className="fieldTitle">{t("WorkingJournalExcel.Complete.TypeName")}</div>
                <div className="fieldContent">
                  <CustomDropDownList
                    name="primaryTaskType"
                    data={primaryTaskTypeData}
                    dataItemKey="id"
                    textField="name"
                    onChange={handlePrimaryTaskTypeChange}
                    value={taskFormData.primaryTaskType}
                    style={isMobile ? { width: "100%" } : { width: "230px" }}
                  />
                  <CustomDropDownList
                    name="secondaryTaskType"
                    data={secondaryTaskTypeData}
                    dataItemKey="id"
                    textField="name"
                    onChange={handleSecondaryTaskTypeChange}
                    value={taskFormData.secondaryTaskType}
                    style={isMobile ? { width: "100%" } : { width: "230px" }}
                  />
                </div>
              </div>
              <div className="fieldContainer">
                <div className="fieldTitle">
                  <span>{t("TaskModel.LatestAssignee")}</span>
                </div>
                <div className="fieldContent">
                  <CustomDropDownList
                    name="latestAssignee"
                    dataItemKey="id"
                    data={filterLatestAssigneeData}
                    textField="name"
                    filterable={true}
                    onFilterChange={handleLatestAssigneeFilter}
                    onChange={handleLatestAssigneeChange}
                    value={taskFormData.latestAssignee}
                    style={isMobile ? { width: "100%" } : { width: "470px" }}
                  />
                  {taskFormErrors.latestAssignee && <span className="validationMessage">{taskFormErrors.latestAssignee}</span>}
                </div>
              </div>
              <div className="fieldContainer">
                <div className="fieldTitle">{t("TaskModel.Location")}</div>
                <div className="fieldContent">
                  <Input name="location" style={{ width: "470px" }} onChange={handleLocationChange} value={taskFormData.location} />
                </div>
              </div>
            </div>
            <DialogActionsBar layout="end">
              <Button isGrayBorder={true} isDisabled={isDisabled} onClick={() => toggleTaskFormVisible()} style={{ fontWeight: '400' }}>
                {t("Com.Cancel")}
              </Button>
              <Button isPrimary={true} isSubmit={true} content={t("Com.Save") || ""} isDisabled={isDisabled}>
                {t("Com.Save")}
              </Button>
            </DialogActionsBar>
          </form>
        </StyledDialog>
      )}
    </>
  );
};

export default TaskForm;
