import { createContext, useContext, useState } from "react";
import { User as LoginUser, emptyUser } from "../models/User";


export interface ILoginUserContextValue {
  loginUser: LoginUser;
  setLoginUser: React.Dispatch<React.SetStateAction<LoginUser>>;
}


const LoginUserContext = createContext<ILoginUserContextValue | undefined>(undefined);

export const LoginUserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [loginUser, setLoginUser] = useState<LoginUser>(emptyUser);

  const value = {
    loginUser,
    setLoginUser,
  };

  return (
    <LoginUserContext.Provider value={value}>
      {children}
    </LoginUserContext.Provider>
  );

}



export const useLoginUserContext = () => {
  const context = useContext<ILoginUserContextValue | undefined>(LoginUserContext);
  if (context === undefined) {
    throw new Error('Login user context is null');
  }
  return context;
}


export default LoginUserContext;