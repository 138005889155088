import { useEffect, useRef, useState } from "react";
import { useDebounce } from "react-use";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridPagerSettings,
  GridRowClickEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { DataResult, State } from "@progress/kendo-data-query";
import styled from "styled-components";
import { CustomersLoader } from "./CustomerListLoader";
import { Label as LabelStruct } from "../../models/Label";
import Label from "../../components/shared/Label";
import { Link, useNavigate } from "react-router-dom";
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import InputWithIcon from "../../components/shared/InputWithIcon";
import { useTranslation } from 'react-i18next';

const OverrideGrid = styled(Grid)`
  .k-grid-toolbar {
    .searchKey {
      @media ${(props) => props.theme.devices.mobile.mediaQuery} {
        flex-grow: 1;
      }
      border-radius: 4px;
    }
  }
`;


const LabelList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;

interface ColumnSetting {
  fieldName: string,
  title: string,
  isHiddenOnMobileDevice?: boolean,
  cell?: (props: GridCellProps) => JSX.Element,
}

function CustomerList() {
  const { t } = useTranslation();
  const columnSettings: ColumnSetting[] = [
    {
      fieldName: 'name',
      title: t("User.Custom.KeyWord.Customer"),
      cell: (props: GridCellProps) => {
        return LinkCell(props);
      },
    },
    {
      fieldName: 'companyName',
      title: t("User.Custom.KeyWord.Company"),
    },
    {
      fieldName: 'email',
      title: 'E-mail',
    },
    {
      fieldName: 'labels',
      title: t("SiteMenu.SysManagement.Label"),
      isHiddenOnMobileDevice: true,
      cell: (props: GridCellProps) => {
        return LabelsCell(props);
      },
    },
  ];

  const [customers, setCustomers] = useState<DataResult>({
    data: [],
    total: 0,
  });
  const [pageState, setPageState] = useState<State>({ take: 10, skip: 0 });
  const [searchKeyWord, setSearchKeyWord] = useState("");
  const [displayKeyword, setDisplayKeyword] = useState("");
  const [pagerSettings, setPagerSettings] = useState({
    buttonCount: 5,
  } as GridPagerSettings);
  const navigate = useNavigate();
  const kendoGridRef = useRef<any>(null);
  const [kendoGridWidth, setKendoGridWidth] = useState<number>(0);

  const handleDataStateChange = (e: GridDataStateChangeEvent) => {
    setPageState(e.dataState);
    let gridContainer = document.querySelector(".k-grid-content");
    if (gridContainer) {
      gridContainer.scrollTop = 0;
    }
  };

  const handleDataReceived = (customers: DataResult) => {
    setCustomers(customers);
  };

  const handleRowClick = (e: GridRowClickEvent) => {
    const customerShowLink = `/customer/show/${e.dataItem.id}`;
    navigate(customerShowLink, { replace: true });
  };

  useDebounce(
    () => {
      setSearchKeyWord(displayKeyword);
      setPageState({ take: 10, skip: 0 });
    },
    700,
    [displayKeyword]
  );
  useEffect(() => {
    kendoGridRef.current = document.querySelector('.k-grid');
    setKendoGridWidth(kendoGridRef.current.offsetWidth || 0);

    // Resize observer to reset columns width.
    const observer = new ResizeObserver(() => {
      setKendoGridWidth(kendoGridRef.current.offsetWidth || 0);
    });
    observer.observe(kendoGridRef.current);

    // Clean up.
    return () => {
      observer.disconnect();
    };
  }, []);

  const LinkCell = (props: GridCellProps) => {
    const customerData = props.dataItem;
    const customerShowLink = `/customer/show/${customerData.id}`;
    return (
      <td>
        <Link to={customerShowLink} key={customerData.id}>
          {customerData.name}
        </Link>
      </td>
    );
  };

  const LabelsCell = (props: GridCellProps) => {
    const labelData = props.dataItem.labels.map((item: LabelStruct) => (
      <Label key={item.id} styleId={item.styleId}>
        {item.displayName}
      </Label>
    ));
    return (
      <td>
        <LabelList>{labelData}</LabelList>
      </td>
    );
  };

  return (
    <>
      <OverrideGrid
        filterable={false}
        sortable={false}
        pageable={pagerSettings}
        fixedScroll={true}
        {...pageState}
        data={customers}
        onDataStateChange={handleDataStateChange}
        onRowClick={handleRowClick}
      >
        <GridToolbar>
          <InputWithIcon icon={faSearch} placeholder={`${t("Com.Input")}${t("NoteImport.CustomerName", { "User.Custom.KeyWord.Customer": t("User.Custom.KeyWord.Customer")})}、${t("ContactMech.TelecomNum.FixedPhone")}`} setDisplayKeyword={setDisplayKeyword} displayKeyword={displayKeyword} />
        </GridToolbar>
        {columnSettings.map((columnSetting: ColumnSetting) => {
          return !(!!columnSetting.isHiddenOnMobileDevice && kendoGridWidth <= 485) && <Column
            key={columnSetting.fieldName}
            field={columnSetting.fieldName}
            title={columnSetting.title}
            cell={columnSetting.cell}
          />
        })}
      </OverrideGrid>
      <CustomersLoader
        keyword={searchKeyWord}
        pageState={pageState}
        onDataReceived={handleDataReceived}
      />
    </>
  );
}

export default CustomerList;
