import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyledInputWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border: 1px solid ${(props) => props.theme.colorGray300};
  border-radius: 4px;
  background: ${(props) => props.theme.colorWhite};
  font-size: 14px;
  padding: 0;
  margin-right: 8px;
  box-sizing: border-box;
  height: 32px;

  @media screen and (${props => props.theme.devices.mobile.mediaQuery}) {
    flex-grow: 1;
  }
  & > svg {
    margin-left: 8px;
    color: ${(props) => props.theme.colorGray500};
  }
  & > input {
    border: 0;
    padding: 6px;
    width: 250px;
    &:focus-visible {
      outline: 0;
    }
    &::placeholder {
      color: ${(props) => props.theme.colorGray400};
    }
  }
`;

interface InputWithIconProps {
  icon?: IconProp;
  placeholder: string;
  displayKeyword: string;
  setDisplayKeyword: (keyword: string) => void;
}

const InputWithIcon = (props: InputWithIconProps) => {
  const { icon, placeholder, displayKeyword, setDisplayKeyword } = props;
  return (
    <StyledInputWithIcon>
      {icon && <FontAwesomeIcon icon={icon} />}
      <input
        className="searchKey"
        type="text"
        placeholder={placeholder}
        onChange={(e) => {
          setDisplayKeyword(e.target.value);
        }}
        value={displayKeyword}
        style={{ background: "transparent", padding: '3px 6px' }}
      />
    </StyledInputWithIcon>
  )
}

export default InputWithIcon