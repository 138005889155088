import styled from "styled-components";
import {
  DropDownList,
  DropDownsPopupSettings,
  DropDownListFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FieldRenderProps, FieldWrapper } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { useTranslation } from 'react-i18next';



interface EditorItemProps {
  distance?: "Near" | "Middle" | "Far";
}

const EditorItem = styled.div<EditorItemProps>`
  margin-left: ${(props) => {
    switch (props.distance) {
      case "Near":
        return "4px";
      case "Middle":
        return "8px";
      case "Far":
        return "16px";
      default:
        return "";
    }
  }};
`;


interface StyledDropDownListProps {
  dropdownListSize?: "Small" | "Middle" | "Large" | "Huge";
}
const StyledDropDownList = styled(DropDownList) <StyledDropDownListProps>`
  width: ${(props) => {
    switch (props.dropdownListSize) {
      case "Middle":
        return "200px";
      case "Large":
        return "300px";
      case "Huge":
        return "400px";
      case "Small":
      default:
        return "150px";
    }
  }};

  @media screen and (${(props) => props.theme.devices.mobile.mediaQuery}) {
    width: 100%;
  }
`;

export const EditorItemDropdownList = (fieldRenderProps: FieldRenderProps) => {
  const { t } = useTranslation();
  const {
    visited,
    validationMessage,
    data,
    dataItemKey,
    textField,
    isPrimitive,
    enablePleaseSelectAsDefaultItem,
    filterable,
    filterChange,
    loading,
    isRequired,
    editorItemDistance,
    inputSize,
    onBlur,
    onFocus,
    ...others
  } = fieldRenderProps;

  const onFilterChange = (event: DropDownListFilterChangeEvent) => {
    filterChange?.(event);
  };

  const DefaultDropDownsPopupSettings: DropDownsPopupSettings = {
    className: "dropdownListPopupContainer",
    popupClass: "popupItem",
  };

  return (
    <FieldWrapper>
      <EditorItem distance={editorItemDistance}>
        <StyledDropDownList
          popupSettings={DefaultDropDownsPopupSettings}
          data={data}
          dataItemKey={isPrimitive ? null : dataItemKey}
          textField={isPrimitive ? null : textField}
          defaultItem={
            enablePleaseSelectAsDefaultItem
              ? isPrimitive
                ? t("Com.PleaseSelect")
                : { [textField]: t("Com.PleaseSelect") }
              : undefined
          }
          filterable={filterable}
          onFilterChange={filterable && onFilterChange}
          loading={loading}
          {...others}
        />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </EditorItem>
    </FieldWrapper>
  );
};
